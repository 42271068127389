const chartValuesColor = '#333';

const chartXColor = '#666666';
const chartYColor = '#333333';

const chartColors = [
    // CIFFC branding colors
    '#F5333F',
    '#9C3321',
    '#D65129',
    '#F9A123',
    '#FFC431',
    '#6DC8BF',
    '#5EB546',
    '#336667',
    '#485654',
    '#666699',
    '#303584',
    '#663366',
    // some extra "overflow" colors, just in case
    '#be4e7f',
    '#ff752e',
    '#dfdf75',
];

export default {
    
    // define default CONFIGs

    defaultColors: chartColors,

    // defaune default DONUT chart OPTIONS

    donutOptions: () => {
        return {
            plugins: {
                legend: {
                    // display: false,
                    position: 'right',
                    align: 'center'
                },

                // configuring DATA VALUE LABELS, https://chartjs-plugin-datalabels.netlify.app/guide

                datalabels: {
                    display: false
                }
            }
        }
    },

    // define default VERTICAL BAR chart OPTIONS
    
    barOptions: (
        titleY = false, 
        titleX = false
    ) => {
    
        return {
            indexAxis: 'x',

            aspectRatio: 1.5,

            responsive: true,

            scales: {
                yAxes: {
                    title: {
                        display: ( titleY ? true : false ),
                        text: ( titleY ? titleY : false )
                    },
                    
                    ticks:{
                        color: chartYColor,
                        font: {
                            size: 16,
                        },
                    },
                },

                xAxes: {
                    title: {
                        display: ( titleX ? true : false ),
                        text: ( titleX ? titleX : false ),
                    },
                    
                    ticks:{
                        color: chartXColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },

                    grid: {
                        display: false
                    },
                }
            },

            plugins: {
                
                // configuring DATA VALUE LABELS, https://chartjs-plugin-datalabels.netlify.app/guide
                
                datalabels: {
                    display: true,
                    color: chartValuesColor,

                    anchor: 'end',
                    rotation: 270,
                    align: 'top',

                    font: {
                        size: 12,
                    },

                    formatter: function(value, context) {
                        return value ? parseInt(value).toLocaleString() : null;
                    }
                }

             }
        }
        
    },

    // define default BAR chart DATASET configuration
    
    barDataSet: (
        label = false, 
        data = [],
        colorSet = 0
    ) => {
    
        return {
            label: ( label ? label : false ),
            data: data,

            borderRadius: 0,
            backgroundColor: chartColors[ colorSet ],
        }
        
    },

    // define default HORIZONTAL BAR chart OPTIONS
    
    barHorizontalOptions: (
        titleY = false, 
        titleX = false
    ) => {
    
        return {
            indexAxis: 'y',

            aspectRatio: 1.5,

            responsive: true,

            scales: {
                yAxes: {
                    title: {
                        display: ( titleY ? true : false ),
                        text: ( titleY ? titleY : false )
                    },
                    
                    ticks:{
                        color: chartYColor,
                        font: {
                            size: 16,
                        },
                    },
                },

                xAxes: {
                    title: {
                        display: ( titleX ? true : false ),
                        text: ( titleX ? titleX : false ),
                    },
                    
                    ticks:{
                        color: chartXColor,
                        font: {
                            size: 16,
                            weight: 'bold',
                        },
                    },

                    grid: {
                        display: false
                    },
                }
            },

            plugins: {

                legend: {
                    display: false
                },
                
                // configuring DATA VALUE LABELS, https://chartjs-plugin-datalabels.netlify.app/guide
                
                datalabels: {
                    display: true,
                    color: chartValuesColor,

                    anchor: 'end',
                    rotation: 0,
                    align: 'right',
                    offset: -2,

                    font: {
                        size: 12,
                    },

                    formatter: function(value, context) {
                        return value ? parseInt(value).toLocaleString() : null;
                    }
                }

             }
        }
        
    },

}
import React from "react";

import { useTranslation } from 'react-i18next';

function CiffcNumberFormat(props) {
    
    // loading TRANSLATION funcitons
    const { i18n } = useTranslation();
    
    // make sure we're working with a NUMBER
    const numberToFormat = parseFloat( props.number || 0 );

    let defaultDecimal = 1;
    let defaultThousands = 'auto';

    // if provided a TYPE of number, set some DEFAULT setting overrides (and allow FALLBACK to more specific when provided)
    
    switch ( props.type ) {
        // basic INTEGERs default
        case 'i':
        case 'int':
        case 'integer':
            defaultDecimal = 0;
            break;
        // latitude and longitude coord displays
        case 'll':
        case 'latlong':
        case 'latlng':
            defaultDecimal = 4;
            defaultThousands = false;
            break;
        // consistent AREA displays of hectares
        case 'ha':
        case 'hec':
        case 'hectare':
        case 'hectares':
            defaultDecimal = 1;
            break;
        default:
            break;
    }

    // override some SETTINGS, if provided by PROPS
    const decimalMin = props.decimal || props.decimalMin || defaultDecimal;
    const decimalMax = props.decimal || props.decimalMax || defaultDecimal;
    const thousandSeparator = props.thousands || defaultThousands;

    // give us a CONSISTENT format to display, in terms of THOUSANDS separator and decimal points, while respecting LANGUAGE
    return <>
        { new Intl.NumberFormat(
                i18n.language, { 
                    style:'decimal', 
                    useGrouping: thousandSeparator,
                    minimumFractionDigits: decimalMin, 
                    maximumFractionDigits: decimalMax
                }
            ).format(
                numberToFormat
            )
        }
        </>

}

export default CiffcNumberFormat;
import React, { useEffect, useState } from "react";
import useLocalStorage from "../components/hooks/UseLocalStorage";
import axios from "axios";
import moment from 'moment-timezone';

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// CONTENT
import StaticContent from "./elements/StaticContent";

// TABLES
import { TableWrapper } from "../components/tables/TableWrapper";  // used for consistency and layouts
import { TableFiresActiveAndOut } from "../components/tables/TableFiresActiveAndOut";
import { TableFiresByCause } from "../components/tables/TableFiresByCause";
import { TableFiresFullResponse } from "../components/tables/TableFiresFullResponse";
import { TableFiresTotals } from "../components/tables/TableFiresTotals";

function Summary(){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Current Fires Summary') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);
    
    // TABLE states

    // const [dipAgenciesData, setDipAgenciesData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [dipAgenciesData, setDipAgenciesData] = useLocalStorage("node-summary-agencies-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    // const [dipFireCountData, setDipFireCountData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [dipFireCountData, setDipFireCountData] = useLocalStorage("node-summary-fire-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));
    
    // const [dipSummaryData, setDipSummaryData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [dipSummaryData, setDipSummaryData] = useLocalStorage("node-summary-table-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));
    
    const [tableFireCount, setTableFireCount] = useState(<TableWrapper></TableWrapper>);
    const [tableFullResponse, setTableFullResponse] = useState(<TableWrapper></TableWrapper>);
    const [tableModifiedResponse, setTableModifiedResponse] = useState(<TableWrapper></TableWrapper>);
    const [tableMonitoredResponse, setTableMonitoredResponse] = useState(<TableWrapper></TableWrapper>);
    const [tableGrandTotals, setTableGrandTotals] = useState(<TableWrapper></TableWrapper>);

    useEffect(() => {

        // for list of AGENCIES in all tables
        if ( !dipAgenciesData ) {
            let urlAgencies = ApiEndpoints.dipAgencies() + '?has_siterep=true';

            axios
                .get( urlAgencies )
                .then(({ data }) => {
                    setDipAgenciesData(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        // for SUMMARY of new fires today (using TOMORROW date, due to how SQL works)
        if ( !dipFireCountData ) {

            let tomorrow =  new Date();
            tomorrow.setDate(new Date().getDate() + 1);
            let tomorrowString = tomorrow.getFullYear() + '-' + (tomorrow.getMonth() + 1).toString().padStart(2, '0') + '-' + tomorrow.getDate().toString().padStart(2, '0');

            let urlFireCount = ApiEndpoints.dipFireCount() + '?date=' + tomorrowString;

            axios
                .get( urlFireCount )
                .then(({ data }) => {
                    setDipFireCountData(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        // for SUMMARY of all fire data statuses
        if ( !dipSummaryData ) {

            let urlSummary = ApiEndpoints.dipSummary();

            axios
                .get( urlSummary )
                .then(({ data }) => {
                    setDipSummaryData(data);

                    // flag API as accessible
                    setIsApi(true);
                })
                .catch((error) => {
                    console.log(error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);
                });
        } else {
            // if reading CACHE, then assume true
            setIsApi(true);
        }

    }, []);

    useEffect(() => {

        if ( dipAgenciesData && dipFireCountData ) {
            
            let fireCauses = {};

            dipAgenciesData.forEach((item) => {
                fireCauses[ item.field_code ] = {
                    agency: item.field_name, 
                            
                    cause_natural: 0,
                    cause_human: 0,
                    cause_undetermined: 0, 
                        
                    total: 0
                }; 
            });

            dipFireCountData.forEach((dataProp) => {

                const agency_code = dataProp.agency_code.toLowerCase();

                let cause_key = 'cause_undetermined';
                if ( dataProp.fire_cause.toUpperCase() === 'H' ) { cause_key = 'cause_human'; }
                else if ( dataProp.fire_cause.toUpperCase() === 'N' ) { cause_key = 'cause_natural'; }
                
                if ( fireCauses.hasOwnProperty(agency_code) ) { 
                    fireCauses[ dataProp.agency_code ][ cause_key ] = fireCauses[ dataProp.agency_code ][ cause_key ] + parseInt(dataProp.fire_count);
                    fireCauses[ dataProp.agency_code ].total = fireCauses[ dataProp.agency_code ].total + parseInt(dataProp.fire_count);
                }

            });

            setTableFireCount( <TableFiresByCause data={fireCauses} agencyFullName={true} agencyLink={true} /> );
        }

    }, [ dipAgenciesData, dipFireCountData, i18n.language ]);

    useEffect(() => {

        if ( dipAgenciesData && dipSummaryData ) {  

            let agenciesFullRespone = {}

            dipAgenciesData.forEach((item) => {
                agenciesFullRespone[ item.field_code ] = {
                    agency: item.field_name, 
                            
                    soc_oc: 0,
                    soc_bh: 0,
                    soc_uc: 0, 
                        
                    active: 0,
                    active_area: 0.00,

                    soc_out: 0,
                    out_area: 0.00,
                    
                    total: 0,
                    total_area: 0.00
                }; 
            });

            dipSummaryData.forEach((dataProp) => {
                        
                if (
                    dataProp.hasOwnProperty('response_type')
                    && dataProp.response_type === 'FUL'
                    && agenciesFullRespone.hasOwnProperty(dataProp.agency_code)
                ) { 

                    let stage_of_control_key = 'soc_' + dataProp.stage_of_control.toLowerCase();

                    if ( stage_of_control_key === 'soc_out' ) {
                        agenciesFullRespone[ dataProp.agency_code ].out_area = agenciesFullRespone[ dataProp.agency_code ].out_area + parseFloat(dataProp.area_sum);
                    } else {
                        agenciesFullRespone[ dataProp.agency_code ].active = agenciesFullRespone[ dataProp.agency_code ].active + parseInt(dataProp.fire_count);
                        agenciesFullRespone[ dataProp.agency_code ].active_area = agenciesFullRespone[ dataProp.agency_code ].active_area + parseFloat(dataProp.area_sum);
                    }

                    agenciesFullRespone[ dataProp.agency_code ][ stage_of_control_key ] = agenciesFullRespone[ dataProp.agency_code ][ stage_of_control_key ] + parseInt(dataProp.fire_count);
                    agenciesFullRespone[ dataProp.agency_code ].total = agenciesFullRespone[ dataProp.agency_code ].total + parseInt(dataProp.fire_count);
                    agenciesFullRespone[ dataProp.agency_code ].total_area = agenciesFullRespone[ dataProp.agency_code ].total_area + parseFloat(dataProp.area_sum);

                }

            });

            setTableFullResponse( <TableFiresFullResponse data={agenciesFullRespone} /> );

        }

    }, [ dipAgenciesData, dipSummaryData, i18n.language ]);

    useEffect(() => {

        if ( dipAgenciesData && dipSummaryData ) {

            let agenciesModified = {};
            let agenciesMonitored = {};
            let agenciesGrandTotals = {};

            dipAgenciesData.forEach((item) => {

                agenciesModified[ item.field_code.toLowerCase() ] = {
                    agency: item.field_name, 
                    
                    active: 0,
                    out: 0,

                    total: 0,
                    area: 0.00
                }; 

                agenciesMonitored[ item.field_code ] = {
                    agency: item.field_name, 
                    
                    active: 0,
                    out: 0,

                    total: 0,
                    area: 0.00
                };

                agenciesGrandTotals[ item.field_code ] = {
                    agency: item.field_name, 
                    
                    fires: 0,
                    area: 0.00
                }; 
            });

            dipSummaryData.forEach((dataProp) => {

                const agency_code = dataProp.agency_code.toLowerCase();
                const stage_of_control_key = 'soc_' + dataProp.stage_of_control.toLowerCase();

                if (
                    dataProp.hasOwnProperty('response_type')
                    && dataProp.response_type === 'MOD'
                    && agenciesModified.hasOwnProperty(agency_code)
                ) { 
                
                    if ( stage_of_control_key === 'soc_out' ) {
                        agenciesModified[ agency_code ].out = agenciesModified[ agency_code ].out + parseInt(dataProp.fire_count);
                    } else {
                        agenciesModified[ agency_code ].active = agenciesModified[ agency_code ].active + parseInt(dataProp.fire_count);
                    }

                    agenciesModified[ agency_code ].total = agenciesModified[ agency_code ].total + parseInt(dataProp.fire_count);
                    agenciesModified[ agency_code ].area = agenciesModified[ agency_code ].area + parseFloat(dataProp.area_sum);

                }

                if (
                    dataProp.hasOwnProperty('response_type')
                    && dataProp.response_type === 'MON'
                    && agenciesModified.hasOwnProperty(agency_code)
                ) { 
                
                    if ( stage_of_control_key === 'soc_out' ) {
                        agenciesMonitored[ agency_code ].out = agenciesMonitored[ agency_code ].out + parseInt(dataProp.fire_count);
                    } else {
                        agenciesMonitored[ agency_code ].active = agenciesMonitored[ agency_code ].active + parseInt(dataProp.fire_count);
                    }

                    agenciesMonitored[ agency_code ].total = agenciesMonitored[ agency_code ].total + parseInt(dataProp.fire_count);
                    agenciesMonitored[ agency_code ].area = agenciesMonitored[ agency_code ].area + parseFloat(dataProp.area_sum);

                }

                agenciesGrandTotals[ agency_code ].fires = agenciesGrandTotals[ agency_code ].fires + parseInt(dataProp.fire_count);
                agenciesGrandTotals[ agency_code ].area = agenciesGrandTotals[ agency_code ].area + parseFloat(dataProp.area_sum);

            });

            setTableModifiedResponse(<TableFiresActiveAndOut data={agenciesModified} />);

            setTableMonitoredResponse(<TableFiresActiveAndOut data={agenciesMonitored} />);

            setTableGrandTotals(<TableFiresTotals data={agenciesGrandTotals} />);

        }

    }, [ dipAgenciesData, dipSummaryData, i18n.language ]);
    
    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t('National Fires Summary') */ }
                <StaticContent staticContentAlias="national-summary" />

                <p>
                    { t('Valid as of') } { moment().tz( moment.tz.guess() ).format('LLLL z') }<br />
                    { t('For current year from January 1.') }
                </p>

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                    <h4>{ t('New Wildland Fires Today') }</h4>

                        { tableFireCount }

                    <h4>{ t('Full Response') }</h4>

                        { tableFullResponse }

                    <div className="table-sidebyside">
                        <div>
                            <h4>{ t('Modified Response') }</h4>
                            { tableModifiedResponse }
                        </div>

                        <div>
                            <h4>{ t('Monitored Response') }</h4>
                            { tableMonitoredResponse }
                        </div>

                        <div>
                            <h4>{ t('Grand Totals') }</h4>
                            { tableGrandTotals }
                        </div>
                    </div>

             </div>
        </section>

    )
}

export default Summary;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import moment from 'moment';

import AgenciesData from "../constants/AgenciesData";
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

import { CSVLink } from "react-csv";

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "./elements/StaticContent";

import { TableWrapper } from "../components/tables/TableWrapper";  // used for consistency and layouts

function Inventory( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Inventory Summary') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;

    // REDIRECT browser out of autheticated area if user not LOGGED IN

    const history = useHistory();

    useEffect(() => {
        if ( !isLoggedIn || !activeUser || !activeUser.hasOwnProperty('jwt') ) {
            history.push("/login");
        }
    }, [ isLoggedIn, activeUser ]);

    const [wildfireFilterPage, setWildfireFilterPage] = useState( 1 );
    const [wildfireFilterAgency, setWildfireFilterAgency] = useState('');
    const [wildfireFilterCategory, setWildfireFilterCategory] = useState('');
    const [wildfireFilterSubcategory, setWildfireFilterSubcategory] = useState('');
    const [wildfireFilterItem, setWildfireFilterItem] = useState('');
    
    const [wildfireData, setWildfireData] = useState();

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {
            
            // always start with PAGE, as we should always HAVE that as a param with at LEAST a set default
            let urlParamsAsString = `?page=${wildfireFilterPage}`;

            // loop through any OTHER params and APPEND to url query STRING (starting with ampersand)
            if ( wildfireFilterAgency ) urlParamsAsString = urlParamsAsString + `&agency=${wildfireFilterAgency}`;
            if ( wildfireFilterCategory ) urlParamsAsString = urlParamsAsString + `&category=${wildfireFilterCategory}`;
            if ( wildfireFilterSubcategory ) urlParamsAsString = urlParamsAsString + `&subcategory=${wildfireFilterSubcategory}`;
            if ( wildfireFilterItem ) urlParamsAsString = urlParamsAsString + `&item=${wildfireFilterItem}`;

            const url = ApiEndpoints.dipNationalInventory() + urlParamsAsString;

            axios
                .get(
                    url,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setWildfireData(data);

                    // flag API as accessible
                    setIsApi(true);
                })
                .catch((error) => {
                    console.log(error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);
                })

        } else {
            setWildfireData( null );
        }

    }, [ isLoggedIn, wildfireFilterPage, wildfireFilterAgency, wildfireFilterCategory, wildfireFilterSubcategory, wildfireFilterItem, i18n.language ]);

    const [wildfireTableRows, setWildfireTableRows] = useState();

    const [wildfireOptionsPagination, setWildfireOptionsPagination] = useState();
    const [wildfirePaginationSummary, setWildfirePaginationSummary] = useState();

    const [wildfireOptionsAgencies, setWildfireOptionsAgencies] = useState();
    const [wildfireOptionsCategories, setWildfireOptionsCategories] = useState();
    const [wildfireOptionsSubcategory, setWildfireOptionsSubcategories] = useState();
    const [wildfireOptionsItems, setWildfireOptionsItems] = useState();

    useEffect(() => {

        // populate AGENCIES filter options

        if ( AgenciesData ) {
            setWildfireOptionsAgencies(
                <>
                    {
                        Object.keys( AgenciesData ).map(function(dataKey, index) {

                            // AGENCY display label...and translate, if available
                            let agency_display_name = t(AgenciesData[dataKey].name) ? t(AgenciesData[dataKey].name) : AgenciesData[dataKey].name;
                            
                            // do NOT translation the option VALUE, as that needs to be as is to do a data match
                            return <option key={ index } value={ dataKey }>{ agency_display_name }</option>
                        })
                    }
                </>
            );
        }

        // if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_agencies ) {
        //     setWildfireOptionsAgencies(
        //         <>
        //             {
        //                 Object.keys( wildfireData.meta.filter_agencies ).map(function(dataKey, index) {

        //                     // AGENCY display label...and translate, if available
        //                     let agency_display_name = t(wildfireData.meta.filter_agencies[dataKey].field_name) ? t(wildfireData.meta.filter_agencies[dataKey].field_name) : wildfireData.meta.filter_agencies[dataKey].field_name;
                            
        //                     // do NOT translation the option VALUE, as that needs to be as is to do a data match
        //                     return <option key={ index } value={ wildfireData.meta.filter_agencies[dataKey].field_code }>{ agency_display_name }</option>
        //                 })
        //             }
        //         </>
        //     );
        // }

        // populate CATEGORIES filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_categories ) {
            setWildfireOptionsCategories(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_categories ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_categories[dataKey].field_code }>{ t(wildfireData.meta.filter_categories[dataKey].field_name) ? t(wildfireData.meta.filter_categories[dataKey].field_name) : wildfireData.meta.filter_categories[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate SUBCATEGORIES filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_subcategories ) {
            setWildfireOptionsSubcategories(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_subcategories ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_subcategories[dataKey].field_code }>{ t(wildfireData.meta.filter_subcategories[dataKey].field_name) ? t(wildfireData.meta.filter_subcategories[dataKey].field_name) : wildfireData.meta.filter_subcategories[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate ITEMS filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_items ) {
            setWildfireOptionsItems(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_items ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_items[dataKey].field_code }>{ t(wildfireData.meta.filter_items[dataKey].field_name) ? t(wildfireData.meta.filter_items[dataKey].field_name) : wildfireData.meta.filter_items[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate TABLE with paginated ROWS

        if ( wildfireData && wildfireData.rows ) {

            setWildfireTableRows(
                <>
                    <tbody>
                        { 
                            Object.keys( wildfireData.rows ).map(function(dataKey, index) {
                                return <tr key={ index }>
                                    <td>{ wildfireData.rows[dataKey].agency.toUpperCase() }</td>
                                    
                                    <td>{ t(wildfireData.rows[dataKey].category) }</td>
                                    <td>{ t(wildfireData.rows[dataKey].subcategory) }</td>
                                    <td>{ t(wildfireData.rows[dataKey].item) }</td>

                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData.rows[dataKey].seasonal } /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData.rows[dataKey].exportable } /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData.rows[dataKey].available } /></td>


                                    <td className="right">{ moment( wildfireData.rows[dataKey].last_update ).format('ll') }</td>
                                </tr>
                            })
                        }
                    </tbody>
                </>
            );

            let paginationArray = Array.from({length: wildfireData.meta.total_pages}, (_, i) => i + 1);
        
            setWildfireOptionsPagination(
                <>
                    {
                        Object.keys( paginationArray ).map(function(dataKey, index) {
                            return <option key={ index } value={ paginationArray[dataKey] }>{ paginationArray[dataKey] }</option>
                        })
                    }
                </>
            );

            setWildfirePaginationSummary(
                <>
                    { t('of') } <CiffcNumberFormat type="int" number={ wildfireData.meta.total_results } /> { t('results') }
                </>
            );

        }

    }, [wildfireData, t]);

    // define HANDLERs for filter pulldowns

    const handleOptionsChangeAgencies = event => {
        setWildfireFilterPage(1);
        setWildfireFilterAgency(event.target.value);
    };

    const handleOptionsChangeCategories = event => {
        setWildfireFilterPage(1);
        setWildfireFilterCategory(event.target.value);
    };

    const handleOptionsChangeSubcategories = event => {
        setWildfireFilterPage(1);
        setWildfireFilterSubcategory(event.target.value);
    };

    const handleOptionsChangeItems = event => {
        setWildfireFilterPage(1);
        setWildfireFilterItem(event.target.value);
    };

    const handleOptionsChangePagination = event => {
        setWildfireFilterPage(event.target.value);
    };

    // define HANDLERs for download buttonm using react-csv LIB (https://github.com/react-csv/react-csv)

    const [wildfireCSV, setWildfireCSV] = useState([]);

    const csvLink = React.createRef();
    
    const handleExportToCsv = () => {

        setWildfireCSV([]); // start it EMPTY, we use this state as FLAG to trigger a download later

        // always start with PAGE, as we should always HAVE that as a param with at LEAST a set default
        let urlParamsAsString = `?export=true`;

        // loop through any OTHER params and APPEND to url query STRING (starting with ampersand)
        if ( wildfireFilterAgency ) urlParamsAsString = urlParamsAsString + `&agency=${wildfireFilterAgency}`;
        if ( wildfireFilterCategory ) urlParamsAsString = urlParamsAsString + `&category=${wildfireFilterCategory}`;
        if ( wildfireFilterSubcategory ) urlParamsAsString = urlParamsAsString + `&subcategory=${wildfireFilterSubcategory}`;
        if ( wildfireFilterItem ) urlParamsAsString = urlParamsAsString + `&item=${wildfireFilterItem}`;

        const url = ApiEndpoints.dipWildfires() + urlParamsAsString;

        axios
            .get( url )
            .then(({ data }) => {
                setWildfireCSV(data.rows);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // if CSV DATA TO DOWNLOAD finished fetching, only THEN trigger the download but programically pressing the button

    useEffect(() => {
        if ( wildfireCSV.length ) {
            csvLink.current.link.click();
        }
    }, [wildfireCSV]);

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t('Inventory') */ }
                <StaticContent staticContentAlias="inventory" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <div className="table-filter">
                    <div>
                        <label>{ t('Agency') }</label>
                        <select onChange={ handleOptionsChangeAgencies }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsAgencies }
                        </select>
                    </div>

                    <div>
                        <label>{ t('Category') }</label>
                        <select onChange={ handleOptionsChangeCategories }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsCategories }
                        </select>
                    </div>

                    <div>
                        <label>{ t('Subcategory') }</label>
                        <select onChange={ handleOptionsChangeSubcategories }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsSubcategory }
                        </select>
                    </div>

                    <div>
                        <label>{ t('Item') }</label>
                        <select onChange={ handleOptionsChangeItems }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsItems }
                        </select>
                    </div>

                    <div className="to-right">
                        <button className="button" onClick={ handleExportToCsv }>
                            <FontAwesomeIcon icon={ solid('cloud-arrow-down') } />
                            { t('Export') } (CSV)
                        </button>
                    </div>

                    <CSVLink 
                        className="hidden" 
                        filename={ "ciffc.csv" } 
                        data={ wildfireCSV }
                        ref={csvLink}
                    />

                </div>
                
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency Code') }</th>
                            <th>{ t('Category') }</th>
                            <th>{ t('Sub-Category') }</th>
                            <th>{ t('Item') }</th>
                            <th className="right">{ t('Seasonal') }</th>
                            <th className="right">{ t('Exportable') }</th>	
                            <th className="right">{ t('Available') }</th>	
                            <th className="right">{ t('Last Update') }</th>
                        </tr>
                    </thead>
                    { wildfireTableRows }
                </TableWrapper>
                
                <div className="table-pagination">
                    <label>{ t('Page') }</label>
                    <select onChange={ handleOptionsChangePagination }>
                        { wildfireOptionsPagination }
                    </select>
                    <span>{ wildfirePaginationSummary }</span>
                </div>

            </div>
        </section>

    );

}

export default Inventory;
import { useState } from "react";
import moment from 'moment';

// Hook, via https://usehooks.com/useLocalStorage/

function useLocalStorage(key, initialValue, expiryInSeconds=false) {
    
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
      if (typeof window === "undefined") {
        return initialValue;
      }
      try {

        if ( expiryInSeconds===false ) {

          // Get from local storage by key
          // const item = window.localStorage.getItem(key);
          // Parse stored json or if none return initialValue
          // return item ? JSON.parse(item) : initialValue;

          // ORIGINALLY, we wanted FALSE to be equivilant to "cache forever", but because that causes problems, we will use FALSE to instead act as "never cache", and trust a REALLY BIG NUMBER to be "cache forever instead"
          return initialValue;

        } else {

          // get CURRENT moment
          const currentMoment = moment();
          
          // get EXPIRY moment from local storage first...
          const expiryKey = 'expiry-'+key;
          let storedExpiry = window.localStorage.getItem(expiryKey);

          if (
            !storedExpiry
            || storedExpiry.toString().toLowerCase()==='false'
            || storedExpiry.toString().toLowerCase()==='null'
            || storedExpiry.toString().toLowerCase()===''
          ) {
            storedExpiry = false;
          } else {
            storedExpiry = moment(storedExpiry);
          }

          // if CACHE has not yet expired...
          if (
            storedExpiry!==false
            && storedExpiry.isValid()
            && storedExpiry.diff(currentMoment) > 0
          ) {
            
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;

          } else {
            return initialValue;
          }

        }
        
      } catch (error) {
        // If error also return initialValue
        return initialValue;
      }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = ( value ) => {
      try {
        
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        
          // Save state
        setStoredValue(valueToStore);

        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
          
          // ...and save EXPIRY value
          const expiryKey = 'expiry-'+key;
          if ( expiryInSeconds===false ) {
            window.localStorage.setItem( expiryKey, false );
          } else {
            const expiryMoment = moment().add( parseInt(expiryInSeconds), 'seconds' ).format("YYYY-MM-DD HH:mm:ss");
            window.localStorage.setItem( expiryKey, expiryMoment );
          }

        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    };

    return [storedValue, setValue];

  }

  export default useLocalStorage;
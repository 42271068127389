import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableMobilizationsNational( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    const [tableHeaders, setTableHeaders] = useState(); 
    const [tableDisplay, setTableDisplay] = useState(); 

    useEffect(() => {

        if (
            props.agencies &&  Array.isArray(props.agencies)
        ) {
            setTableHeaders(
                <thead>
                    <tr>
                        <th>{ t('Item') }</th>
                        {
                            Object.keys( props.agencies ).map(function(dataKey, index) {
                                return <th className="right" key={ index }>{ props.agencies[dataKey].upper }</th>
                            })
                        }
                        <th className="right">{ t('CIFFC') }</th>
                        <th className="right">{ t('Intl') }</th>
                        <th className="right">{ t('Total') }</th>
                    </tr>
                </thead>
            );
        }

    }, [ props.agencies, t ]);

    useEffect(() => {

        if ( 
            props.agencies && Array.isArray(props.agencies)
            && props.data && Array.isArray(props.data)
        ) {

            let tableRows = {};

            props.data.forEach( (dataProp) => {

                let dataPropItem = dataProp.field_section;
                let dataPropCategory = 'Misc'; // fallback CATEGORY

                // try to find the PROPER categorization if possible
                if ( props.resources && Array.isArray(props.resources) ) {
                    props.resources.forEach( (dataPropResource) => {
                        if ( dataPropItem === dataPropResource.field_item || dataPropItem === dataPropResource.field_item_short ) {
                            dataPropCategory = dataPropResource.field_category;
                        }

                        else if ( dataPropItem === dataPropResource.field_subcategory ) {
                            dataPropCategory = dataPropResource.field_category;
                        }
                    });
                }
                
                // create OBJECT properties if they don't already exist

                if ( !tableRows.hasOwnProperty( dataPropCategory ) ) {
                    tableRows[ dataPropCategory ] = {}
                }
                    
                if ( !tableRows[ dataPropCategory ].hasOwnProperty( dataPropItem ) ) {
                    tableRows[ dataPropCategory ][ dataPropItem ] = {
                        item: dataPropItem,
                        total: 0,
                        CIFFC: 0,
                        INTL: 0
                    };

                    // create PROPERTY placeholders for all available regions

                    props.agencies.forEach( (dataAgencyProp) => {
                        tableRows[ dataPropCategory ][ dataPropItem ][ dataAgencyProp.upper.toUpperCase() ] = 0;
                    });
                }

                // finally, add COUNTs to region and total, which now properly exist

                tableRows[ dataPropCategory ][ dataPropItem ][ dataProp.field_agency.toUpperCase() ] = parseInt(dataProp.field_amount);
                tableRows[ dataPropCategory ][ dataPropItem ].total = tableRows[ dataPropCategory ][ dataPropItem ].total + parseInt(dataProp.field_amount);
            });
            
            setTableDisplay(
                props.resourceOptions.categories.map( (category, categoryIndex) => {
                    if ( tableRows.hasOwnProperty(category) ) {
                        
                        return <tbody key={categoryIndex}>
                            <tr>
                                <th colSpan={ (4 + props.agencies.length) }>{ t(category) }</th>
                            </tr>
                            {
                                // loop through SORTED resource OPTIONs and display any matches...

                                props.resourceOptions.subcategories[category].map( (subcategory, subcategoryIndex) => {

                                    return props.resourceOptions.resources[category][subcategory].map( ( resourceLookup, resourceLookupIndex ) => {
                                        
                                        // ...for the SUBCATEGORY, pre-2023
                                        if (tableRows[category].hasOwnProperty(subcategory)) {

                                            const displayTableRow = tableRows[category][subcategory];
                                            delete tableRows[category][subcategory];
                                            
                                            return parseInt(displayTableRow.total) === 0 ? '' : <tr key={ categoryIndex+'-'+subcategoryIndex }>
                                                <td>{ t(displayTableRow.item) }</td>
                                                {
                                                    Object.keys( props.agencies ).map(function(dataAgencyKey, indexAgency) {
                                                        return <td key={indexAgency} className="right"><CiffcNumberFormat type="int" number={ displayTableRow[ props.agencies[dataAgencyKey].upper ] } /></td>
                                                    })
                                                }
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.CIFFC } /></td>
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.INTL } /></td>
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.total } /></td>
                                            </tr>

                                        }
                                        
                                        // ...for the ITEMS, 2023 and forward
                                        if (tableRows[category].hasOwnProperty(resourceLookup)) {

                                            const displayTableRow = tableRows[category][resourceLookup];
                                            delete tableRows[category][resourceLookup];
                                            
                                            return parseInt(displayTableRow.total) === 0 ? '' : <tr key={ categoryIndex+'-'+subcategoryIndex+'-'+resourceLookupIndex }>
                                                <td>{ displayTableRow.item }</td>
                                                {
                                                    Object.keys( props.agencies ).map(function(dataAgencyKey, indexAgency) {
                                                        return <td key={indexAgency} className="right"><CiffcNumberFormat type="int" number={ displayTableRow[ props.agencies[dataAgencyKey].upper ] } /></td>
                                                    })
                                                }
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.CIFFC } /></td>
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.INTL } /></td>
                                                <td className="right"><CiffcNumberFormat type="int" number={ displayTableRow.total } /></td>
                                            </tr>

                                        }

                                        return null;
                                        
                                    })
                                })


                            }

                            {
                                // ...then LOOP through remaining UNSORTED results we couldn't directly match (usually historical?)...

                                Object.keys(tableRows[category]).map(function(dataKey, dataIndex) {
                                    return parseInt(tableRows[category][dataKey].total) === 0 ? '' : <tr key={ categoryIndex+'-unsorted-'+dataIndex }>
                                        <td>{ tableRows[category][dataKey].item }</td>
                                        {
                                            Object.keys( props.agencies ).map(function(dataAgencyKey, indexAgency) {
                                                return <td key={indexAgency} className="right"><CiffcNumberFormat type="int" number={ tableRows[category][dataKey][ props.agencies[dataAgencyKey].upper ] } /></td>
                                            })
                                        }
                                        <td className="right"><CiffcNumberFormat type="int" number={ tableRows[category][dataKey].CIFFC } /></td>
                                        <td className="right"><CiffcNumberFormat type="int" number={ tableRows[category][dataKey].INTL } /></td>
                                        <td className="right"><CiffcNumberFormat type="int" number={ tableRows[category][dataKey].total } /></td>
                                    </tr>
                                })

                            }
                        </tbody>

                    }

                    return null;
                })
            );

            /*
            setTableDisplay(
                Object.keys(tableRows).map(function(dataCat, indexCategory) {
                    return <tbody key={indexCategory}>
                        <tr>
                            <th colSpan={ (4 + props.agencies.length) }>{ dataCat }</th>
                        </tr>
                        {
                            Object.keys(tableRows[dataCat]).map(function(dataKey, index) {
                                return <tr key={ index }>
                                    <td>{ tableRows[dataCat][dataKey].item }</td>
                                    {
                                        Object.keys( props.agencies ).map(function(dataAgencyKey, indexAgency) {
                                            return <td key={indexAgency} className="right">{ tableRows[dataCat][dataKey][ props.agencies[dataAgencyKey].upper ] }</td>
                                        })
                                    }
                                    <td className="right">{ tableRows[dataCat][dataKey].CIFFC }</td>
                                    <td className="right">{ tableRows[dataCat][dataKey].INTL }</td>
                                    <td className="right">{ tableRows[dataCat][dataKey].total }</td>
                                </tr>
                            })
                        }
                    </tbody>
                })
                
            );
            */

        }

    }, [ props.agencies, props.data, props.resourceOptions, t ]);

    return(
        <TableWrapper>
            { tableHeaders }
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableMobilizationsNational };
import React, { useEffect, useState } from "react";
import useLocalStorage from "../components/hooks/UseLocalStorage";
import axios from "axios";
import moment from 'moment-timezone';

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';
import PreparationLevels from "../constants/PreparationLevels";

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// CONTENT
import StaticContent from "./elements/StaticContent";

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// MAPPING
import { Map, Overlay, ZoomControl } from "pigeon-maps";
import { PigeonCustomerMarkerCenterAnchor as Marker } from "../components/PigeonCustomerMarkerCenterAnchor";


// CHARTING
import ConfigCharts from "../constants/ConfigCharts";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

// DEFINE custom map legend definition of circle sizes, repeated for all legend items...

function MapLegendCircleDefinitions(props){
    const socClassName = props.socClassName ? props.socClassName : null;

    return <ul className={ socClassName }>
        <li>
            <span className="map-legend-circle"><FontAwesomeIcon style={{fontSize:8}} icon={ solid('circle') } /></span>
            <span className="map-legend-ha"><CiffcNumberFormat type="int" number="1" /> - <CiffcNumberFormat type="int" number="100" /> Ha</span>
        </li>
        <li>
            <span className="map-legend-circle"><FontAwesomeIcon style={{fontSize:12}} icon={ solid('circle') } /></span>
            <span className="map-legend-ha"><CiffcNumberFormat type="int" number="101" /> - <CiffcNumberFormat type="int" number="1000" /> Ha</span>
        </li>
        <li>
            <span className="map-legend-circle"><FontAwesomeIcon style={{fontSize:16}} icon={ solid('circle') } /></span>
            <span className="map-legend-ha">&gt; <CiffcNumberFormat type="int" number="1000" /> Ha</span>
        </li>
    </ul>
}

// DEFINE custom card stat VALUE display, for consistency...

function FormattedStat(props){
    let formattedStatDisplay = parseInt( props.number );

    // if NUMBER greater than certain amount, we want to ABBREVIATE it
    
    if ( props.number>1000000 ) {
        formattedStatDisplay = <><CiffcNumberFormat decimal="1" number={ (parseFloat(props.number) / 1000000.00) } /><span className="stat-abbrev">M</span></>;
    }

    else if ( props.number>10000 ) {
        formattedStatDisplay = <><CiffcNumberFormat decimal="1" number={ (parseFloat(props.number) / 1000.00) } /><span className="stat-abbrev">K</span></>;
    }

    else {
        formattedStatDisplay = <CiffcNumberFormat decimalMin="0" decimalMax="1" number={ parseFloat(props.number) } />;
    }

    return formattedStatDisplay;
}

// DEFINE custom map POPUP when a circle is rolled over, for consistency...

function Popover(props){
    
    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // console.log(props);

    let displayStageOfControl = props.content.field_stage_of_control_status;
    let classNameStageOfControl = '';

    if ( displayStageOfControl === 'UC' ) {
        displayStageOfControl = t('Under Control');
        classNameStageOfControl = 'soc-uc';
    } else if ( displayStageOfControl === 'UC' ) {
        displayStageOfControl = t('Under Control');
        classNameStageOfControl = 'soc-uc';
    } else if ( displayStageOfControl === 'OC' ) {
        displayStageOfControl = t('Out of Control');
        classNameStageOfControl = 'soc-oc';
    } else if ( displayStageOfControl === 'BH' ) {
        displayStageOfControl = t('Being Held');
        classNameStageOfControl = 'soc-bh';
    } else if ( displayStageOfControl === 'OUT' ) {
        displayStageOfControl = t('Out');
        classNameStageOfControl = 'soc-out';
    }

    let displayResponseType = props.content.field_response_type;

    if ( displayResponseType === 'MOD' ) {
        displayResponseType = t('Modified Response');
    } else if ( displayResponseType === 'MON' ) {
        displayResponseType = t('Monitored Response');

        // JUN 2024, treating MONITORed RT as pseudo status for display purposes
        if ( classNameStageOfControl === 'soc-oc' ) { classNameStageOfControl = 'soc-mon'; }
    } else if ( displayResponseType === 'FUL' ) {
        displayResponseType = t('Full Response');
    }


    return <div className={ 'map-popover ' + classNameStageOfControl + ' ' + i18n.language + ' ' + ( props.visible ? 'show' : 'hide' ) }>
        <dl>
            <dd>{ t('Agency Fire ID') }</dd> 
                <dt>{ props.content.field_agency_fire_id ? props.content.field_agency_fire_id : '-' }</dt>

            <dd>{ t('Fire Size') }</dd>
                <dt>{ props.content.field_fire_size ? <><CiffcNumberFormat type="ha" number={props.content.field_fire_size} /> HA</> : '-' }</dt>

            <dd>{ t('Stage of Control') }</dd>
                <dt>{ displayStageOfControl ? displayStageOfControl : '-' }</dt>

            <dd>{ t('Response Type') }</dd>
                <dt>{ displayResponseType ? displayResponseType : '-' }</dt>

            <dd>{ t('Last Update') }</dd>
                <dt>{ props.content.field_status_date ? moment(props.content.field_status_date).tz( moment.tz.guess() ).format('MMM D, YYYY h:mma z') : '-' }</dt>
        </dl>
    </div>

}

function Dashboard(){

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Home') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // STAT displays
    const [firesNew, setFiresNew] = useState(null);
    const [firesActive, setFiresActive] = useState(null);
    const [annualFires, setAnnualFires] = useState(null);
    const [annualAreas, setAnnualArea] = useState(null);

    // CHARTING states

    // const [dipAgenciesData, setDipAgenciesData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [dipAgenciesData, setDipAgenciesData] = useLocalStorage("node-dashboard-agencies-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));
    const [chartFiresStages, setChartFiresStages] = useState(null);
    const [chartFiresAgencies, setChartFiresAgencies] = useState(null);
    
    // MAPPING states

    const mapMarkerDataTTL = process.env.REACT_APP_CACHE_IN_SECONDS_GEOSERVER_DASHBOARD_PINS || false;
    const [mapMarkerData, setMapMarkerData] = useLocalStorage("geoserver-firesmap", false, mapMarkerDataTTL);
    
    const [mapMarkersOoc, setMapMarkersOoc] = useState();
    const [mapMarkersBh, setMapMarkersBh] = useState();
    const [mapMarkersUc, setMapMarkersUc] = useState();
    const [mapMarkersMon, setMapMarkersMon] = useState();

    const [mapMarkersUpdating, setMapMarkersUpdating] = useState(true);

    const [showMarkersFireUnderControl, setShowMarkersFireUnderControl] = useState(true);
    const [showMarkersFireOutOfControl, setShowMarkersFireOutOfControl] = useState(true);
    const [showMarkersFireBeingHeld, setShowMarkersFireBeingHeld] = useState(true);
    const [showMarkersFireMonitored, setShowMarkersFireMonitored] = useState(true);

    const [popoverAnchor, setPopoverAnchor] = useState([54.56333, -98.15222]);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverContent, setPopoverContent] = useState(false);

    useEffect(() => {
            if ( !mapMarkerData ) {
                let url = ApiEndpoints.firesMap();

                axios
                    .get( url )
                    .then(({ data }) => {
                        if(
                            typeof(data) === 'object'
                            && data
                            && data.hasOwnProperty('features')
                        ){
                            setMapMarkerData(data);
                        }else{
                            console.log('Geoserver XML error');
                            // Got non-object data from server,
                            // we should probably cache an empty
                            // result so we don't call the server
                            // right away.
                            setMapMarkerData({features:[]});
                        }
                    })
                    .catch((error) => {
                        console.log('Geoserver error');
                        // console.log(error);
                        // Got an error from the server,
                        // we should probably cache an empty
                        // result so we don't call the server
                        // right away.
                        setMapMarkerData( {features:[]} );
                    })
            }
        }, []);

    // parse MAP DATA for MAP MARKERs

    useEffect(() => {

            if ( mapMarkerData ){

                // console.log(data);

                let newMarkersMon = []; // JUN 2024, introducing pseudo status for MONITORed response-type fires
                let newMarkersOoc = [];
                let newMarkersBh = [];
                let newMarkersUc = [];
                
                // const markers = mapMarkerData.features.map((feature,index) => {
                mapMarkerData.features.forEach((feature,index) => {

                    // otherwise, lets define some mouse over/out events (for revealing the OVERLAY)...

                    const markerOnMouseOver = () => {
                        setPopoverContent( feature.properties );
                        setPopoverAnchor( feature.geometry.coordinates );
                        setPopoverVisible( true );
                    }

                    const markerOnMouseOut = () => {
                        setPopoverVisible( false );
                    }

                    // define OBJECT for styling map marker...

                    let markerChildStyle = {
                        borderRadius: 1000,
                        border: '1px solid #000',
                        backgroundColor: "#cccccc",
                        width: 0,
                        height: 0,
                        pointerEvents: 'auto'
                    }
                    

                    // update SIZE of displayed pin based on size of FIRE...

                    markerChildStyle.width = 8;
                    markerChildStyle.height = 8;

                    if ( parseInt(feature.properties.field_fire_size)>1000 ) {
                        markerChildStyle.width = 16;
                        markerChildStyle.height = 16;
                    } else if ( parseInt(feature.properties.field_fire_size)>100 ) {
                        markerChildStyle.width = 12;
                        markerChildStyle.height = 12;
                    }

                    // JUN 2024, separating out specifically SOC out-of-control RT monitored from other fire counts

                    if (
                        feature.properties.field_response_type === 'MON'
                        && feature.properties.field_stage_of_control_status === 'OC'
                    ) {
                        markerChildStyle.backgroundColor = '#b3a';

                        newMarkersMon.push(
                            <Marker
                                key={ index }
                                className='markerFireMonitored'
                                
                                width={markerChildStyle.width}
                                height={markerChildStyle.height}

                                anchor={ feature.geometry.coordinates.reverse() }

                                onMouseOver={markerOnMouseOver}
                                onMouseOut={markerOnMouseOut}
                            >
                                <div style={ markerChildStyle }></div>
                            </Marker>
                        );

                    }

                    // SOC out of control
                    
                    else if (feature.properties.field_stage_of_control_status === 'OC') {
                        markerChildStyle.backgroundColor = '#e00';

                        newMarkersOoc.push(
                            <Marker
                                key={ index }
                                className='markerFireOutOfControl'
                                
                                width={markerChildStyle.width}
                                height={markerChildStyle.height}

                                anchor={ feature.geometry.coordinates.reverse() }

                                onMouseOver={markerOnMouseOver}
                                onMouseOut={markerOnMouseOut}
                            >
                                <div style={ markerChildStyle }></div>
                            </Marker>
                        );
                    }
                    
                    // SOC being held
                    else if (feature.properties.field_stage_of_control_status === 'BH') {
                        markerChildStyle.backgroundColor = '#ed3';

                        newMarkersBh.push(
                            <Marker
                                key={ index }
                                className='markerFireBeingHeld'
                                
                                width={markerChildStyle.width}
                                height={markerChildStyle.height}

                                anchor={ feature.geometry.coordinates.reverse() }

                                onMouseOver={markerOnMouseOver}
                                onMouseOut={markerOnMouseOut}
                            >
                                <div style={ markerChildStyle }></div>
                            </Marker>
                        );
                    }

                    // SOC under control
                    else if (feature.properties.field_stage_of_control_status === 'UC') {
                        markerChildStyle.backgroundColor = '#00a2ff';

                        newMarkersUc.push(
                            <Marker
                                key={ index }
                                className='markerFireUnderControl'
                                
                                width={markerChildStyle.width}
                                height={markerChildStyle.height}

                                anchor={ feature.geometry.coordinates.reverse() }

                                onMouseOver={markerOnMouseOver}
                                onMouseOut={markerOnMouseOut}
                            >
                                <div style={ markerChildStyle }></div>
                            </Marker>
                        );
                    }
                    
                    // SOC out
                    else if (feature.properties.field_stage_of_control_status === 'OUT') {
                        // nothing
                    }

                });

                // set MARKERs for map
                setMapMarkersOoc(newMarkersOoc);
                setMapMarkersBh(newMarkersBh);
                setMapMarkersUc(newMarkersUc);
                setMapMarkersMon(newMarkersMon);
                setMapMarkersUpdating(false);

            }

        }, [mapMarkerData]) //, i18n.language]);

    // parse MAP DATA for CHARTs

    useEffect(() => {

        if ( mapMarkerData && dipAgenciesData ){

            // console.log(data);

            let countNew = 0;
            let countActive = 0;

            let chartSoc = {};
            let chartAgencies = {};

            Object.keys(dipAgenciesData).forEach((agency_code) => {
                // to use as chart LABEL and array KEY...and translate, if available
                const agency_key_name = t(dipAgenciesData[agency_code].field_name) ? t(dipAgenciesData[agency_code].field_name) : dipAgenciesData[agency_code].field_name;

                chartAgencies[ agency_key_name ] = 0;
            });
            
            mapMarkerData.features.forEach((feature,index) => {
                
                // console.log('feature', feature);

                // for CHARTs display
                let socDisplay = 'na'; 
                const agency_code = feature.properties.field_agency_code.toLowerCase();
                const agency_key_name = t(dipAgenciesData[agency_code].field_name) ? t(dipAgenciesData[agency_code].field_name) : dipAgenciesData[agency_code].field_name; 

                // JUN 2024, separating out specifically SOC out-of-control with RT monitored from other fire counts
                if (
                    feature.properties.field_response_type === 'MON'
                    && feature.properties.field_stage_of_control_status === 'OC'
                ) {
                    countActive++; // count as active STAT
                    socDisplay = t('Out of Control (Monitored)'); // for CHART display
                }
                // SOC under control
                else if (feature.properties.field_stage_of_control_status === 'UC') {
                    countActive++; // count as active STAT
                    socDisplay = t('Under Control'); // for CHART display
                }
                // SOC out of control
                else if (feature.properties.field_stage_of_control_status === 'OC') {
                    countActive++; // count as active STAT
                    socDisplay = t('Out of Control'); // for CHART display
                }
                // SOC being held
                else if (feature.properties.field_stage_of_control_status === 'BH') {
                    countActive++; // count as active STAT
                    socDisplay = t('Being Held'); // for CHART display
                }
                // SOC out
                else if (feature.properties.field_stage_of_control_status === 'OUT') {
                    // nothing, we do not display
                }

                let dateToday = new Date();
                let dateDatabase = new Date(feature.properties.field_situation_report_date);

                // if field_situation_report_date is CURRENT DAY, then count that as a new fire STAT
                if (
                    dateToday.getUTCFullYear() === dateDatabase.getUTCFullYear()
                    && dateToday.getUTCMonth() === dateDatabase.getUTCMonth()
                    && dateToday.getUTCDate() === dateDatabase.getUTCDate()
                ) {
                    countNew++;
                }
                
                // if SOC is active, then use counts for CHARTs
                if ( feature.properties.field_stage_of_control_status !== 'OUT' ) {

                    // populate SOC chart data
                    if ( !chartSoc.hasOwnProperty(socDisplay) ) {  chartSoc[ socDisplay ] = 0; }
                    chartSoc[ socDisplay ]++;

                    // populate AGENCY chart data
                    chartAgencies[ agency_key_name ]++;

                }
            });

            // format DASHBOARD stats
            setFiresNew(<FormattedStat number={countNew} />);
            setFiresActive(<FormattedStat number={countActive} />);

            // set chart OPTIONS
            
            const chartOptionsSoc = ConfigCharts.barHorizontalOptions();

            // config SOC chart for display

            let chartSocData = {
                labels: [],
                datasets: [{
                    label: t('Stage of Control'),
                    data: [],
                    backgroundColor: []
                }]
            };

            // matching ORDER and COLORS used on DASHBOARD and MAP PINs

            const keyOoc = t("Out of Control");
            if ( chartSoc.hasOwnProperty(keyOoc) ) {
                chartSocData.labels.push(keyOoc);
                chartSocData.datasets[0].data.push( chartSoc[keyOoc] );
                // chartSocData.datasets[0].backgroundColor.push( '#F5333F' );
                chartSocData.datasets[0].backgroundColor.push( '#e00' ); // JUN 2024 colors
            }
            
            const keyBh = t("Being Held");
            if ( chartSoc.hasOwnProperty(keyBh) ) {
                chartSocData.labels.push(keyBh);
                chartSocData.datasets[0].data.push( chartSoc[keyBh] );
                // chartSocData.datasets[0].backgroundColor.push( '#336667' );
                chartSocData.datasets[0].backgroundColor.push( '#ed3' ); // JUN 2024 colors
            }

            const keyUc = t("Under Control");
            if ( chartSoc.hasOwnProperty(keyUc) ) {
                chartSocData.labels.push(keyUc);
                chartSocData.datasets[0].data.push( chartSoc[keyUc] );
                // chartSocData.datasets[0].backgroundColor.push( '#F9A123' );
                chartSocData.datasets[0].backgroundColor.push( '#00a2ff' ); // JUN 2024 colors
            }

            // JUN 2024, adding fourth color for MONITORED pseudo status
            const keyMon = t("Out of Control (Monitored)");
            if ( chartSoc.hasOwnProperty(keyMon) ) {
                chartSocData.labels.push(keyMon);
                chartSocData.datasets[0].data.push( chartSoc[keyMon] );
                chartSocData.datasets[0].backgroundColor.push( '#b3a' ); 
            }

            setChartFiresStages( <Bar options={chartOptionsSoc} data={chartSocData} /> );

            // config AGENCY chart for display
            
            const chartOptionsAgencies = ConfigCharts.barHorizontalOptions();

            let chartAgencyData = {
                labels: [],
                datasets: [{
                    label: t('Agency'),
                    data: [],
                    backgroundColor: ConfigCharts.defaultColors[0],
                }]
            };

            Object.keys(chartAgencies).map(function(k) {
                chartAgencyData.labels.push(k);
                chartAgencyData.datasets[0].data.push(chartAgencies[k]);
                return true;
            });

            setChartFiresAgencies( <Bar options={chartOptionsAgencies} data={chartAgencyData} /> );

        }

    }, [mapMarkerData, dipAgenciesData, i18n.language]);

    // const [sitrepData, setSitrepData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [sitrepData, setSitrepData] = useLocalStorage("node-dashboard-sitrep-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    // const [historicalData, setHistoricalData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [historicalData, setHistoricalData] = useLocalStorage("node-dashboard-historical-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    useEffect(() => {

        // for list of AGENCIES in all tables
        if ( !dipAgenciesData ) {
            
            let urlAgencies = ApiEndpoints.dipAgencies() + '?has_siterep=true';

            axios
                .get( urlAgencies )
                .then(({ data }) => {

                    let agenciesFormatted = {};
                    data.forEach(agency => {
                        agenciesFormatted[ agency.field_code.toLowerCase() ] = agency;
                    });

                    setDipAgenciesData(agenciesFormatted);

                    // flag API as accessible
                    setIsApi(true);

                })
                .catch((error) => {
                    console.log(error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);
                });

        } else {
            // if reading CACHE, then assume true
            setIsApi(true);
        }

        // for latest SITREP summary data
        if ( !sitrepData ) {

            let urlSitrep = ApiEndpoints.dipSitrepNationalLatest();

            axios
                .get( urlSitrep )
                .then(({ data }) => {
                    setSitrepData(data);
                    // console.log("sitRep", data);
                })
                .catch((error) => {
                    console.log(error);
                });

        }

        // FIRE WEEKLY, to get YTD displayed values
        if ( !historicalData ) {

            let urlFiresWeekly = ApiEndpoints.firesWeekly();

            axios
                .get( urlFiresWeekly )
                .then(({ data }) => {
                    const currentYear = moment().year();
                    const currentDayOfYear = moment().dayOfYear();

                    let ytdData = {};
                    ytdData[ currentYear ] = {
                        fires: 0,
                        area: 0.00
                    };
                    
                    if(
                        typeof(data) === 'object'
                        && data
                        && data.hasOwnProperty('features')
                    ){
        
                        data.features.forEach((dataProp) => {
                            const reportDayOfYear = moment(dataProp.properties.field_situation_report_date).dayOfYear();
                            const reportYear = moment(dataProp.properties.field_situation_report_date).year();
        
                            const reportArea = parseFloat(dataProp.properties.field_fire_size) || 0.00;
                            
                            // MAY 2024, we're counting carry-over fires for FIRE COUNT, but we're NOT counting carry-over hectares-burned for AREA BURNED

                            ytdData[ currentYear ].fires++;

                            if (
                                reportYear === currentYear
                                && parseInt(reportDayOfYear) >= 1
                                && parseInt(reportDayOfYear) <= parseInt(currentDayOfYear)
                            ) { 
                                ytdData[ currentYear ].area += reportArea;
                            }
                        });
                    }else{
                        console.log('Geoserver weekly fires fetch data error');
                    }
                    
                    // Cache the historical data whether we got something from the geoserver or not
                    setHistoricalData(ytdData);
                })
                .catch((error) => {
                    console.log(error);
                });

        }

    }, []);

    const [sitrepPrepardnessLevel, setSitrepPrepardnessLevel] = useState(null);
    const [sitrepReportDate, setSitrepReportDate] = useState(null);
    const [sitrepReportYear, setSitrepReportYear] = useState(null);
    const [sitrepReportComment, setSitrepReportComment] = useState(null);
    const [sitrepReportLink, setSitrepReportLink] = useState('/situation/');

    useEffect(() => {
        if ( historicalData ) {

            let thisYear = new Date();
            thisYear = thisYear.getFullYear();

            if ( historicalData.hasOwnProperty(thisYear) ) { 
                setAnnualFires( <FormattedStat number={historicalData[thisYear].fires} /> );
                setAnnualArea( <FormattedStat number={historicalData[thisYear].area} /> );
            }

        }
    },  [historicalData ]);

    useEffect(() => {
        if ( sitrepData ) {

            setSitrepPrepardnessLevel( sitrepData.field_preparedness_level );

            let displayComments = i18n.language === 'fr' ? sitrepData.field_fr_summary_comments : sitrepData.field_en_summary_comments;
            setSitrepReportComment( displayComments );

            let translatedDateFormat = moment.localeData().longDateFormat('LL').replace(/YYYY/, '');
            let displayDate = moment( sitrepData.field_date ).format(translatedDateFormat);
            setSitrepReportDate( displayDate );

            let displayYear = moment( sitrepData.field_date ).format('YYYY');
            setSitrepReportYear( displayYear );

            let displayLinkSuffix = moment( sitrepData.field_date ).format('YYYY-MM-DD');
            setSitrepReportLink( '/situation/' + displayLinkSuffix );

        }
    },  [sitrepData, i18n.language]);
    
    return(
      
        <section className="dashboard">

            <div className="container">
                <StaticContent staticContentAlias="homepage-dashboard" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <div className="dashboard-row dashboard-card dashboard-map">
                
                    <div className="dashboard-column dashboard-map-canvas">
                        <Map 
                            defaultCenter={[60, -85]} 
                            defaultZoom={3.8}

                            minZoom={3.8}
                            maxZoom={7.5}

                            metaWheelZoom={true}
                        
                            height={600}  
                        >

                            { showMarkersFireOutOfControl ? mapMarkersOoc : null }
                            { showMarkersFireBeingHeld ? mapMarkersBh : null }
                            { showMarkersFireUnderControl ? mapMarkersUc : null }
                            { showMarkersFireMonitored ? mapMarkersMon : null }

                            <Overlay style={{zIndex:10}} anchor={ popoverAnchor } offset={[200, 155]}>
                                <Popover
                                    visible={ popoverVisible }
                                    content={ popoverContent }
                                />
                            </Overlay>

                            <ZoomControl />
                        </Map>
                    </div>

                    <div className="dashboard-column dashboard-map-legend">

                        <h3 className="map-title">{ t('Active Fires Map') }</h3>

                        { 
                            mapMarkersUpdating 
                                ? <p className="stat-value">-</p>
                                : <ul className="map-legend">
                                    <li className={ showMarkersFireOutOfControl ? null : 'markers-hidden' }>
                                        <h4 className="map-legend-item">
                                            <FontAwesomeIcon
                                                icon={ showMarkersFireOutOfControl ? solid('toggle-on') : solid('toggle-off') }
                                                onClick={()=>{ setShowMarkersFireOutOfControl(current=>!current); }}
                                                focusable="true"
                                                tabIndex="0"
                                            />
                                            { t('Out of Control') }
                                        </h4>

                                        { <MapLegendCircleDefinitions socClassName="soc-oc" /> }
                                    </li>
                                    
                                    <li className={ showMarkersFireBeingHeld ? null : 'markers-hidden' }>
                                        <h4 className="map-legend-item">
                                            <FontAwesomeIcon
                                                icon={ showMarkersFireBeingHeld ? solid('toggle-on') : solid('toggle-off') }
                                                onClick={()=>{ setShowMarkersFireBeingHeld(current=>!current); }}
                                                focusable="true"
                                                tabIndex="0"
                                            />
                                            { t('Being Held') }
                                        </h4>

                                        { <MapLegendCircleDefinitions socClassName="soc-bh" /> }
                                    </li>
        
                                    <li className={ showMarkersFireUnderControl ? null : 'markers-hidden' }>
                                        <h4 className="map-legend-item">
                                            <FontAwesomeIcon
                                                icon={ showMarkersFireUnderControl ? solid('toggle-on') : solid('toggle-off') }
                                                onClick={()=>{ setShowMarkersFireUnderControl(current=>!current); }}
                                                focusable="true"
                                                tabIndex="0"
                                            />
                                            { t('Under Control') }
                                        </h4>
                                        
                                        { <MapLegendCircleDefinitions socClassName="soc-uc" /> }
                                    </li>
        
                                    <li className={ showMarkersFireMonitored ? null : 'markers-hidden' }>
                                        <h4 className="map-legend-item">
                                            <FontAwesomeIcon
                                                icon={ showMarkersFireMonitored ? solid('toggle-on') : solid('toggle-off') }
                                                onClick={()=>{ setShowMarkersFireMonitored(current=>!current); }}
                                                focusable="true"
                                                tabIndex="0"
                                            />
                                            { t('Out of Control (Monitored)') }
                                        </h4>
                                        
                                        { <MapLegendCircleDefinitions socClassName="soc-mon" /> }
                                    </li>
                                </ul>
                        }


                        <p className="map-legend-footer">
                            <a target="_blank" rel="noopener noreferrer" href={ i18n.language==='fr' ? '/pdfs/conditions-dincendie-et-types-dintervention.pdf' : '/pdfs/stages-of-control-and-response.pdf' }>
                                { t('Learn more about the different stages of control and response types') }
                                <FontAwesomeIcon icon={ solid('arrow-right') } />
                            </a>
                        </p>

                    </div>
                </div>

                <div className="dashboard-row">
                    <div className="dashboard-column half">

                        <div className="dashboard-card">
                            
                            <div className="dashboard-stat">
                                <h3 className="stat-label">{ t('National Preparedness Level') }</h3>
                                <p className={ 'apl-lvl-' + sitrepPrepardnessLevel }>{ sitrepPrepardnessLevel === null ? '-' : sitrepPrepardnessLevel }</p>
                            </div>

                            <div className="dashboard-card-summary">

                                { sitrepPrepardnessLevel === null ? null : PreparationLevels.NPL_LVL_DESC(sitrepPrepardnessLevel) }

                                <ul className="dashboard-card-summary-read-more">
                                    <li>
                                        <a target="_blank" rel="noopener noreferrer" href={ i18n.language==='fr' ? '/pdfs/niveaux-de-preparation-nationaux.pdf' : '/pdfs/nationalpreparedness-levels.pdf' }>
                                            { t('Learn more about the NPL') }
                                            <FontAwesomeIcon icon={ solid('arrow-right') } />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                    <div className="dashboard-column half">

                        <div className="dashboard-card">
                            
                            <div className="dashboard-stat">
                                <h3 className="stat-label">{ t('National Fire Situation Report') }</h3>
                                <a className="latest-sitrep-link" href={ sitrepReportLink }>
                                    { sitrepReportDate === null ? t('Latest') : sitrepReportDate }
                                    <span className="latest-sitrep-link-year">{ sitrepReportDate === null ? null : sitrepReportYear }</span>
                                </a>
                            </div>

                            <div className="dashboard-card-summary">
                                <p>{ sitrepReportComment }</p>
                                <ul className="dashboard-card-summary-read-more">
                                    <li>
                                        <a href={ sitrepReportLink }>
                                            { t('Read the full report') }
                                            <FontAwesomeIcon icon={ solid('arrow-right') } />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/situation/archive/">
                                            { t('Browse our report archive') }
                                            <FontAwesomeIcon icon={ solid('arrow-right') } />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
                
                <div className="dashboard-row">
                    <div className="dashboard-column">
                        <div className="dashboard-stat dashboard-card">
                            <h3 className="stat-label">{ t('New Fires Today') }</h3>         
                            <p className="stat-value">
                                { firesNew === null ? '-' : firesNew }
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-column">
                        <div className="dashboard-stat dashboard-card">
                            <h3 className="stat-label">{ t('Active Fires') }</h3>         
                            <p className="stat-value">
                                { firesActive === null ? '-' : firesActive }
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-column">
                        <div className="dashboard-stat dashboard-card">
                            <h3 className="stat-label">{ t('YTD Fires') }</h3>
                            <p className="stat-value">
                                { annualFires=== null ? '-' : annualFires }
                            </p>
                        </div>
                    </div>

                    <div className="dashboard-column">
                        <div className="dashboard-stat dashboard-card">
                            <h3 className="stat-label">{ t('Area Burned YTD (Ha)') }</h3>
                            <p className="stat-value">
                                { annualAreas=== null ? '-' : annualAreas }
                            </p>
                        </div>

                    </div>
                </div>

                <div className="dashboard-row">
                    <div className="dashboard-column half">
                        <div className="dashboard-card dashboard-stat">
                            <h3 className="stat-label">{ t('Active Fires') } / { t('Stage of Control') }</h3> 
                            { chartFiresStages }
                        </div>
                    </div>

                    <div className="dashboard-column half">
                        <div className="dashboard-card dashboard-stat">
                            <h3 className="stat-label">{ t('Active Fires') } / { t('Agency') }</h3> 
                            { chartFiresAgencies }
                        </div>
                    </div>

                </div>
            </div>
        </section>

    )
}

export default Dashboard;
import { useRef, useEffect } from 'react'

function useDocumentTitle(title = false, prevailOnUnmount = false) {
  
  // start with what we want the DEFAULT to be if not passed anything (usually will match what is set normally in the INDEX file)
  const defaultTitle = useRef( document.title ); 

  // set the TITLE manually...
  useEffect(() => {
    if ( title!==false && title!==null && title!=='' ) { 
        document.title = 'CIFFC' + ( title ? ' | '+title : '' );
        
        // ...and trigger a GOOGLE ANALYTICS page view event

        const gaMeasurementID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || false;

        if (
            typeof window.gtag !== "undefined" // if the GA script is available
            && gaMeasurementID // and if the ENV variable for the analytics is set
        ) {
            // console.log( 'trigger GA page view event for "'+title+'", "'+window.location.href+'"' );

            window.gtag("event", "page_view", {
                page_title: title,
                page_location: window.location.href
            });
        }
    }
  }, [title]);

  // when UNMOUNTING, optionally reset to DEFAULT title
  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, []);
}

export default useDocumentTitle;
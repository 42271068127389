import React, { useEffect, useState } from "react";

import AgenciesData from "../../constants/AgenciesData";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableFiresFullResponse( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    useEffect(() => {

        if ( props.data ) {

            const data = props.data;

            /*
            let tableRows = {};

            agencies.forEach((item) => {
                tableRows[ item.field_code ] = {
                    agency: item.field_name, 
                            
                    soc_oc: 0,
                    soc_bh: 0,
                    soc_uc: 0, 
                        
                    active: 0,
                    active_hectares: 0,

                    soc_out: 0,

                    out_hectares: 0,
                    
                    total: 0,
                    hectares: 0
                }; 
            });
            */
            
            let tableTotals = {
                agency: t('Total'),

                soc_oc: 0,
                soc_bh: 0,
                soc_uc: 0, 
                
                active: 0, 
                active_area: 0.00, 
                
                soc_out: 0,
                out_area: 0.00,
                
                total: 0,
                total_area: 0.00
            };

            Object.keys(data).map(function(dataKey) {

                tableTotals.soc_oc = tableTotals.soc_oc + ( parseInt(data[dataKey].soc_oc) || 0 );
                tableTotals.soc_bh = tableTotals.soc_bh + ( parseInt(data[dataKey].soc_bh) || 0 );
                tableTotals.soc_uc = tableTotals.soc_uc + ( parseInt(data[dataKey].soc_uc) || 0 );

                tableTotals.active = tableTotals.active + ( parseInt(data[dataKey].active) || 0 );
                tableTotals.active_area = tableTotals.active_area + ( parseFloat(data[dataKey].active_area) || 0 );

                tableTotals.soc_out = tableTotals.soc_out + ( parseInt(data[dataKey].soc_out) || 0 );
                tableTotals.out_area = tableTotals.out_area + ( parseFloat(data[dataKey].out_area) || 0 );

                tableTotals.total = tableTotals.total + ( parseInt(data[dataKey].total) || 0 );
                tableTotals.total_area = tableTotals.total_area + ( parseFloat(data[dataKey].total_area) || 0 );

                return true;
            });

            setTableDisplay(
                <>
                    <tbody>
                        { 
                            Object.keys(data).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }

                                return <tr key={ index }>
                                    <td>{ agency_display_name }</td>

                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].soc_oc} /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].soc_bh} /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].soc_uc} /></td>

                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].active} /></td>
                                    <td className="right"><CiffcNumberFormat type="ha" number={data[dataKey].active_area} /></td>

                                    <td className="border-left">{ agency_display_name }</td>

                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].soc_out} /></td>
                                    <td className="right"><CiffcNumberFormat type="ha" number={data[dataKey].out_area} /></td>

                                    <td className="border-left">{ agency_display_name }</td>

                                    <td className="right"><CiffcNumberFormat type="int" number={data[dataKey].total} /></td>
                                    <td className="right"><CiffcNumberFormat type="ha" number={data[dataKey].total_area} /></td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{ tableTotals.agency }</th>

                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.soc_oc} /></th>
                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.soc_bh} /></th>
                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.soc_uc} /></th>

                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.active} /></th>
                            <th className="right"><CiffcNumberFormat type="ha" number={tableTotals.active_area} /></th>

                            <th className="border-left">{ tableTotals.agency }</th>
                            
                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.soc_out} /></th>
                            <th className="right"><CiffcNumberFormat type="ha" number={tableTotals.out_area} /></th>

                            <th className="border-left">{ tableTotals.agency }</th>

                            <th className="right"><CiffcNumberFormat type="int" number={tableTotals.total} /></th>
                            <th className="right"><CiffcNumberFormat type="ha" number={tableTotals.total_area} /></th>
                        </tr>
                    </tfoot>
                </>
            );

        }

    }, [ props.agencies, props.data, t ]);


    const [tableDisplay, setTableDisplay] = useState(); 

    return(
        <TableWrapper>
            <thead>
                <tr>
                    <th>{ t('Agency') }</th>

                    <th className="right" title={ t('Out of Control') }>{ t('OC') }</th>
                    <th className="right" title={ t('Being Held') }>{ t('BH') }</th>
                    <th className="right" title={ t('Under Control') }>{ t('UC') }</th>
                    <th className="right">{ t('Total') }</th>
                    <th className="right">{ t('Hectares') }</th>

                    <th className="border-left">{ t('Agency') }</th>
                    
                    <th className="right">{ t('Out') }</th>
                    <th className="right">{ t('Hectares') }</th>

                    <th className="border-left">{ t('Agency') }</th>

                    <th className="right">{ t('Total') }</th>
                    <th className="right">{ t('Hectares') }</th>
                </tr>
            </thead>
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableFiresFullResponse };
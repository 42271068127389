import React, { useEffect, useState } from "react";
import useLocalStorage from "../components/hooks/UseLocalStorage";
import axios from "axios";

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

import _ from 'lodash'; // https://lodash.com/docs
import { CSVLink } from "react-csv";

import ConfigCharts from "../constants/ConfigCharts";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';


// CONTENT
import StaticContent from "./elements/StaticContent";

// CHARTing

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
} from 'chart.js';

import ChartDataLabels from 'chartjs-plugin-datalabels';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
);

/* !DEFINE consistent display of chart HEADING, with title and CSV export button */

function FiresChartsTitling( props ){

    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    let displayTitle = props.displayTitle || null;
    let csvData = props.csvData || [];
    let csvRef = props.csvRef || null;
    let csvFilename = props.csvFilename || 'ciffc.csv';

    return <hgroup>
        <h4>{ t(displayTitle) }</h4>

        {
            csvData.length
            && csvRef
            && csvFilename
                ? <>
                        <button className="button" onClick={ ()=>csvRef.current.link.click() }>
                            <FontAwesomeIcon icon={ solid('cloud-arrow-down') } />
                            { t('Export') } (CSV)
                        </button>

                        <CSVLink 
                            className="hidden" 
                            filename={ csvFilename } 
                            data={ csvData }
                            ref={ csvRef }
                        />
                    </>
                : null
        }
    </hgroup>;

}

function FiresCharts(){

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    const [dataFiresDaily, setDataFiresDaily] = useLocalStorage("node-charts-fires-daily", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false)); // useState(); 
    const [dataFiresHistoricalYearly, setDataFiresHistoricalYearly] = useLocalStorage("node-charts-fires-historical-yearly", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false)); // useState(); 

    const [chartDailyDisplay, setChartDailyDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>);
    const [chartYearDisplay, setChartYearDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>);
    const [chartHectaresDisplay, setChartHectaresDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>);

    const [csvDailyData, setCsvDailyData] = useState([]);
    const [csvYearData, setCsvYearData] = useState([]);
    const [csvHectaresData, setCsvHectaresData] = useState([]);

    const csvDailyLink = React.createRef();
    const csvYearLink = React.createRef();
    const csvHectaresLink = React.createRef();

    const [ytdFires, setYtdFires] = useLocalStorage("node-charts-ytd-fires", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false)); // useState(); 
    const [weeklyOneYear, setWeeklyOneYear] = useLocalStorage("node-charts-weekly-year", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false)); // useState(); 
    const [weeklyTenYear, setWeeklyTenYear] = useLocalStorage("node-charts-weekly-decade", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false)); // useState(); 

    const [chartWeekDisplay, setChartWeekDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>); 
    const [chartWeekLastYearDisplay, setChartWeekLastYearDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>);
    const [chartWeekTenYearsDisplay, setChartWeekTenYearsDisplay] = useState(<div className="fire-chart-loading"><Spinner /></div>);

    const [csvWeekData, setCsvWeekData] = useState([]);
    const [csvWeekLastYearData, setCsvWeekLastYearData] = useState([]);
    const [csvWeekTenYearsData, setCsvWeekTenYearsData] = useState([]);

    const csvWeekLink = React.createRef();
    const csvWeekLastYearLink = React.createRef();
    const csvWeekTenYearsLink = React.createRef();
    
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Wildfire Graphs') );

    // !DAILY chart useEffects

    useEffect(() => {
        if ( !dataFiresDaily ) {

            const startOfCurrentMonth = moment().startOf('month').format('YYYY-MM-DD');
            const startOfNextMonth = moment().add(1, 'M').startOf('month').format('YYYY-MM-DD');

            const url = ApiEndpoints.firesDaily( startOfCurrentMonth, startOfNextMonth );

            axios
                .get( url )
                .then(({ data }) => {
                    setDataFiresDaily(data);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }, []);

    useEffect(() => {
        if ( dataFiresDaily ) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Fire starts'), t('Day of month'),  );
            
            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }
            
            // DATA to parse, from API (now a separate UE call)

            let data = dataFiresDaily;
        
            // parse DATA from API reply into CHART LIBRARY format

            let chartDataSet = {};
            
            // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

            const chartDataKeys = [ ...Array(32).keys() ].slice(1);
            
            chartDataKeys.forEach((dataKey) => {
                chartDataSet[dataKey] = 0;
            });

            if(
                typeof(data) === 'object'
                && data
                && data.hasOwnProperty('features')
            ){

                data.features.forEach((dataProp) => {
                    // avoid any BAD DATA for fires incorrectly provided with a FUTURE date...
                    if ( moment(dataProp.properties.field_situation_report_date).diff( moment(), 'days' ) <= 0 ) {
                        
                        const dataPropKey = new Date(dataProp.properties.field_situation_report_date).getUTCDate(); // dates coming in UTC, and using normal getDate() would offset it , occasionally returning "30" or "31" if the fire was on the first of the month within the hour offset

                        if ( chartDataSet.hasOwnProperty(dataPropKey) ) { 
                            chartDataSet[dataPropKey]++;
                        }

                    }
                });
            }else{
                console.log('Geoserver daily fires fetch data error');
            }
            
            // configure DATASET
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Fires this day'), Object.values(chartDataSet), 0 )
            ); 

            // set as LABELS for chart data...
            chartData.labels = Object.keys(chartDataSet);

            // set CHART display
            setChartDailyDisplay(<Bar options={chartOptions} data={chartData} />);

            // set CSV data for optional EXPORT
            setCsvDailyData( _.zip(chartData.labels, chartData.datasets[0].data) );

        }
    }, [i18n.language, dataFiresDaily]);

    // !WEEKLY charts data useEffect

    useEffect(() => {
        if ( !ytdFires ) {
                
            const url = ApiEndpoints.firesWeekly();
            
            axios
            .get( url )
            .then(({ data }) => {

                let chartDataSetCurrent = {};
                
                // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

                const chartCurrentKeys = [ ...Array(53).keys() ].slice(1);

                chartCurrentKeys.forEach((dataKey) => {
                    chartDataSetCurrent[dataKey] = 0;
                });
                
                if(
                    typeof(data) === 'object'
                    && data
                    && data.hasOwnProperty('features')
                ){

                    data.features.forEach((dataProp) => {
                        const dataPropKey = moment(dataProp.properties.field_situation_report_date).week();
                        const report_year = moment(dataProp.properties.field_situation_report_date).year();
                        const year = moment().year();

                        if ( report_year === year && chartDataSetCurrent.hasOwnProperty(dataPropKey) && parseInt(dataPropKey) >= 1 ) { 
                            chartDataSetCurrent[dataPropKey]++;
                        }
                    });
                }else{
                    console.log('Geoserver weekly fires fetch data error');
                }


                // calculate YEAR TO DATE amount by walking through each entry and ADDING it to the PREVIOUS

                let prevDataProp = 1;
                let todayWeek = new moment().week();

                for (let dataProp in chartDataSetCurrent) {
                    if ( dataProp==="1" ) continue; // skip FIRST day, as there isn't a PREVIOUS to add to it
                    if ( dataProp > todayWeek ) continue; // stop year-to-date ADDITION after the CURRENT week

                    chartDataSetCurrent[ dataProp ] = chartDataSetCurrent[ dataProp ] + chartDataSetCurrent[ prevDataProp ];
                    prevDataProp++;
                };
                
                // If we want to leave gaps for weeks that didn't change the number
                // of fires from the previous week:
                for (let index in Object.values(chartCurrentKeys).reverse() ) {
                    const dataProp = "" + index;
                    if ( dataProp==="1" ) continue; // skip FIRST day, as there isn't a PREVIOUS to add to it
                    const prevKey = "" + (index - 1);
                    
                    if( chartDataSetCurrent[ prevKey ] === chartDataSetCurrent[ dataProp] ){
                        chartDataSetCurrent[dataProp] = 0;
                    }
                }
                
                setYtdFires(Object.values(chartDataSetCurrent));

            })
            .catch((error) => {
                console.log(error);
            });

        }
    }, [i18n.language]);

    // !WEEKLY charts display useEffect

    useEffect(() => {
        if ( ytdFires ) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Fire starts'),  t('Week') );

            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }

            const chartCurrentKeys = [ ...Array(53).keys() ].slice(1);

            // append dataset to CHART
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Year to date'), ytdFires, 0 )
            ); 

            // set LABELS to data
            chartData.labels = [];
            const year = new moment().year();

            for (let index in chartCurrentKeys){
                let weeknum = chartCurrentKeys[index];
                const weekDate = year + "-W" + (weeknum < 10 ? "0" : "") + weeknum;

                let translatedDateFormat = moment.localeData().longDateFormat('LL').replace(/YYYY/, '');
                const propLabel = (new moment( weekDate )).startOf('isoweek').format(translatedDateFormat);
                chartData.labels.push( propLabel );
            }

            // set CHART display
            setChartWeekDisplay( <Bar options={chartOptions} data={chartData} /> );

            // set CSV data for optional EXPORT
            setCsvWeekData( _.zip(chartData.labels, chartData.datasets[0].data) );

        }
    }, [i18n.language, ytdFires]);


    // !WEEKLY (1 year) charts data useEffect

    useEffect(() => {
        if ( !weeklyOneYear ) {

            const url = ApiEndpoints.firesHistoricalWeekly() + '?years=1';
            
            axios
                .get( url )
                .then(({ data }) => {

                    let chartDataSetHistorical = {};

                    // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

                    let todayWeek = new moment().week();

                    const chartHistoricalKeys = [ ...Array(53).keys() ].slice(1);

                    chartHistoricalKeys.forEach((dataKey) => {
                        chartDataSetHistorical[ dataKey ] = 0;
                    });

                    data.forEach((dataProp) => {
                        const dataPropKey = moment(dataProp.date).week();

                        if (
                            dataPropKey <= todayWeek
                            && chartDataSetHistorical.hasOwnProperty(dataPropKey)
                        ) {
                            chartDataSetHistorical[dataPropKey] = dataProp.avg_fires;
                        }
                    });
                    
                    setWeeklyOneYear( Object.values(chartDataSetHistorical) );
            })
            .catch((error) => {
                console.log(error);
            });

        }
    }, [i18n.language]);
    
    // !WEEKLY (1 year) charts useEffect

    useEffect(() => {
        if (ytdFires && weeklyOneYear) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Fire starts'), t('Week') );

            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }
            
            // append dataset to CHART
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Last year'), weeklyOneYear, 0 )
            ); 
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Year to date'), ytdFires, 1 )
            ); 
            // set LABELS to data
            const chartHistoricalKeys = [ ...Array(53).keys() ].slice(1);

            chartData.labels = chartHistoricalKeys;

            // set CHART display
            setChartWeekLastYearDisplay( <Bar options={chartOptions} data={chartData} /> );

            // set CSV data for optional EXPORT
            setCsvWeekLastYearData( _.zip(chartData.labels, chartData.datasets[0].data, chartData.datasets[1].data) );

        }
    }, [i18n.language, ytdFires, weeklyOneYear]);


    // !WEEKLY (10 years) charts data useEffect
    
    useEffect(() => {
        if ( !weeklyTenYear ) {
            
            const url = ApiEndpoints.firesHistoricalWeekly() + '?years=10';
        
            axios
                .get( url )
                .then(({ data }) => {

                    let chartDataSetHistorical10Year = {};
                    
                    // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

                    let todayWeek = new moment().week();

                    const chartHistoricalKeys = [ ...Array(53).keys() ].slice(1);

                    chartHistoricalKeys.forEach((dataKey) => {
                        chartDataSetHistorical10Year[ dataKey ] = 0;
                    });

                    data.forEach((dataProp) => {
                        const dataPropKey = moment(dataProp.date).week();
                        
                        if (
                            dataPropKey <= todayWeek
                            && dataPropKey > 0
                            && chartDataSetHistorical10Year.hasOwnProperty(dataPropKey)
                        ) {
                            chartDataSetHistorical10Year[dataPropKey] = dataProp.avg_fires;
                        }
                    });
                    
                    setWeeklyTenYear(Object.values(chartDataSetHistorical10Year));
            })
            .catch((error) => {
                console.log(error);
            });

        }
    }, [i18n.language]);


    // !WEEKLY (10 years) charts display useEffect
    
    useEffect(() => {
        if ( ytdFires && weeklyTenYear ) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Fire starts'), t('Week') );
            
            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }
            // append dataset to CHART
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('10-Year Average'), weeklyTenYear, 0 )
            ); 
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Year to date'), ytdFires, 1 )
            ); 

            // set LABELS to data
            const chartHistoricalKeys = [ ...Array(53).keys() ].slice(1);

            chartData.labels = chartHistoricalKeys;

            setChartWeekTenYearsDisplay( <Bar options={chartOptions} data={chartData} /> );

            // set CSV data for optional EXPORT
            setCsvWeekTenYearsData( _.zip(chartData.labels, chartData.datasets[0].data, chartData.datasets[1].data) );

        }
    }, [i18n.language, ytdFires, weeklyTenYear]);


    // !YEAR chart useEffects

    useEffect(() => {
        if ( !dataFiresHistoricalYearly ) {
            
            const url = ApiEndpoints.firesHistoricalYearly();

            axios
                .get( url )
                .then(({ data }) => {
                    setDataFiresHistoricalYearly(data);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
    }, []);

    useEffect(() => {
        if ( dataFiresHistoricalYearly ) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Fire starts'), t('Year') );
            
            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }
            
            // DATA to parse, from API (now a separate UE call)

            let data = dataFiresHistoricalYearly;

            // parse DATA from API reply into CHART LIBRARY format

            let chartDataYearSet = {};
            
            // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

            for (var i = 1980; i <= new Date().getFullYear(); i++) {
            chartDataYearSet[i] = 0;
            }

            data.forEach((dataProp) => {
                const dataPropKey = dataProp.year;

                let dataPropValue = 0;

                for (const k in dataProp) {
                    if ( k !== 'date' && dataProp[k].hasOwnProperty('avg_fires') ) {
                        dataPropValue = dataPropValue + parseInt(dataProp[k].avg_fires);
                    }
                };

                if ( chartDataYearSet.hasOwnProperty(dataPropKey) ) { 
                chartDataYearSet[dataPropKey] = chartDataYearSet[dataPropKey] + dataPropValue;
                }
            });
            
            // configure DATASET
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Fire starts'), Object.values(chartDataYearSet), 0 )
            ); 
            
            // set LABELS based on data KEYS
            chartData.labels = Object.keys(chartDataYearSet);

            // set CHART display
            setChartYearDisplay( <Bar options={chartOptions} data={chartData} /> );

            // set CSV data for optional EXPORT
            setCsvYearData( _.zip(chartData.labels, chartData.datasets[0].data) );

        }
    }, [i18n.language, dataFiresHistoricalYearly]);

    // !HECTARES chart useEffects

    useEffect(() => {
        if ( dataFiresHistoricalYearly ) {

            // set chart OPTIONS
            const chartOptions = ConfigCharts.barOptions( t('Area burned (HA)'), t('Year') );
            
            // placeholder for DATASETS
            let chartData = {
                labels:[],
                datasets:[]
            }
            
            // DATA to parse, from API (now a separate UE call)

            let data = dataFiresHistoricalYearly;

            // parse DATA from API reply into CHART LIBRARY format

            let chartDataHectaresSet = {};
            
            // prepopulate DATASET with KEYs that match our AXIS, with default ZEROs

            for (var i = 1980; i <= new Date().getFullYear(); i++) {
                chartDataHectaresSet[i] = 0;
            }

            data.forEach((dataProp) => {
                const dataPropKey = dataProp.year;

                let dataPropValue = 0;

                for (const k in dataProp) {
                    if ( k !== 'date' && dataProp[k].hasOwnProperty('avg_fires') ) {
                        dataPropValue = dataPropValue + parseInt(dataProp[k].avg_hectares);
                    }
                };

                if ( chartDataHectaresSet.hasOwnProperty(dataPropKey) ) { 
                    chartDataHectaresSet[dataPropKey] = chartDataHectaresSet[dataPropKey] + dataPropValue;
                }
            });

            // configure DATASET
            chartData.datasets.push( 
                ConfigCharts.barDataSet( t('Area burned (HA)'), Object.values(chartDataHectaresSet), 0 )
            ); 
            
            // set LABELS based on data KEYS
            chartData.labels = Object.keys(chartDataHectaresSet);

            // set CHART display
            setChartHectaresDisplay( <Bar options={chartOptions} data={chartData} /> );

            // set CSV data for optional EXPORT
            setCsvHectaresData( _.zip(chartData.labels, chartData.datasets[0].data) );


        }
    }, [i18n.language, dataFiresHistoricalYearly]);

    // !RETURN display
    
    return(
      
        <section className="fires-charts">
            <div className="container">

                { /* t('Fire Statistics') */ }
                <StaticContent staticContentAlias="fire-statistics" />
                
                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Daily Fires" csvData={ csvDailyData } csvRef={ csvDailyLink } csvFilename="ciffc-fires-daily.csv" />
                    { chartDailyDisplay }
                </div>

                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Fires by Week" csvData={ csvWeekData } csvRef={ csvWeekLink } csvFilename="ciffc-fires-weekly.csv" />
                    { chartWeekDisplay }
                </div>

                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Fires by Week vs. Last Year" csvData={ csvWeekLastYearData } csvRef={ csvWeekLastYearLink } csvFilename="ciffc-fires-weekly-vs-year.csv" />
                    { chartWeekLastYearDisplay }
                </div>

                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Fires by Week vs. 10-year Average" csvData={ csvWeekTenYearsData } csvRef={ csvWeekTenYearsLink } csvFilename="ciffc-fires-weekly-vs-decade.csv" />
                    { chartWeekTenYearsDisplay }
                </div>

                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Annual Fires in Canada" csvData={ csvYearData } csvRef={ csvYearLink } csvFilename="ciffc-fires-annual.csv" />
                    { chartYearDisplay }
                </div>

                <div className="fire-chart">
                    <FiresChartsTitling displayTitle="Annual Area Burned in Canada" csvData={ csvHectaresData } csvRef={ csvHectaresLink } csvFilename="ciffc-fires-annual-area.csv" />
                    { chartHectaresDisplay }
                </div>

            </div>
        </section>

    )
}

export default FiresCharts;
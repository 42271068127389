import React, { useEffect } from "react";
import useLocalStorage from "../../components/hooks/UseLocalStorage";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import ApiEndpoints from "../../constants/ApiEndpoints";
import { useTranslation } from 'react-i18next';

import AgenciesData from "../../constants/AgenciesData";

function AgencySidebar(props) {

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    // REDIRECT browser out of authenticated area if user not LOGGED IN

    const history = useHistory();

    // vars and funcs, LOGGING a USER in

    const { isLoggedIn, setLoggedIn, activeUser, setActiveUser, contentPublished } = props;

    const handleLogout = () => {
        setLoggedIn((prev) => !prev);
        setActiveUser([]);

        // ...and immediately PUSH to login page (out of any autheticated areas)
        history.push("/login");
    };

    // vars and funcs, PUBLIC PAGES from backend

    const pagesAgencyTTL = process.env.REACT_APP_CACHE_IN_SECONDS_STRAPI_PAGES || false;
    const [pagesAgency, setPagesAgency] = useLocalStorage("cache-pages", [], pagesAgencyTTL);

    useEffect(() => {
        if ( isLoggedIn ) {
            
            let url = ApiEndpoints.pagesAgency();
            
            axios
                .get( 
                    url,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    },
                    
                )
                .then(({ data }) => {
                    setPagesAgency( data.data )
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
            setPagesAgency( [] )
        }
      }, [isLoggedIn]);

    return (
            <ul className="nav-list agency-sidebar-navigation">
                
                {
                    // for practical reasons, we're currently NOT ALLOWING the homepage(s) to be UNPUBLISHed via the STRAPI system
                }
                <li className="menu-item menu-item-overview">
                    <Link to="/agency">{ t('Overview') }</Link>
                </li>

                {
                    // is LOGGED IN
                    activeUser
                    
                    // and has a recognized AUTH role
                    && activeUser.role
                    && activeUser.role.name
                    && activeUser.role.name.toUpperCase() === 'AUTHENTICATED'

                    // and has a recognized AGENCY code
                    && activeUser.user.agency_code
                    && AgenciesData.hasOwnProperty( activeUser.user.agency_code.toLowerCase() )

                    // content ALIASes may not match URL aliases, due to client changes
                    && contentPublished!==false
                    && contentPublished.includes("sitrep-input-agency")

                    ? <li className="menu-item menu-item-sitrep-input-agency">
                            <Link to="/agency/sitrep">{ t('Agency SitRep Input') }</Link>
                        </li>

                    : null
                }

                {

                    activeUser
                    && activeUser.role
                    && activeUser.role.name
                    && activeUser.role.name.toUpperCase() === 'CIFFC'

                    // content ALIASes may not match URL aliases, due to client changes
                    && contentPublished!==false
                    && contentPublished.includes("sitrep-input-national")

                    ? <li className="menu-item menu-item-sitrep-input-national">
                            <Link to="/agency/national">{ t('National SitRep Input') }</Link>
                        </li>

                    : null
                }

                {

                    // is LOGGED IN
                    activeUser
                    
                    // and has a recognized AUTH role
                    && activeUser.role
                    && activeUser.role.name
                    && activeUser.role.name.toUpperCase() === 'AUTHENTICATED'

                    // and has a recognized AGENCY code
                    && activeUser.user.agency_code
                    && AgenciesData.hasOwnProperty( activeUser.user.agency_code.toLowerCase() )

                    // content ALIASes may not match URL aliases, due to client changes
                    && contentPublished!==false
                    && contentPublished.includes("inventory-input")

                    ? <li className="menu-item menu-item-inventory-input">
                            <Link to="/agency/inventory/input">{ t('Inventory Input') }</Link>
                        </li>

                    : null
                }
                
                {
                    // content ALIASes may not match URL aliases, due to client changes
                    contentPublished!==false
                    && contentPublished.includes("inventory")
                        ? <li className="menu-item menu-item-inventory-summary">
                                <Link to="/agency/inventory">{ t('Inventory Summary') }</Link>
                            </li> 
                        : null
                }
                
                {
                    // CMS pages already use a publish/unpublish state, so we only need to list whichever we receieved from the API

                    pagesAgency.map(({ id, attributes }) => {
                        var usePageAlias = attributes.Alias;
                        var usePageName = attributes.Name;
                        attributes.localizations.data.forEach(element => {
                            // if TRANSLATION provided for LANG currently in, use that NAME instead...
                            if ( element.attributes.locale === i18n.language ) {
                                usePageName = element.attributes.Name;
                            }
                        });

                        return <li key={id} className={"menu-item menu-item-"+usePageAlias}>
                            <Link to={`/agency/page/${usePageAlias}`}>{usePageName}</Link>
                        </li>
                    })
                }

                <li className="menu-item menu-item-logout">
                    <button className="link" onClick={ handleLogout }>{ t('Logout') }</button>
                </li>
            </ul>
    );

}
export default AgencySidebar;
import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableMobilizationsToFrom( props ){
    
    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    useEffect(() => {

        if ( props.data &&  Array.isArray(props.data) ) {

            setTableDisplay(
                <tbody>
                { 
                        Object.keys( props.data ).map(function(dataKey, index) {
                            
                            // use TRANSLATED text provided
                            let translated_field_resource = i18n.language==='fr' ? props.data[dataKey].field_fr_resource : props.data[dataKey].field_en_resource;
                            
                            return <tr key={ index }>
                                <td>{ props.data[dataKey].field_to }</td>
                                <td>
                                    <ul>
                                        {
                                            translated_field_resource
                                            ? translated_field_resource.split("\n").map(function(item, idx) {
                                                    return <li key={idx}>{item}</li>
                                                })
                                            : <li></li>
                                        }
                                    </ul>
                                </td>
                                <td className="right">{ props.data[dataKey].field_from }</td>
                            </tr>
                        })
                    }
                </tbody>
            );

        }

    }, [ props.data, i18n.language ]);

    const [tableDisplay, setTableDisplay] = useState(); 

    return(
        <TableWrapper>
            <thead>
                <tr>
                    <th>{ t('To') }</th>
                    <th>{ t('Resources') }</th>
                    <th className="right">{ t('From') }</th>
                </tr>
            </thead>
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableMobilizationsToFrom };
import React, { useEffect, useState } from "react";
import _ from 'lodash'; // https://lodash.com/docs

// translations
import { useTranslation } from 'react-i18next';

function ResourceOptionSet( props ) {

    // loading TRANSLATION functions
    const { t } = useTranslation();

    // AUTH required to access page content
    const { 
        inputName, 
        inputValue, 
        
        onBlur, 
        
        resourceData, 
        
        formData, 
        setFormData,
        
        useAmounts,
        useLabels,

        labelAmount,
        labelResource,
        
        returnNameOnly, 
        returnShortName
    } = props;

    // explode provided value to ARRAY
    let inputValueArray = (inputValue !== undefined ? inputValue : '').toString();
    inputValueArray = inputValueArray.replaceAll(', ', ',');
    inputValueArray = inputValueArray.split(',');

    if ( !useAmounts ) inputValueArray.unshift(1); // if we don't use AMOUNT, prepend to array to make all below lookups line up

    // OPTION selections
    const [ optionAmount, setOptionAmount ]  = useState(_.get(inputValueArray, 0, 1));
    const [ optionCat, setOptionCat ]  = useState(_.get(inputValueArray, 1, ''));
    const [ optionSubcat, setOptionSubcat ]  = useState(_.get(inputValueArray, 2, ''));
    const [ optionItem, setOptionItem ]  = useState(_.get(inputValueArray, 3, ''));
    const [ optionItemShortName, setOptionItemShortName ]  = useState('');

    const handleResourceOnChange = (event) => {
        if ( event.target.name === "resource_amount" ) {
            setOptionAmount(event.target.value);
        } else if ( event.target.name === "resource_item" ) {
            setOptionItem(event.target.value);
        } else if ( event.target.name === "resource_subcat" ) {
            setOptionSubcat(event.target.value);
        } else if ( event.target.name === "resource_cat" ) {
            setOptionCat(event.target.value);
        }
    };

    useEffect(() => {
        
        if ( resourceData ) {

            if (
                optionCat
                && optionSubcat
                && optionItem

                && resourceData.hasOwnProperty('shortnames')
                && resourceData.shortnames.hasOwnProperty(optionCat)
                && resourceData.shortnames[optionCat].hasOwnProperty(optionSubcat)
                && resourceData.shortnames[optionCat][optionSubcat].hasOwnProperty(optionItem)
            ) {
                setOptionItemShortName( resourceData.shortnames[optionCat][optionSubcat][optionItem] );
            } else {
                setOptionItemShortName('');
            }

            if (
                !optionCat
                || !resourceData.resources[optionCat].hasOwnProperty(optionSubcat)
                || !optionSubcat
                || !resourceData.resources[optionCat][optionSubcat].includes(optionItem)
            ) {
                setOptionItem('');
            }

            if (
                !optionCat
                || !resourceData.subcategories[optionCat].includes(optionSubcat)
            ) {
                setOptionSubcat('');
            }

            let return_value = '';

            // if want to return ONLY the resource itself (not the category and subcategory...)

            if ( returnNameOnly ) {
                if ( optionItem ) {
                    return_value = returnShortName ? optionItemShortName : optionItem; // return SHORT NAME if preferred
                }
            }

            // ...otherwise, return a CONCAT string of category, subcategory, and resource
            
            else {
                let concat_input_value = [];

                if (useAmounts) { concat_input_value.push( optionAmount ? parseInt(optionAmount).toString() : '0' ); }
                if (optionCat) { concat_input_value.push( optionCat ); }
                if (optionSubcat) { concat_input_value.push( optionSubcat ); }
                if (optionItem) { concat_input_value.push( returnShortName ? optionItemShortName : optionItem ); } // concat SHORT NAME if preferred
                
                return_value = concat_input_value.join(', ');
            }

            if ( inputName ) {
                let newFormData = _.cloneDeep(formData); // DEEP CLONING the object, to ensure the SETSTATE about to happen actually "sees" the state change properly
                newFormData = _.set( newFormData, inputName, return_value );

                setFormData( newFormData );
            }
        }
        
    }, [optionCat, optionSubcat, optionItem, optionItemShortName]);

    return <div className="input input-wide input-set">
        
        {
            useAmounts
                ? <div className="input">
                        { useLabels ? <label>{ labelAmount ? t(labelAmount) : t('Amount') }</label> : null }
                        <input name="resource_amount" type="number" min="1" required="required" forinputname={inputName} onChange={handleResourceOnChange} onBlur={onBlur} value={optionAmount} placeholder="0" />
                    </div>
                : null
        }
        
        <div className="input">
            { useLabels ? <label>{ labelResource ? t(labelResource) : t('Resource') }</label> : null }
            <select name="resource_cat" required="required" forinputname={inputName} onChange={handleResourceOnChange} onBlur={onBlur} value={optionCat}>
                <option></option>
                {
                    resourceData 
                    && resourceData.hasOwnProperty('categories')

                        ? resourceData.categories.map( (item, index) => {
                            return <option key={index} value={item}>{ t(item) ? t(item) : item }</option>
                        })

                        : null
                }
            </select>
        </div>

        <div className="input">
            { useLabels ? <label>&nbsp;</label> : null }
            <select name="resource_subcat" required="required" forinputname={inputName} onChange={handleResourceOnChange} onBlur={onBlur} readOnly={!optionCat ? true : false} value={optionSubcat}>
                <option></option>
                {
                    resourceData 
                    && resourceData.hasOwnProperty('subcategories')
                    && optionCat
                    && resourceData.subcategories.hasOwnProperty(optionCat)

                        ? resourceData.subcategories[optionCat].map( (item, index) => {
                            return <option key={index} value={item}>{ t(item) ? t(item) : item }</option>
                        })

                        : null
                }
            </select>
        </div>

        <div className="input">
            { useLabels ? <label>&nbsp;</label> : null }
            <select name="resource_item" required="required" forinputname={inputName} onChange={handleResourceOnChange} onBlur={onBlur} readOnly={!optionSubcat ? true : false} value={optionItem}>
                <option></option>
                {
                    resourceData 
                    && resourceData.hasOwnProperty('subcategories')
                    && optionCat
                    && resourceData.resources.hasOwnProperty(optionCat)
                    && optionSubcat
                    && resourceData.resources[optionCat].hasOwnProperty(optionSubcat)

                        ? resourceData.resources[optionCat][optionSubcat].map( (item, index) => {
                            return <option key={index} value={item}>{ t(item) ? t(item) : item }</option> 
                        })

                        : null
                }
            </select>
        </div>
    </div>;

}

export default ResourceOptionSet;
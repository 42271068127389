export default {

    ab: {
        name: 'Alberta',
        website: 'https://www.arcgis.com/apps/dashboards/3ffcc2d0ef3e4e0999b0cf8b636defa3',
        website_priority_wildfires: 'https://srd.web.alberta.ca/wildfires-of-note',
    },

    bc: {
        name: 'British Columbia',
        website: 'https://wildfiresituation.nrs.gov.bc.ca/map',
        website_priority_wildfires: 'https://wildfiresituation.nrs.gov.bc.ca/map',
    },

    mb: {
        name: 'Manitoba',
        website: 'https://www.gov.mb.ca/nrnd/wildfire_program/index.html',
        website_priority_wildfires: 'https://www.gov.mb.ca/nrnd/wildfire_program/index.html',
    },

    nb: {
        name: 'New Brunswick',
        website: 'https://www2.gnb.ca/content/gnb/en/news/public_alerts/forest_fire_watch.html',
        website_priority_wildfires: 'https://www2.gnb.ca/content/gnb/en/news/public_alerts/forest_fire_watch.html',
    },

    nl: {
        name: 'Newfoundland and Labrador',
        website: 'https://www.gov.nl.ca/ffa/public-education/forestry/forest-fires/',
        website_priority_wildfires: 'https://www.gov.nl.ca/ffa/public-education/forestry/forest-fires/',
    },

    ns: {
        name: 'Nova Scotia',
        website: 'https://novascotia.ca/natr/forestprotection/wildfire/',
        website_priority_wildfires: 'https://novascotia.ca/natr/forestprotection/wildfire/',
    },

    nt: {
        name: 'Northwest Territories',
        website: 'https://www.enr.gov.nt.ca/en/services/wildfire-update',
        website_priority_wildfires: 'https://www.enr.gov.nt.ca/en/services/wildfire-update',
    },

    on: {
        name: 'Ontario',
        website: 'https://www.ontario.ca/page/forest-fires',
        website_priority_wildfires: 'https://www.ontario.ca/page/forest-fires',
    },

    pc: {
        name: 'Parks Canada',
        website: 'https://parks.canada.ca/nature/science/conservation/feu-fire/incendies-wildfires',
        website_priority_wildfires: 'https://parks.canada.ca/nature/science/conservation/feu-fire/incendies-wildfires',
    },

    pe: {
        name: 'Prince Edward Island',
        website: 'https://www.princeedwardisland.ca/en/topic/fire-information',
        website_priority_wildfires: 'https://www.princeedwardisland.ca/en/topic/fire-information',
    },

    qc: {
        name: 'Quebec',
        website: 'https://sopfeu.qc.ca',
        website_priority_wildfires: 'https://sopfeu.qc.ca',
    },

    sk: {
        name: 'Saskatchewan',
        website: 'https://www.saskpublicsafety.ca/emergencies-and-response/active-incidents',
        website_priority_wildfires: 'https://www.saskpublicsafety.ca/emergencies-and-response/active-incidents',
    },

    yt: {
        name: 'Yukon',
        website: 'https://wildfires.service.yukon.ca/',
        website_priority_wildfires: 'https://wildfires.service.yukon.ca/',
    }

}
import React, { useEffect, useState } from "react";
import useLocalStorage from "../../components/hooks/UseLocalStorage";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import ApiEndpoints from "../../constants/ApiEndpoints";
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import { ReactComponent as CiffcLogo } from '../../images/Logo.svg';

import AgencySidebar from "./AgencySidebar";

function Header(props) {

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => { i18n.changeLanguage(lng); };

    // vars and funcs, LOGGING a USER in

    const { isLoggedIn, setLoggedIn, activeUser, setActiveUser, contentPublished } = props;

    // toggle for mobile MENUS

    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        // console.log('Location changed detected...');
        setShowMenu(false);
    }, [location]);

    // vars and funcs, PUBLIC PAGES from backend

    const pagesPublicTTL = process.env.REACT_APP_CACHE_IN_SECONDS_STRAPI_PAGES || false;
    const [pagesPublic, setPagesPublic] = useLocalStorage("cache-pages", false, pagesPublicTTL);

    useEffect(() => {
        if ( pagesPublic===false ) {
            let url = ApiEndpoints.pagesPublic();

            axios
            .get( url )
            .then(({ data }) => {
                setPagesPublic( data.data )
            })
            .catch((error) => {
                console.log(error)
            })
        }
      }, []);

    return (
        <header className={ "app-header " + ( showMenu ? "menu-on" : "" ) }>
            <div className="container">
                
                <h1>
                    <Link to="/">
                        <CiffcLogo />
                    </Link>
                </h1>
                
                <button className="menu-toggle" onClick={ () => setShowMenu(prevCheck => !prevCheck) }>
                    <FontAwesomeIcon icon={ solid('bars') } />
                    <FontAwesomeIcon icon={ solid('close') } />
                </button>
                
                <nav className="main-nav">
                    <ul className="nav-list primary-list">  

                        {
                            // for practical reasons, we're currently NOT ALLOWING the homepage(s) to be UNPUBLISHed via the STRAPI system
                        }
                        <li>
                            <Link to="/">{ t('Home') }</Link>
                        </li>

                        {
                            // content ALIASes may not match URL aliases, due to client changes
                            contentPublished!==false
                            && contentPublished.includes("national-sitrep-archive")
                                ? <li>
                                        <Link to="/situation/archive">{ t('Situation Reports') }</Link>
                                    </li> 
                                : null
                        } 
                        
                        {
                            // content ALIASes may not match URL aliases, due to client changes
                            contentPublished!==false
                            && contentPublished.includes("national-summary")
                                ? <li>
                                        <Link to="/summary">{ t('Current Fires Summary') }</Link>
                                    </li> 
                                : null
                        } 

                        {
                            // content ALIASes may not match URL aliases, due to client changes
                            contentPublished!==false
                            && contentPublished.includes("national-fires")
                                ? <li>
                                        <Link to="/national">{ t('National Fires List') }</Link>
                                    </li> 
                                : null
                        }  

                        {
                            // content ALIASes may not match URL aliases, due to client changes
                            contentPublished!==false
                            && contentPublished.includes("fire-statistics")
                                ? <li>
                                        <Link to="/statistics">{ t('Wildfire Graphs') }</Link>
                                    </li>
                                : null
                        }
    
                        {

                            // CMS pages already use a publish/unpublish state, so we only need to list whichever we receieved from the API

                            pagesPublic!==false
                                
                                ? pagesPublic.map(({ id, attributes }) => {
                                        var usePageAlias = attributes.Alias;
                                        var usePageName = attributes.Name;
                                        attributes.localizations.data.forEach(element => {
                                            // if TRANSLATION provided for LANG currently in, use that NAME instead...
                                            if ( element.attributes.locale === i18n.language ) {
                                                usePageName = element.attributes.Name;
                                            }
                                        });
            
                                        return <li key={id}>
                                            <Link to={`/page/${usePageAlias}`}>{usePageName}</Link>
                                        </li>
                                    })

                                : null
                        }
                    </ul>
    
                    <ul className="nav-list secondary-list">   
                        <li className="language-toggle">
                            {
                                i18n.language === 'fr' ? (
                                    <button className="link" onClick={ ()=>changeLanguage('en') }>English</button>
                                ) : (
                                    <button className="link" onClick={ ()=>changeLanguage('fr') }>Français</button>
                                )
                            }
                            
                            
                        </li>
    
                        {
                            isLoggedIn && activeUser && activeUser.hasOwnProperty('user') ? (
                                <>
                                    <li>
                                        <Link className="pill-shape logged-in" to="/agency">
                                            { activeUser.user.agency_code ? activeUser.user.agency_code.toUpperCase() : activeUser.user.username }
                                            <FontAwesomeIcon icon={ solid('clipboard-user') } />
                                        </Link>

                                        <AgencySidebar isLoggedIn={isLoggedIn} setLoggedIn={setLoggedIn} activeUser={activeUser} setActiveUser={setActiveUser} contentPublished={contentPublished} />
                                    </li>
                                </>
                            ) : (
                                <>
                                    {
                                        // content ALIASes may not match URL aliases, due to client changes
                                        contentPublished!==false
                                        && contentPublished.includes("login")
                                            ? <li>
                                                    <Link className="pill-shape" to="/login">
                                                        { t('Login') }
                                                        <FontAwesomeIcon icon={ solid('right-to-bracket') } />
                                                    </Link>
                                                </li>
                                            : null
                                    }
                                </>
                            )
                        }
    
                    </ul>
                    
                </nav>

            </div>
        </header>
    );

}
export default Header;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import moment from 'moment';

import FormStatuses from "../constants/FormStatuses"; 
import AgenciesData from "../constants/AgenciesData";
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

import { ReactComponent as Map } from '../images/Map.svg';

// CONTENT
import StaticContent from "./elements/StaticContent";

function AgencyOverview( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Overview') );

    // INTERVAL amount for re-fetching SITREP statuses (in milliseconds)
    const intervalAmount = 1 * 60 * 1000;

    // is API access available?
    const [isApi, setIsApi] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(false);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;
    
    // REDIRECT browser out of autheticated area if user not LOGGED IN

    const history = useHistory();

    useEffect(() => {
        if ( !isLoggedIn || !activeUser || !activeUser.hasOwnProperty('jwt') ) {
            history.push("/login");
        }
    }, [ isLoggedIn, activeUser ]);
    
    const [wildfireData, setWildfireData] = useState();
    const [sitrepStateData, setSitrepStateData] = useState();

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {

            const urlWildfires = ApiEndpoints.dipWildfireStats();
            
            axios
                .get(
                    urlWildfires,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setWildfireData(data);
                })
                .catch((error) => {
                    console.log(error);
                });

            const urlSitreps = ApiEndpoints.dipSitrepStates();
                
            axios
                .get(
                    urlSitreps,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    setSitrepStateData(data);

                    // flag API as accessible
                    setIsApi(true);

                    // set API last update
                    setLastUpdated( moment() );
                })
                .catch((error) => {
                    console.log(error);

                    // flag API as inaccessible, show WARNING
                    setIsApi(false);

                    // set API last update
                    setLastUpdated( moment() );
                });

        }

    }, [ isLoggedIn ]);

    useEffect(() => {
        let interval = setInterval(() => {
            
            if ( isLoggedIn && isApi ) {

                const urlSitreps = ApiEndpoints.dipSitrepStates();
                
                axios
                    .get(
                        urlSitreps,
                        {
                            headers: {
                                Authorization: "Bearer " + activeUser.jwt
                            }
                        }
                    )
                    .then(({ data }) => {
                        setSitrepStateData(data);

                        // flag API as accessible
                        setIsApi(true);

                        // set API last update
                        setLastUpdated( moment() );
                    })
                    .catch((error) => {
                        console.log(error);

                        // flag API as inaccessible, show WARNING
                        setIsApi(false);

                        // set API last update
                        setLastUpdated( moment() );
                    });
            }

        }, intervalAmount );

        return () => {
            clearInterval(interval);
        };
    }, [ isLoggedIn, isApi ]);


    const [tableFireStats, setTableFireStats] = useState();

    useEffect(() => {
        if ( sitrepStateData ) {

            Object.keys(sitrepStateData).map(function(dataKey, index) {
                const province_id = 'CA-' + sitrepStateData[dataKey].agency.toUpperCase();
                const province_state = sitrepStateData[dataKey].status.trim().toUpperCase();
                const province_element = document.getElementById( province_id );
                const province_url = '/agency/sitrep/' + sitrepStateData[dataKey].agency.toLowerCase();;

                if ( province_element ) {
                    // update BACKGROUND with current color
                    if ( province_state && FormStatuses[province_state] ) {
                        province_element.classList = FormStatuses[province_state].className;
                    } else {
                        province_element.classList = FormStatuses['N'].className;
                    }

                    // IF allowed to click/access an agency sitrep input
                    if (
                        !sitrepStateData[dataKey].edited_by
                        || (
                            sitrepStateData[dataKey].edited_by
                            && sitrepStateData[dataKey].edited_by === activeUser.user.id
                        )
                        || (
                            sitrepStateData[dataKey].edited_by
                            && sitrepStateData[dataKey].edited_by !== activeUser.user.id
                            && province_state 
                            && province_state!=='E'
                        )
                    ) {
                        // attach CLASS for hover state
                        province_element.classList.add('approval-map-can-hover');

                        // attach CLICK event
                        province_element.addEventListener('click', () => {
                            history.push(province_url);
                        });
                    }
                }
                
                else {
                    console.log("Can't find #ID for "+province_id );
                }

                return true
            });
        } else {
            setTableFireStats(null);
        }
    }, [ sitrepStateData ]);

    useEffect(() => {
        if ( wildfireData ) {
            setTableFireStats(
                <>
                    <tbody>
                        { 
                            Object.keys(wildfireData).map(function(dataKey, index) {

                                // AGENCY ...and translate, if available
                                let agency_display_name = t(AgenciesData[ wildfireData[dataKey].agency ].name) ? t(AgenciesData[ wildfireData[dataKey].agency ].name) : AgenciesData[ wildfireData[dataKey].agency ].name;
                                
                                
                                return <tr key={ index }>
                                    <td>
                                        <a href={ AgenciesData[ wildfireData[dataKey].agency ].website } target="_blank" rel="noopener noreferrer">
                                            { agency_display_name }
                                        </a>
                                    </td>
                                    
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData[dataKey].ytd_fires } /></td>
                                    <td className="right"><CiffcNumberFormat type="int" number={ wildfireData[dataKey].fires_today } /></td>
                                </tr>
                            })
                        }
                    </tbody>
                </>
            );
        } else {
            setTableFireStats(null);
        }
    }, [ wildfireData, i18n.language ]);

    return(
        <section className="dashboard agency">

            <div className="container">
                <StaticContent staticContentAlias="agency-overview" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }
            
                <div className="dashboard-row">
                    <div className="dashboard-column wide">
                        <div className="dashboard-card">
                            <div id="approval-map-wrapper">
                                <Map />
                            
                                <ul id="approval-map-legend">
                                    <li><span className="swatch"></span>{ t(FormStatuses['N'].title) }</li>
                                    <li><span className="swatch editing"></span>{ t(FormStatuses['E'].title) }</li>
                                    <li><span className="swatch submitted"></span>{ t(FormStatuses['S'].title) }</li>
                                    <li><span className="swatch rejected"></span>{ t(FormStatuses['R'].title) }</li>
                                    <li><span className="swatch approved"></span>{ t(FormStatuses['A'].title) }</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    
                    <div className="dashboard-column">
                        <div className="dashboard-card">
                            <table className="approval-map-table">
                                <thead>
                                    <tr>
                                        <th>{ t('Fires') }</th>

                                        <th className="right">{ t('YTD') }</th>
                                        <th className="right">{ t('Today') }</th>
                                    </tr>
                                </thead>
                                { tableFireStats }
                            </table>
                        </div>
                    </div>
                </div>
                
                <div className="dashboard-row">
                    <dl className="agency-status-summary">

                        <dt>{ t('Agency') }</dt>
                        <dd>{ activeUser.user !== undefined && activeUser.user.agency_code ? activeUser.user.agency_code.toUpperCase() : '-' }</dd>

                        <dt>{ t('Username') }</dt>
                        <dd>{ activeUser.user !== undefined ? activeUser.user.username : '-' }</dd>

                        {
                            /* 
                            // removed Oct 2023, Devops issue #225
                            <dt>{ t('Email') }</dt>
                            <dd>{ activeUser.user !== undefined ? activeUser.user.email : '-' }</dd>
                            */
                        }

                        <dt>{ t('Role') }</dt>
                        <dd>{ activeUser.user !== undefined & activeUser.role !== undefined ? activeUser.role.name : '-' }</dd>

                        <dt>{ t('Language') }</dt>
                        <dd>{ activeUser.user !== undefined ? activeUser.user.preferredLocale : '-' }</dd>

                        <dt>{ t('Last checked') }</dt>
                        <dd>{ lastUpdated ? lastUpdated.format('LT') : '-' }</dd>

                    </dl>
                </div>

            </div>
        </section>
    );

}

export default AgencyOverview;
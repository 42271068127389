export default 
{
	"default":[
		"Light",
		"Intermed",
		"Medium",
		"Heavy",
		"AT-802",
		"CV-580",
		"L-188",
		"Q-400",
		"RJ-185",
		"Scan Ship",
		"Scanning Aircraft",
		"AT-802 Amph",
		"CL-215",
		"CL-215T",
		"CL-415",
		"Birddog",
		"Overhead (General)",
		"DIVS",
		"FSC1",
		"FBAN",
		"ICT1",
		"IOF1",
		"LSC1",
		"OSC1",
		"PSC1",
		"SOF1",
		"STLD/TFLD",
		"FF1 (SA)",
		"FF2",
		"FF1 (IA)",
		"IMT1",
		"IMT2",
		"Sprinkler Units",
		"Pumps",
		"1.5\" hose",
		"Sprinkler Heads"
	],
	"expanded": [
		"Light",
		"Intermed",
		"Medium",
		"Heavy",
		"Q-400",
		"Scan Ship",
		"AT-802",
		"CV-580",
		"L-188",
		"RJ-185",
		"AT-802 Amph",
		"CL-215",
		"CL-215T",
		"CL-415",
		"Birddog",
		"AAON",
		"AOBD",
		"ASGS",
		"ATGS",
		"FWBM",
		"HEBM",
		"HLCO",
		"HENG",
		"HESM",
		"HTMM",
		"LOAD",
		"MXMS",
		"PLDO",
		"AREP",
		"ACDR",
		"CREP",
		"ICT1",
		"ICT2",
		"ICT3",
		"IOF1",
		"IOF2",
		"INLO",
		"LOFR",
		"SOF1",
		"SOF2",
		"SREP",
		"CLMS",
		"CMSY",
		"COMP",
		"COST",
		"EQTR",
		"FSC1",
		"FSC2",
		"PTRC",
		"PROC",
		"TIME",
		"DOZB",
		"ENGB",
		"ENOP",
		"HEBD",
		"HEGS",
		"HEOP",
		"ACLC",
		"BCMG",
		"COMT",
		"COML",
		"DISP",
		"FACL",
		"FCMG",
		"FDUL",
		"GSUL",
		"LSC1",
		"LSC2",
		"MEDL",
		"RADO",
		"RCDM",
		"SVBD",
		"SMEC",
		"SPUC",
		"SPUL",
		"SUBD",
		"DIVS",
		"FALL",
		"FOBS",
		"FIRB",
		"IGGS",
		"LSCT",
		"OPBD",
		"OSC1",
		"OSC2",
		"PBSP",
		"SCLD",
		"SMKJ",
		"STAM",
		"STLD",
		"STPS",
		"TFLD",
		"IGIS",
		"DMOB",
		"DOCL",
		"FBAN",
		"FINV",
		"GISS",
		"IMET",
		"IRIN",
		"IROP",
		"LTAN",
		"PSC1",
		"PSC2",
		"RESC",
		"RESL",
		"SITL",
		"SCKN",
		"CLRK",
		"THSP",
		"IMT1",
		"IMT2",
		"FF1 (IA)",
		"FF1 (EA)",
		"FF2",
		"FF3",
		"Sprinkler Units",
		"Pumps",
		"1.5\" hose",
		"Sprinkler Heads",
	],
	"short": [
		"Light",
		"Intermed",
		"Medium",
		"Heavy",
		"AT-802",
		"CV-580",
		"L-188",
		"Q-400",
		"RJ-185",
		"Scan Ship",
		"Scanning Aircraft",
		"AT-802 Amph",
		"CL-215",
		"CL-215T",
		"CL-415",
		"Birddog",
		"Overhead",
		"FF1 (IA)",
		"FF1 (EA)",
		"FF2",
		"FF3",
		"IMT1",
		"IMT2",
		"Pumps",
		"1.5\" hose",
		"Sprinkler Heads"
	],
};

// import React from "react";

// import { useTranslation } from 'react-i18next';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

function Footer() {

    // loading TRANSLATION funcitons
    // const { t } = useTranslation();

    return null;

    /*
    
    // Oct 2023, devops issue 240, FOOTER deprecated at client request

    return (
        <footer>
            <div className="container">
                <p>
                    { new Date().getFullYear() } &copy; <a href="https://ciffc.ca" title="Canadian Interagency Forest Fire Centre"> Canadian Interagency Forest Fire Centre</a>
                </p>

                <ul className="contacts">
                    <li>
                        <a href="https://ciffc.ca/about/contact" title={ t('Contact') } target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={ solid('envelope') } />
                        </a>
                    </li> 
                    <li>
                        <a href="https://ciffc.ca/rss.xml" title="RSS" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={ solid('rss') } />
                        </a>
                    </li> 
                    <li>
                        <a href="https://twitter.com/CIFFC" title="Twitter" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={ brands('twitter') } />
                        </a>
                    </li> 
                </ul>
            </div>
        </footer>
    );
    */

}
export default Footer;
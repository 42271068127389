import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

import { useTranslation } from 'react-i18next';

function ContentPage( props ){

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    const { pageAlias } = useParams();

    const { isLoggedIn, activeUser } = props;

    // vars and funcs, PUBLIC PAGES from backend

    const [contentPageTitle, setContentPageTitle] = useState();
    const [contentPageBody, setContentPageBody] = useState();

    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {

          let url = ApiEndpoints.pagesAgencyContent( pageAlias );

          axios
            .get( 
                url,
                {
                    headers: {
                        Authorization: "Bearer " + activeUser.jwt
                    }
                },
                
            )
            .then(({ data }) => {
              
              var usePageTitle = data.data[0].attributes.Name;
              var usePageContent = data.data[0].attributes.Body;

              data.data[0].attributes.localizations.data.forEach(element => {
                  // if TRANSLATION provided for LANG currently in, use that NAME instead...
                  if ( element.attributes.locale === i18n.language ) {
                    usePageTitle = element.attributes.Name;
                    usePageContent = element.attributes.Body;
                  }
              });

              setContentPageTitle( usePageTitle );
              setContentPageBody( usePageContent );

            })
            .catch((error) => {
              setContentPageTitle( t('Error') );
              setContentPageBody( t('Page not found.') );
            })

        }
        
        // if PROP isLoggedIn NOT passed in, we'll assume this is a normal public-page
        else {

          let url = ApiEndpoints.pagesPublicContent( pageAlias );

          axios
            .get( url )
            .then(({ data }) => {

              var usePageTitle = data.data[0].attributes.Name;
              var usePageContent = data.data[0].attributes.Body;

              data.data[0].attributes.localizations.data.forEach(element => {
                  // if TRANSLATION provided for LANG currently in, use that NAME instead...
                  if ( element.attributes.locale === i18n.language ) {
                    usePageTitle = element.attributes.Name;
                    usePageContent = element.attributes.Body;
                  }
              });

              setContentPageTitle( usePageTitle );
              setContentPageBody( usePageContent );

            })
            .catch((error) => {
              setContentPageTitle( t('Error') );
              setContentPageBody( t('Page not found.') );
            })

        }

      }, [ pageAlias, i18n.language ]);

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( contentPageTitle );

    return(
      
        <section className="contentpage">
            <div className="container">
                <h2>{ contentPageTitle }</h2>
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{ contentPageBody }</ReactMarkdown>
            </div>
        </section>

    )
}

export default ContentPage;
import React, { useEffect, useState } from "react";

import AgenciesData from "../../constants/AgenciesData";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableFiresByCause( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    useEffect(() => {

        if ( props.data ) {

            const data = props.data;
            
            let tableTotals = {
                agency: t('Total'), 
                            
                cause_natural: 0,
                cause_human: 0,
                cause_undetermined: 0, 
                    
                total: 0
            };

            Object.keys(data).map(function(dataKey) {
                tableTotals.cause_natural = tableTotals.cause_natural + parseInt(data[dataKey].cause_natural);
                tableTotals.cause_human = tableTotals.cause_human + parseInt(data[dataKey].cause_human);
                tableTotals.cause_undetermined = tableTotals.cause_undetermined + parseInt(data[dataKey].cause_undetermined);
                tableTotals.total = tableTotals.total + parseInt(data[dataKey].total);

                return true;
            });

            setTableDisplay(
                <>
                    <tbody>
                        { 
                            Object.keys(data).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }
                                
                                return <tr key={ index }>
                                    <td>{ agency_display_name }</td>

                                    <td className="w15 right"><CiffcNumberFormat type="int" number={ data[dataKey].cause_natural } /></td>
                                    <td className="w15 right"><CiffcNumberFormat type="int" number={ data[dataKey].cause_human } /></td>
                                    <td className="w15 right"><CiffcNumberFormat type="int" number={ data[dataKey].cause_undetermined } /></td>

                                    <td className="w15 right"><CiffcNumberFormat type="int" number={ data[dataKey].total } /></td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{ tableTotals.agency }</th>

                            <th className="w15 right"><CiffcNumberFormat type="int" number={ tableTotals.cause_natural } /></th>
                            <th className="w15 right"><CiffcNumberFormat type="int" number={ tableTotals.cause_human } /></th>
                            <th className="w15 right"><CiffcNumberFormat type="int" number={ tableTotals.cause_undetermined } /></th>

                            <th className="w15 right"><CiffcNumberFormat type="int" number={ tableTotals.total } /></th>
                        </tr>
                    </tfoot>
                </>
            );

        }

    }, [ props.agencies, props.data, t ]);


    const [tableDisplay, setTableDisplay] = useState(); 

    return(
        <TableWrapper>
            <thead>
                <tr>
                    <th>{ t('Agency') }</th>

                    <th className="right">{ t('Natural') }</th>
                    <th className="right">{ t('Human') }</th>
                    <th className="right">{ t('Undetermined') }</th>

                    <th className="right">{ t('Total') }</th>
                </tr>
            </thead>
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableFiresByCause };
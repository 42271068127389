import React from "react";

import i18next from '../i18n';

import { TableWrapper } from "../components/tables/TableWrapper"; // used for consistency and layouts

const PreparationLevels = {

    // CIFFC National Preparation Levels, as JSON

    NPL_LVL_DESC: ( level = '1' ) => {
        
        switch ( String(level) ) {
            
            case '1':
                return <>
                    <p>{ i18next.t('Wildland fire activity is minimal, and most jurisdictions experience light/moderate wildland fire danger.') }</p>
                    <p>{ i18next.t('The demand for firefighters and equipment from other jurisdictions is light.') }</p>
                </>;
            
            case '2':
                return <>
                    <p>{ i18next.t('Wildland fire activity is increasing within one or more jurisdictions.') }</p>
                    <p>{ i18next.t('The demand for mobilization of firefighters and equipment from other jurisdictions is light.') }</p>
                </>;
            
            case '3':
                return <>
                    <p>{ i18next.t('Wildland fire activity is increasing within one or more jurisdictions.') }</p>
                    <p>{ i18next.t('The demand for mobilization of firefighters and equipment from other jurisdictions is moderate.') }</p>
                </>;
            
            case '4':
                return <>
                    <p>{ i18next.t('Wildland fire activity is significant within one or more jurisdictions.') }</p>
                    <p>{ i18next.t('The demand for mobilization of firefighters and equipment from other jurisdictions is high.') }</p>
                </>;
            
            case '5':
                return <>
                    <p>{ i18next.t('Wildland fire activity is significant within one or more jurisdictions.') }</p>
                    <p>{ i18next.t('Firefighters and equipment in every jurisdiction in Canada is put to use, and international help has been requested.') }</p>
                </>;

            default:
                return '';

        }

    },

    // CIFFC Agency Preparation Levels, as JSON, adapted from sitrep/controllers/constants.inc
    
    APL_LVL_DESC: ( level = '1' ) => {
        
        switch ( level.toString() ) {

            // previous DRUPAL constant key APL_LVL1_DESC
            case '1':
                return <>
                    <p>{ i18next.t('Agencies accomplish incident management objectives utilizing local resources with little or no CIFFC support. There is little risk of drawing down capability in the agencies to support incident operations.') }</p>
                    <ul>
                        <li>{ i18next.t('Conditions are not favorable to support significant wildland fire activity in most agencies.') }</li>
                        <li>{ i18next.t('Resource capability is adequate with little or no mobilization of resources occurring through CIFFC.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is expected to remain minimal.') }</li>
                    </ul>
                </>;

            // previous DRUPAL constant key APL_LVL2_DESC
            case '2':
                return <>
                    <p>{ i18next.t('Active agencies are unable to independently accomplish incident management objectives without some support. Resource capability remains stable enough nationally to sustain incident operations and meet objectives in active agencies.  There is a low probability that drawing down resources from non-active agencies may pose a risk should existing conditions change.') }</p>
                    <ul>
                        <li>{ i18next.t('Wildland fire activity is increasing in one or more agencies.') }</li>
                        <li>{ i18next.t('Resources within most agencies are adequate to manage their current situation, with light to moderate demand and mobilization of resources occurring through CIFFC.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is normal to below normal for the time of year.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key APL_LVL3_DESC
            case '3':
                return <>
                    <p>{ i18next.t('Increased mobilization of resources through CIFFC is required to support operations in the active agencies. Agency priorities may need to be considered as a necessary measure to address the demand for shared resources among multiple active agencies.  There is a moderate probability that drawing down resources from non-active agencies may pose a risk should existing conditions change.') }</p>
                    <ul>
                        <li>{ i18next.t('Significant wildland fire activity is occurring in one or more agencies, with incident management (IMTs) actively engaged.') }</li>
                        <li>{ i18next.t('Demand and mobilization of interagency resources through CIFFC is moderate to high.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is normal for time of year.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key APL_LVL4_DESC
            case '4':
                return <>
                    <p>{ i18next.t('Significant mobilization of resources through CIFFC is required to assist operations in the active agencies. Agency priorities need to be considered as a necessary measure to address the demand for shared resources among multiple active agencies. There is a moderate to high probability that drawing down resources from non-active agencies may pose a risk should existing conditions change.') }</p>
                    <ul>
                        <li>{ i18next.t('Significant wildland fire activity is occurring in one or more agencies, with incident management (IMTs) actively engaged.') }</li>
                        <li>{ i18next.t('Demand and mobilization of interagency resources through CIFFC is high.') }</li>
                        <li>{ i18next.t('International availability of resources is being investigated.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is such that resource demands are expected to continue or increase.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key APL_LVL5_DESC
            case '5':
                return <>
                    <p>{ i18next.t('National mobilization is heavily committed and increased measures need to be taken to support agencies.  Active agencies may take emergency measures to sustain incident operations.  Inactive/low activity agencies are at drawdown levels.') }</p>
                    <ul>
                        <li>{ i18next.t('Full commitment of national resources is ongoing.') }</li>
                        <li>{ i18next.t('Demand for interagency resources through CIFFC is extreme.') }</li>
                        <li>{ i18next.t('National availability of resources is limited, international resources are being mobilized.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is high to extreme and expected to remain so in one or multiple agencies.') }</li>
                    </ul>
                </>

            default:
                return <></>;

        }

    },

    // US Preparation Levels, as JSON, adapted from sitrep/controllers/constants.inc

    US_APL_MISSING_DESC: () => 'No APL level set.',

    US_APL_UNAVAILABLE: () => 'US data unavailable at time of publishing.',

    US_APL_LVL_DESC: ( level = '1' ) => {
        
        switch ( level.toString() ) {

            // previous DRUPAL constant key US_APL_LVL1_DESC
            case '1':
                return <>
                    <p>{ i18next.t('Geographic Areas accomplish incident management objectives utilizing local resources with little or no national support.') }</p>
                    <ul>
                        <li>{ i18next.t('Conditions are not favorable to support significant wildland fire activity in most geographic areas.') }</li>
                        <li>{ i18next.t('Resource capability is adequate with little or no mobilization of resources occurring through the National Interagency Coordination Center.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is expected to remain minimal.') }</li>
                    </ul>
                </>;

            // previous DRUPAL constant key US_APL_LVL2_DESC
            case '2':
                return <>
                    <p>{ i18next.t('Active Geographic Areas (GA\'s) are unable to independently accomplish incident management objectives. Resource capability remains stable enough nationally to sustain incident operations and meet objectives in active GA\'s.') }</p>
                    <ul>
                        <li>{ i18next.t('Significant wildland fire activity is increasing in a few geographic areas.') }</li>
                        <li>{ i18next.t('Resources within most geographic areas are adequate to manage the current situation, with light to moderate mobilization of resources occurring through the National Interagency Coordination Center.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is normal to below normal for the time of year.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key US_APL_LVL3_DESC
            case '3':
                return <>
                    <p>{ i18next.t('Mobilization of resources nationally is required to sustain incident management operations in the active Geographic Areas (GA\'s). National priorities established as a necessary measure to address the heavy and persistent demand for shared resources among active GA\'s.') }</p>
                    <ul>
                        <li>{ i18next.t('Significant wildland fire activity is occurring in multiple geographic areas, with Incident Management Teams (IMTs) actively engaged.') }</li>
                        <li>{ i18next.t('Mobilization of resources through the National Interagency Coordination Center is moderate to heavy.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is normal for the time of year.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key US_APL_LVL4_DESC
            case '4':
                return <>
                    <p>{ i18next.t('Shared resources are heavily committed. National mobilization trends affect all Geographic Areas (GA\'s) and regularly occur over larger and larger distances. National priorities govern resources of all types. Heavy demand on inactive/low activity GA\'s with low levels of activity for available resources.') }</p>
                    <ul>
                        <li>{ i18next.t('Significant wildland fire activity is occurring in multiple geographic areas; significant commitment of Incident Management Teams.') }</li>
                        <li>{ i18next.t('NICC increasingly engages GACCs in an effort to coordinate and fill orders for available resources.') }</li>
                        <li>{ i18next.t('Potential for significant incidents emerging in multiple GA’s indicates that resource demands will continue or increase.') }</li>
                    </ul>
                </>

            // previous DRUPAL constant key US_APL_LVL5_DESC
            case '5':
                return <>
                    <p>{ i18next.t('National mobilization is heavily committed and measures need to be taken to support GA\'s. Active GA\'s must take emergency measures to sustain incident operations.') }</p>
                    <ul>
                        <li>{ i18next.t('Full commitment of national resources is ongoing.') }</li>
                        <li>{ i18next.t('Resource orders filled at NICC by specifically coordinating requests with GACCs as resources become available.') }</li>
                        <li>{ i18next.t('Potential for emerging significant wildland fires is high and expected to remain high in multiple geographic areas.') }</li>
                    </ul>
                </>

            default:
                return <>
                    <p>{ i18next.t('US data unavailable at time of publishing.') }</p>
                </>;

        }

    },

    // APL section levels

    APL_AGENCY_FIRE_DANGER_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_AGENCY_FIRE_DANGER_LVL_1 to APL_AGENCY_FIRE_DANGER_LVL_5
        switch ( level.toString() ) {
            case '1': return i18next.t('Low'); 
            case '2': return i18next.t('Moderate'); 
            case '3': return i18next.t('High'); 
            case '4': return i18next.t('Very High'); 
            case '5': return i18next.t('Extreme'); 
            default: return null;
        }

    },

    APL_CURRENT_FIRE_LOAD_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_CURRENT_FIRE_LOAD_LVL_1 to APL_CURRENT_FIRE_LOAD_LVL_5
        switch ( level.toString() ) {
            case '1': return i18next.t('Low'); 
            case '2': return i18next.t('Moderate'); 
            case '3': return i18next.t('High'); 
            case '4': return i18next.t('Very High'); 
            case '5': return i18next.t('Extreme'); 
            default: return null;
        }

    },

    APL_ANTICIPATED_FIRE_LOAD_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_ANTICIPATED_FIRE_LOAD_LVL_1 to APL_ANTICIPATED_FIRE_LOAD_LVL_5
        switch ( level.toString() ) {
            case '1': return i18next.t('Low'); 
            case '2': return i18next.t('Moderate'); 
            case '3': return i18next.t('High'); 
            case '4': return i18next.t('Very High'); 
            case '5': return i18next.t('Extreme'); 
            default: return null;
        }

    },

    APL_AGENCY_RESOURCE_LEVELS_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_AGENCY_RESOURCE_LEVELS_LVL_1 to APL_AGENCY_RESOURCE_LEVELS_LVL_5 (skipping 2)
        switch ( level.toString() ) {
            case '1': return i18next.t('Adequate'); 
            case '3': return i18next.t('Some Assistance'); 
            case '4': return i18next.t('Assistance Required'); 
            case '5': return i18next.t('Inadequate'); 
            default: return null;
        }

    },

    APL_CIFFC_REQUEST_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_CIFFC_REQUEST_LVL_1 to APL_CIFFC_REQUEST_LVL_5
        switch ( level.toString() ) {
            case '1': return i18next.t('Excellent'); 
            case '2': return i18next.t('Good'); 
            case '3': return i18next.t('Moderate'); 
            case '4': return i18next.t('Poor'); 
            case '5': return i18next.t('No Ability'); 
            default: return null;
        }

    },

    APL_INTERNATIONAL_POTENTIAL_LVL: ( level = '1' ) => {
        
        // previous DRUPAL constant keys APL_INTERNATIONAL_POTENTIAL_LVL_1 to APL_INTERNATIONAL_POTENTIAL_LVL_5 (skipping 2 and 3)
        switch ( level.toString() ) {
            case '1': return i18next.t('Nil'); 
            case '4': return i18next.t('Increasing'); 
            case '5': return i18next.t('Consideration'); 
            default: return null;
        }

    }

}

export default PreparationLevels;

function PreparationLevelsGuide(){
    return <TableWrapper>
        <thead>
            <tr>
                <th>&nbsp;</th>
                <th className="apl-lvl-1 center">{ i18next.t("Level 1") }</th>
                <th className="apl-lvl-2 center">{ i18next.t("Level 2") }</th>
                <th className="apl-lvl-3 center">{ i18next.t("Level 3") }</th>
                <th className="apl-lvl-4 center">{ i18next.t("Level 4") }</th>
                <th className="apl-lvl-5 center">{ i18next.t("Level 5") }</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{ i18next.t("Agency Fire Danger") }</td>
                <td className="apl-lvl-1-light center">{ i18next.t(PreparationLevels.APL_AGENCY_FIRE_DANGER_LVL(1)) }</td>
                <td className="apl-lvl-2-light center">{ i18next.t(PreparationLevels.APL_AGENCY_FIRE_DANGER_LVL(2)) }</td>
                <td className="apl-lvl-3-light center">{ i18next.t(PreparationLevels.APL_AGENCY_FIRE_DANGER_LVL(3)) }</td>
                <td className="apl-lvl-4-light center">{ i18next.t(PreparationLevels.APL_AGENCY_FIRE_DANGER_LVL(4)) }</td>
                <td className="apl-lvl-5-light center">{ i18next.t(PreparationLevels.APL_AGENCY_FIRE_DANGER_LVL(5)) }</td>
            </tr>

            <tr>
                <td>{ i18next.t("Current Fire Load") }</td>
                <td className="apl-lvl-1-light center">{ i18next.t(PreparationLevels.APL_CURRENT_FIRE_LOAD_LVL(1)) }</td>
                <td className="apl-lvl-2-light center">{ i18next.t(PreparationLevels.APL_CURRENT_FIRE_LOAD_LVL(2)) }</td>
                <td className="apl-lvl-3-light center">{ i18next.t(PreparationLevels.APL_CURRENT_FIRE_LOAD_LVL(3)) }</td>
                <td className="apl-lvl-4-light center">{ i18next.t(PreparationLevels.APL_CURRENT_FIRE_LOAD_LVL(4)) }</td>
                <td className="apl-lvl-5-light center">{ i18next.t(PreparationLevels.APL_CURRENT_FIRE_LOAD_LVL(5)) }</td>
            </tr>

            <tr>
                <td>{ i18next.t("Anticipated Fire Load (7 Days)") }</td>
                <td className="apl-lvl-1-light center">{ i18next.t(PreparationLevels.APL_ANTICIPATED_FIRE_LOAD_LVL(1)) }</td>
                <td className="apl-lvl-2-light center">{ i18next.t(PreparationLevels.APL_ANTICIPATED_FIRE_LOAD_LVL(2)) }</td>
                <td className="apl-lvl-3-light center">{ i18next.t(PreparationLevels.APL_ANTICIPATED_FIRE_LOAD_LVL(3)) }</td>
                <td className="apl-lvl-4-light center">{ i18next.t(PreparationLevels.APL_ANTICIPATED_FIRE_LOAD_LVL(4)) }</td>
                <td className="apl-lvl-5-light center">{ i18next.t(PreparationLevels.APL_ANTICIPATED_FIRE_LOAD_LVL(5)) }</td>
            </tr>

            <tr>
                <td>{ i18next.t("Agency Resource Levels") }</td>
                <td className="apl-lvl-1-light center">{ i18next.t(PreparationLevels.APL_AGENCY_RESOURCE_LEVELS_LVL(1)) }</td>
                <td className="apl-lvl-2-light center">{ i18next.t(PreparationLevels.APL_AGENCY_RESOURCE_LEVELS_LVL(1)) }</td>
                <td className="apl-lvl-3-light center">{ i18next.t(PreparationLevels.APL_AGENCY_RESOURCE_LEVELS_LVL(3)) }</td>
                <td className="apl-lvl-4-light center">{ i18next.t(PreparationLevels.APL_AGENCY_RESOURCE_LEVELS_LVL(4)) }</td>
                <td className="apl-lvl-5-light center">{ i18next.t(PreparationLevels.APL_AGENCY_RESOURCE_LEVELS_LVL(5)) }</td>
            </tr>

            <tr>
                <td>{ i18next.t("Agency's Ability to Respond to CIFFC Resource Requests") }</td>
                <td className="apl-lvl-1-light center">{ i18next.t(PreparationLevels.APL_CIFFC_REQUEST_LVL(1)) }</td>
                <td className="apl-lvl-2-light center">{ i18next.t(PreparationLevels.APL_CIFFC_REQUEST_LVL(2)) }</td>
                <td className="apl-lvl-3-light center">{ i18next.t(PreparationLevels.APL_CIFFC_REQUEST_LVL(3)) }</td>
                <td className="apl-lvl-4-light center">{ i18next.t(PreparationLevels.APL_CIFFC_REQUEST_LVL(4)) }</td>
                <td className="apl-lvl-5-light center">{ i18next.t(PreparationLevels.APL_CIFFC_REQUEST_LVL(5)) }</td>
            </tr>
        </tbody>
    </TableWrapper>;
}

export { PreparationLevelsGuide };
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";

import ApiEndpoints from "../../constants/ApiEndpoints";
import useDocumentTitle from '../../pages/elements/useDocumentTitle';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "../../pages/elements/StaticContent";

function LogIn(props) {

  // loading TRANSLATION funcitons
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => { i18n.changeLanguage(lng); };

  // set HTML PAGE TITLE and trigger any ANALYTICS...
  useDocumentTitle( t('Login') );
  
  // used to REDIRECT on successful login...
  const history = useHistory();
  
  // handling FORM inputs
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  // handling form FEEDBACK
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  
  const { setActiveUser, setLoggedIn } = props;
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    const urlLogin = ApiEndpoints.userLogin();
    const urlProfile = ApiEndpoints.userProfile();

    await axios
      .post(
        urlLogin,
        {
          identifier: username,
          password: password
        }
      )
      .then(resLogin => {

        if ( resLogin.data.hasOwnProperty('error') ) {
          setErrorMessage( resLogin.data.error.message );
        } else {

          axios
            .get(
              urlProfile,
              {
                headers: {
                  Authorization: 'Bearer ' + resLogin.data.jwt
                }
              }
            ).then(resProfile => {
                
                // define USER object and populate with collected data...
                let activeUserObject = {
                  jwt: resLogin.data.jwt,
                  user: resProfile.data,
                  role: resProfile.data.role
                }

                // set the logged in user to app STATE
                setActiveUser( activeUserObject );

                // set the LANG to whatever the logged-in user PREFERRED
                changeLanguage( resProfile.data.preferredLocale );
                
                // sets FLAG for various checks throughout app
                setLoggedIn(true);

                // ...and then NAVIGATE to agency landing page
                history.push("/agency");
                
            });
            
        }

      })
      .catch(error => {

          // console.log(error);

          if ( 
            error.hasOwnProperty('response')
            && error.response.hasOwnProperty('data')
            && error.response.data.hasOwnProperty('error')
          ) {
            setErrorMessage( error.response.data.error.message );
          } else if ( error.hasOwnProperty('message') ) {
            setErrorMessage( error.message );
          }else{
            setErrorMessage( "Error attempting to authenticate. Please try again." );
          }
          
      })
      .finally(() => {
        setFormSubmitting(false);
      });

  };
  
  return (
    <section className="contentpage">
      <div className="container">

      { /* t('Login') */ }
      <StaticContent staticContentAlias="login" />
        
        <form action="" onSubmit={(e) => handleSubmit(e)} className={ "login-form " + (formSubmitting ? "form-submitting" : "") }>
          
          <div className="input">
            <label>{ t('Username') }</label>
            <input
              type="text"
              required="required"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="input">
            <label>{ t('Password') }</label>
            <input
              type="password"
              required="required"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          { errorMessage  && <div className={"message error"}><p>{errorMessage}</p></div> }
          
          <div className="input submit">
            <button className="button" type="submit">
              <FontAwesomeIcon icon={ solid('right-to-bracket') } />
              { t('Login') }
            </button>
          </div>
          
        </form>

      </div>
    </section>
  );

}

export default LogIn;
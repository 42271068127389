import React, { useEffect, useState } from "react";
import useLocalStorage from "../components/hooks/UseLocalStorage";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from 'moment-timezone';

// import AgenciesData from "../constants/AgenciesData";
import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';
import PreparationLevels, { PreparationLevelsGuide } from "../constants/PreparationLevels";

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// TABLES
import { TableWrapper } from "../components/tables/TableWrapper";  // used for consistency and layouts
import { TableMobilizationsToFrom } from "../components/tables/TableMobilizationsToFrom";
import { TableMobilizationsNational } from "../components/tables/TableMobilizationsNational";
import {
    TableFireStatisticsAgenciesSummaries,

    TableFireStatisticsAgenciesYesterday,
    TableFireStatisticsAgenciesFull,
    TableFireStatisticsAgenciesModified,
    TableFireStatisticsAgenciesMonitored,
    TableFireStatisticsAgenciesGrand,
    TableFireStatisticsAgenciesPriority,
    TableFireStatisticsAgenciesPrescribed,
    TableFireStatisticsAgenciesPredictions,
    TableFireStatisticsAgenciesPreparednessLevels,

    TableFireStatisticsAgenciesResourceRequirements,
    TableFireStatisticsAgenciesHelicopters,
    TableFireStatisticsMobilizationsCompact,
    TableFireStatisticsDemobilizationsCompact
} from "../components/tables/TableFireStatistics";
import { 
    TableHistoricalYtdFires, 
    TableHistoricalYtdArea, 
    TableHistoricalAverageFires, 
    TableHistoricalAverageArea
} from "../components/tables/TableHistorical";

function NationalSitRep( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;

    // get pass in ROUTER params, if any
    let { routerParamDate } = useParams();

    // configure caching TITLE based on DATE from router, if any...
    let cacheTitleDate = 'latest';
    if ( routerParamDate ) { cacheTitleDate = moment( routerParamDate.toString() ).format('YYYY-MM-DD'); }

    // const [sitrepData, setSitrepData] = useState(); // replaced with LOCAL STORAGE CACHE
    const [sitrepData, setSitrepData] = useLocalStorage("node-sitrep-public-data-"+cacheTitleDate, false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));
    // const [expandedSitrepData, setExpandedSitrepData] = useState(false); // replaced with LOCAL STORAGE CACHE
    const [expandedSitrepData, setExpandedSitrepData] = useLocalStorage("node-sitrep-expanded-data-"+cacheTitleDate, false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    // const [resourceOptions, setResourceOptions] = useState(null); // replaced with LOCAL STORAGE CACHE
    const [resourceOptions, setResourceOptions] = useLocalStorage("node-sitrep-resource-options", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    useEffect(() => {

        // get RESOURCE OPTION list, for sorting/lookup, etc
        if ( !resourceOptions ) {

            const urlResourceOptions = ApiEndpoints.dipResourceOptions();

            axios
                .get( urlResourceOptions )
                .then(({ data }) => {
                    setResourceOptions(data);
                })
                .catch((error) => {
                    console.log(error);
                });

        }
        
        let url = ApiEndpoints.dipSitrepNational();

        // if passing in DATE via router, fetch that date's SITREP, else fetch the LATEST

        if ( routerParamDate ) {
            let urlParamSiterepDate = moment( routerParamDate.toString() ).format('YYYY-MM-DD');
            url = url + '?date=' + urlParamSiterepDate;
        }

        // if PROP isLoggedIn passed in, pass that uathorization  to (potentially) get extra data to display
        if ( isLoggedIn ) {
            
            if ( !sitrepData ) {
                axios
                    .get(
                        url,
                        {
                            headers: {
                                Authorization: "Bearer " + activeUser.jwt
                            }
                        }
                    )
                    .then(({ data }) => {
                        setSitrepData(data);

                        if (
                            data.hasOwnProperty('expanded_data')
                            && data.expanded_data
                        ) {
                            setExpandedSitrepData(true);
                        }

                        // flag API as accessible
                        setIsApi(true);
                        
                    })
                    .catch((error) => {
                        console.log(error);

                        // flag API as inaccessible, show WARNING
                        setIsApi(false);
                    });
            } else {
                // if reading CACHE, then assume true
                setIsApi(true);
            }

        }
        
        // else, fetch as NORMAL
        else {

            if ( !sitrepData ) {
                axios
                    .get( url )
                    .then(({ data }) => {
                        setSitrepData(data);

                        // flag API as accessible
                        setIsApi(true);
                    })
                    .catch((error) => {
                        console.log(error);

                        // flag API as inaccessible, show WARNING
                        setIsApi(false);
                    });
            } else {
                // if reading CACHE, then assume true
                setIsApi(true);
            }

        }

    }, [i18n.language]);

    const [sitrepNationalIntro, setSitrepNationalIntro] = useState();
    const [sitrepNationalSummary, setSitrepNationalSummary] = useState(<TableWrapper></TableWrapper>);
    const [sitrepUSFireActivity, setSitrepUSFireActivity] = useState();

    const [sitrepAgencySummaries, setSitrepAgencySummaries] = useState();
    
    const [sitrepAgenciesYesterday, setSitrepAgenciesYesterday] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesFull, setSitrepAgenciesFull] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesModified, setSitrepAgenciesModified] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesMonitored, setSitrepAgenciesMonitored] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesGrand, setSitrepAgenciesGrand] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPrescribed, setSitrepAgenciesPrescribed] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPriority, setSitrepAgenciesPriority] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPredictions, setSitrepAgenciesPredictions] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesPreparednessLevels, setSitrepAgenciesPreparednessLevels] = useState(<TableWrapper></TableWrapper>);

    const [sitrepAgenciesResourceRequirements, setSitrepAgenciesResourceRequirements] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAgenciesHelicopters, setSitrepAgenciesHelicopters] = useState(<TableWrapper></TableWrapper>);
    const [sitrepMobilizationsCompact, setSitrepMobilizationsCompact] = useState(<TableWrapper></TableWrapper>);
    const [sitrepDemobilizationsCompact, setSitrepDemobilizationsCompact] = useState(<TableWrapper></TableWrapper>);

    const [sitrepMobilizationsToday, setSitrepMobilizationsToday] = useState(<TableWrapper></TableWrapper>);
    const [sitrepDemobilizationsToday, setSitrepDemobilizationsToday] = useState(<TableWrapper></TableWrapper>);

    const [sitrepMobilizationsNationalTo, setSitrepMobilizationsNationalTo] = useState(<TableWrapper></TableWrapper>);
    const [sitrepMobilizationsNationalFrom, setSitrepMobilizationsNationalFrom] = useState(<TableWrapper></TableWrapper>);

    const [sitrepNationalAvailability, setSitrepNationalAvailability] = useState(<TableWrapper></TableWrapper>);
    
    const [sitrepAgenciesResourceRequests, setSitrepAgenciesResourceRequests] = useState(<TableWrapper></TableWrapper>);

    const [sitrepYtdFires, setSitrepYtdFires] = useState(<TableWrapper></TableWrapper>);
    const [sitrepYtdArea, setSitrepYtdArea] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAverageFires, setSitrepAverageFires] = useState(<TableWrapper></TableWrapper>);
    const [sitrepAverageArea, setSitrepAverageArea] = useState(<TableWrapper></TableWrapper>);

    useEffect(() => {

        if (sitrepData && resourceOptions ) {

            setSitrepNationalIntro(
                <>
                    <h3>{ t('For') }: { moment( sitrepData.field_date ).tz( moment.tz.guess() ).format('LL') }</h3>
                </>
            );

            let displayComments = i18n.language === 'fr' ? sitrepData.field_fr_summary_comments: sitrepData.field_en_summary_comments;
            let urlNationalFireSituationMap = i18n.language === 'fr' ? 'https://cwfis.cfs.nrcan.gc.ca/carte-interactive' : 'http://cwfis.cfs.nrcan.gc.ca/interactive-map';

            setSitrepNationalSummary(
                <>
                    { displayComments ? <p>{displayComments}</p> : null }

                    <h4>{ t('National Preparedness Level') }: <span className={ 'apl-lvl-' + sitrepData.field_preparedness_level }>{ sitrepData.field_preparedness_level }</span></h4>

                        { PreparationLevels.APL_LVL_DESC( sitrepData.field_preparedness_level ) }

                        <p><i>{ t("Data provided based on information collected from agencies. Accuracy varies based on individual agency practices, but is typically considered to be rough estimates for daily operations. Final quality control is performed post-season and compiled in other publications.") }</i></p>

                        <p><b>{ t('National Fire Situation Maps') }:</b> <a target="_blank" rel="noopener noreferrer" href={ urlNationalFireSituationMap }>{ t('Canadian Wildland Fire Information System') }</a></p>
                </>
            );
            
            if ( sitrepData.recent_mobilizations && Array.isArray(sitrepData.recent_mobilizations) ) {
                setSitrepMobilizationsToday( <TableMobilizationsToFrom data={sitrepData.recent_mobilizations} /> );
            }
            
            if ( sitrepData.recent_demobilizations && Array.isArray(sitrepData.recent_demobilizations) ) {
                setSitrepDemobilizationsToday( <TableMobilizationsToFrom data={sitrepData.recent_demobilizations} /> );
            }

            if ( sitrepData.agencies && Array.isArray(sitrepData.agencies) ) {
                if ( sitrepData.national_mobilizations_to && Array.isArray(sitrepData.national_mobilizations_to) ) {
                    setSitrepMobilizationsNationalTo( <TableMobilizationsNational agencies={ sitrepData.agencies } resourceOptions={ resourceOptions } resources={ sitrepData.resources } data={ sitrepData.national_mobilizations_to } /> );
                }
                
                if ( sitrepData.national_mobilizations_from && Array.isArray(sitrepData.national_mobilizations_from) ) {
                    setSitrepMobilizationsNationalFrom( <TableMobilizationsNational agencies={ sitrepData.agencies } resourceOptions={ resourceOptions } resources={ sitrepData.resources } data={ sitrepData.national_mobilizations_from } /> );
                }

                if ( sitrepData.national_availability && Array.isArray(sitrepData.national_availability) ) {
                    setSitrepNationalAvailability( <TableMobilizationsNational agencies={ sitrepData.agencies } resourceOptions={ resourceOptions } resources={ sitrepData.resources } data={ sitrepData.national_availability } /> );
                }
            }

            setSitrepUSFireActivity(
                <>
                    <h4>{ t('Preparedness Level') }: <span className={ 'apl-lvl-' + sitrepData.field_us_preparedness_level }>{ sitrepData.field_us_preparedness_level }</span></h4>

                    { sitrepData.field_us_preparedness_level ? PreparationLevels.US_APL_LVL_DESC( sitrepData.field_us_preparedness_level ) : <p>{ PreparationLevels.US_APL_MISSING_DESC() }</p> }

                    <TableWrapper>
                        <thead>
                            <tr>
                                <th className="w25 center">{ t('Fires Yesterday') }</th>
                                <th className="w25 center">{ t('Acres Yesterday*') }</th>
                                <th className="w25 center" title={ t('Year-to-date Fires') }>{ t('YTD Fires') }</th>
                                <th className="w25 center" title={ t('Year-to-date Acres') }>{ t('YTD Acres*') }</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="center"><CiffcNumberFormat type="int" number={ sitrepData.field_us_fires } /></td>
                                <td className="center"><CiffcNumberFormat number={ sitrepData.field_us_acres } /></td>
                                <td className="center"><CiffcNumberFormat type="int" number={ sitrepData.field_us_ytd_fires } /></td>
                                <td className="center"><CiffcNumberFormat number={ sitrepData.field_us_ytd_acres } /></td>
                            </tr>
                        </tbody>
                    </TableWrapper>

                    <p><i>{ t('1 Acre = 0.404685642 Hectares') }</i></p>
                </>
            );

            //! AGENCIES, display data

            setSitrepAgencySummaries( <TableFireStatisticsAgenciesSummaries sitrep={sitrepData} /> );

            setSitrepAgenciesYesterday( <TableFireStatisticsAgenciesYesterday sitrep={sitrepData} agencyFullName={true} agencyLink={true} /> );
            setSitrepAgenciesFull( <TableFireStatisticsAgenciesFull sitrep={sitrepData} /> );
            setSitrepAgenciesModified( <TableFireStatisticsAgenciesModified sitrep={sitrepData} /> );
            setSitrepAgenciesMonitored( <TableFireStatisticsAgenciesMonitored sitrep={sitrepData} /> );
            setSitrepAgenciesGrand( <TableFireStatisticsAgenciesGrand sitrep={sitrepData} /> );
            setSitrepAgenciesPrescribed( <TableFireStatisticsAgenciesPrescribed sitrep={sitrepData} agencyFullName={true} /> );
            setSitrepAgenciesPriority( <TableFireStatisticsAgenciesPriority sitrep={sitrepData} expandedSitrepData={expandedSitrepData} /> );
            setSitrepAgenciesPredictions(<TableFireStatisticsAgenciesPredictions sitrep={sitrepData} agencyFullName={true} /> );
            setSitrepAgenciesPreparednessLevels( <TableFireStatisticsAgenciesPreparednessLevels sitrep={sitrepData} expandedSitrepData={expandedSitrepData} /> );
            
            setSitrepAgenciesResourceRequirements( <TableFireStatisticsAgenciesResourceRequirements sitrep={sitrepData} agencyFullName={true} /> );
            setSitrepAgenciesHelicopters( <TableFireStatisticsAgenciesHelicopters sitrep={sitrepData} agencyFullName={true} /> );
            setSitrepMobilizationsCompact( <TableFireStatisticsMobilizationsCompact sitrep={sitrepData} /> );
            setSitrepDemobilizationsCompact( <TableFireStatisticsDemobilizationsCompact sitrep={sitrepData} /> );

            setSitrepAgenciesResourceRequests(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('For') }</th>
                            <th>{ t('Resources') }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(sitrepData.resource_requirements).map(function(dataKey, index) {
                                
                                const displayResourceFor = sitrepData.resource_requirements[dataKey].field_for;
                                const displayResourceComments = i18n.language === 'fr' ? sitrepData.resource_requirements[dataKey].field_fr_resources : sitrepData.resource_requirements[dataKey].field_en_resources;

                                if ( displayResourceComments ) {
                                    return <tr key={index}>
                                        <td>{ displayResourceFor }</td>
                                        <td>{ displayResourceComments }</td>
                                    </tr>
                                } else {
                                    return  null;
                                }
                            })
                        }
                    </tbody>
                </TableWrapper>
            );

            setSitrepYtdFires(<TableHistoricalYtdFires historical={sitrepData.historical} historical_averages={sitrepData.historical_averages} year={moment( sitrepData.field_date ).format('YYYY')} />);
            setSitrepYtdArea(<TableHistoricalYtdArea historical={sitrepData.historical} historical_averages={sitrepData.historical_averages} year={moment( sitrepData.field_date ).format('YYYY')} />);
            setSitrepAverageFires(<TableHistoricalAverageFires historical={sitrepData.historical} historical_averages={sitrepData.historical_averages} year={moment( sitrepData.field_date ).format('YYYY')} />);
            setSitrepAverageArea(<TableHistoricalAverageArea historical={sitrepData.historical} historical_averages={sitrepData.historical_averages} year={moment( sitrepData.field_date ).format('YYYY')} />);
            
            setSitrepDocumentTitle( t('Situation Report') + ' ' + moment( sitrepData.field_date ).format('LL') );
        }

    }, [ sitrepData, resourceOptions, i18n.language ]);

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    const [sitrepDocumentTitle, setSitrepDocumentTitle] = useState(false);
    useDocumentTitle( sitrepDocumentTitle );
    
    return(
      
        <section className="contentpage">
            <div className="container">

                <h2>{ t('National Fire Situation Report') }</h2>

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                    { sitrepNationalIntro }
                
                <h3 className="sitrep-display-prefixed">{ t('National Summary') }</h3>

                    { sitrepNationalSummary }
                    { sitrepAgencySummaries }
                
                <h3 className="sitrep-display-prefixed">{ t('Fire Statistics') }</h3>
                    
                    <p>{ t('For current year from January 1.') }</p>

                    <h4>{ t('New Wildland Fires Yesterday') }</h4>

                        { sitrepAgenciesYesterday }

                    <h4>{ t('Full Response') }</h4>

                        { sitrepAgenciesFull }

                    <div className="table-sidebyside">
                        <div>
                            <h4>{ t('Modified Response') }</h4>
                            { sitrepAgenciesModified }
                        </div>
                        <div>
                            <h4>{ t('Monitored Response') }</h4>
                            { sitrepAgenciesMonitored }
                        </div>
                        <div>
                            <h4>{ t('Grand Totals') }</h4>
                            { sitrepAgenciesGrand }
                        </div>
                    </div>

                    <h4>{ t('Prescribed Fires') }</h4>
                    
                        { sitrepAgenciesPrescribed }

                <h3 className="sitrep-display-prefixed">{ t('Priority Fires') }</h3>

                    { sitrepAgenciesPriority }

                {

                    expandedSitrepData
                    ? <>
                            <h3 className="sitrep-display-prefixed">{ t('Fire Occurrence Prediction for Tomorrow') }</h3>
                                <p>{ t('Forecasted Fire Weather Index for Tomorrow') }: <a href="http://cwfis.cfs.nrcan.gc.ca/maps/fw?type=fwi" target="_blank" rel="noopener noreferrer">{ t('Canadian Wildland Fire Information System') }</a></p>

                                { sitrepAgenciesPredictions }
                        </>
                    : null    

                }

                <h3 className="sitrep-display-prefixed">{ t('Preparedness Levels') }</h3>

                    { sitrepAgenciesPreparednessLevels }

                    <PreparationLevelsGuide />

                {
                    expandedSitrepData
                    ? <>
                            <h3 className="sitrep-display-prefixed">{ t('Possibility of Resource Requirements') }</h3>

                                <h4>{ t('Agency Resource Requirements') }</h4>

                                    { sitrepAgenciesResourceRequirements }

                                <h4>{ t('Outstanding Resource Requests') }</h4>

                                    { sitrepAgenciesResourceRequests }
                        </>
                    : null
                }

                {
                    expandedSitrepData
                    ? <>
                            <h3 className="sitrep-display-prefixed">{ t('Casual Hire Helicopters') }</h3>

                                { sitrepAgenciesHelicopters }
                        </>
                    : null
                }

                <h3 className="sitrep-display-prefixed">{ t('Mobilizations') }</h3>

                    <h4>{ t('Today\'s Mobilizations') }</h4>

                        { sitrepMobilizationsToday }

                    <h4>{ t('National Mobilizations: To') }</h4>

                        { sitrepMobilizationsNationalTo }

                    <h4>{ t('National Mobilizations: From') }</h4>

                        { sitrepMobilizationsNationalFrom } 

                    <h4>{ t('Compact Agreement Mobilizations') }</h4>

                        { sitrepMobilizationsCompact }

                <h3 className="sitrep-display-prefixed">{ t('Demobilizations') }</h3>

                    <h4>{ t('Today\'s Demobilizations') }</h4>

                        { sitrepDemobilizationsToday }
                    
                    <h4>{ t('Compact Agreement Demobilizations') }</h4>

                        { sitrepDemobilizationsCompact }

                {
                    expandedSitrepData
                    ? <>
                            <h3 className="sitrep-display-prefixed">{ t('Resource Availability Report') }</h3>

                                { sitrepNationalAvailability }
                        </>
                    : null
                }

                <h3 className="sitrep-display-prefixed">{ t('US Fire Activity') }</h3>

                    { sitrepUSFireActivity }

                <h3 className="sitrep-display-prefixed">{ t('National Statistics') }</h3>
                    
                    <div className="table-sidebyside">
                        <div>
                            <h4>{ t('YTD Fires') }</h4>
                            { sitrepYtdFires }
                        </div>
                        <div>
                            <h4>{ t('Area Burned YTD (Ha)') }</h4>
                            { sitrepYtdArea }
                        </div>
                    </div>

                    <div className="table-sidebyside">
                        <div>
                            <h4>{ t('Fire Averages') }</h4>
                            { sitrepAverageFires }
                        </div>
                        <div>
                            <h4>{ t('Area Burned Averages (Ha)') }</h4>
                            { sitrepAverageArea }
                        </div>
                    </div>

                <div className="footnotes">
                    <p>
                        { t('CIFFC Sitrep for [field_date]').replace( '[field_date]', moment( sitrepData.field_date ).tz( moment.tz.guess() ).format('LL') ) }, 
                        &nbsp;
                        { t('Last updated').toLowerCase() }: { moment( sitrepData.system_edit_timestamp ).tz( moment.tz.guess() ).format('LLLL z') }
                    </p>
                </div>

                <p className="back">
                    <Link to="/situation/archive">
                        <FontAwesomeIcon icon={ solid('arrow-left') } />
                        { t('Archive') }
                    </Link>
                </p>

            </div>
        </section>

    )
}

export default NationalSitRep;
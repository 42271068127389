import React, { useEffect, useState } from "react";

import AgenciesData from "../../constants/AgenciesData";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableFiresTotals( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    useEffect(() => {

        if ( props.data ) {

            const data = props.data;
            
            let tableTotals = {
                agency: t('Total'),

                fires: 0,
                area: 0.00
            };

            Object.keys(data).map(function(dataProp) {
                tableTotals.fires = tableTotals.fires + parseInt(data[dataProp].fires);
                tableTotals.area = tableTotals.area + parseFloat(data[dataProp].area);
                return true;
            });

            let showDisclaimerForTable = false;

            setTableDisplay(
                <>
                    <tbody>
                        { 
                            Object.keys(data).map(function(dataKey, index) {
                                
                                const agency_code = dataKey.toLowerCase();
                                let showDisclaimerForAgency = false;
                                
                                // fetch PROVINCE/AGENCY name from CONST data file
                                let agency_display_name = agency_code.toUpperCase();
                                if ( props.agencyFullName ) {
                                    // ...and translate, if available
                                    agency_display_name = t(AgenciesData[agency_code].name) ? t(AgenciesData[agency_code].name) : AgenciesData[agency_code].name;
                                }

                                // fetch PROVINCE/AGENCY link to website from CONST data file
                                if ( props.agencyLink ) {
                                    agency_display_name = <a href={ AgenciesData[agency_code].website } target="_blank" rel="noopener noreferrer">{ agency_display_name }</a>;
                                }

                                // this table's special in that queried for the YTD DATA and compare to the DISPLAYED summary passed in from whatever the cached source is...
                                if (
                                    data[dataKey].hasOwnProperty('carry_over_fires')
                                    && data[dataKey].carry_over_fires !== ''
                                    && parseInt(data[dataKey].carry_over_fires) > 0
                                ) {
                                    showDisclaimerForAgency = true;
                                    showDisclaimerForTable = true;
                                }

                                return <tr key={ index }>
                                    <td>{ agency_display_name }</td>
                                    
                                    <td className="w15 right">{ showDisclaimerForAgency ? '*' : '' }<CiffcNumberFormat type="int" number={data[dataKey].fires} /></td>
                                    <td className="w15 right"><CiffcNumberFormat type="ha" number={data[dataKey].area} /></td>
                                </tr>
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{ tableTotals.agency }</th>

                            <th className="right">
                                <CiffcNumberFormat type="int" number={tableTotals.fires} />
                            </th>
                            <th className="right">
                                <CiffcNumberFormat type="ha" number={tableTotals.area} />
                            </th>
                        </tr>
                        {
                            showDisclaimerForTable
                                ?   <tr>
                                    <td className="disclaimer" colSpan="3">
                                        * { t('Totals include fires from last fire season that are still listed as active.') } { t('Please refer to the agency’s own website for more information.') }
                                    </td>
                                </tr>
                                : null
                        }
                    </tfoot>
                </>
            );

        }

    }, [ props.agencies, props.data, t ]);


    const [tableDisplay, setTableDisplay] = useState(); 

    return(
        <TableWrapper>
            <thead>
                <tr>
                    <th>{ t('Agency') }</th>

                    <th className="right">{ t('Fires') }</th>
                    <th className="right">{ t('Hectares') }</th>
                </tr>
            </thead>
            { tableDisplay }
        </TableWrapper>
    )
}

export { TableFiresTotals };
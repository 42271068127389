import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import _ from 'lodash'; // https://lodash.com/docs

// toast, made available throughout app
import toast, { Toaster } from "react-hot-toast";

import ResourceOptionSet from "../components/forms/ResourceOptionSet";

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

import AgenciesData from "../constants/AgenciesData";

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "./elements/StaticContent";

function InventoryInput( props ){

    // loading TRANSLATION functions
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Inventory Input') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // AUTH required to access page content
    const { isLoggedIn, activeUser } = props;

    // REDIRECT browser out of authenticated area if user not LOGGED IN with a CIFFC ROLE

    const history = useHistory();

    useEffect(() => {
        if (
            // is LOGGED IN
            activeUser
                    
            // and has a recognized AUTH role
            && activeUser.role
            && activeUser.role.name
            && activeUser.role.name.toUpperCase() === 'AUTHENTICATED'

            // and has a recognized AGENCY code
            && activeUser.user.agency_code
            && AgenciesData.hasOwnProperty( activeUser.user.agency_code.toLowerCase() )
        ) {
            // OK
        } else {
            history.push("/login");
        }

        console.log('activeUser', activeUser);
    }, [ isLoggedIn, activeUser ]);

    // TRACK form status (alloances, permissions, errors)

    const initialFormStatus = {
        isLocked: false, // as of this writing API will NOT return this info for national sitrep input, so this for should NEVER lock
        isSyncing: false, // as of this writing this state will NOT be used as we only save on full form SUBMIT
        isError: false,
        validationErrors: {}
    }

    const [formStatus, setFormStatus] = useState(initialFormStatus);

    // resource DATA for pulldowns

    const initialFormData = {
        'inventory': [],
        'inventory_to_remove': []
    };

    const initialDataInventory = {
        resources: '',
        seasonal: 0,
        exportable: 0,
        is_new: 1
    };

    const [formData, setFormData] = useState(initialFormData);

    const [resourceData, setResourceData] = useState();
    
    useEffect(() => {

        // if PROP isLoggedIn passed in, we can assume this is for an AGENCY PAGE, and access requires authetication to the API
        if ( isLoggedIn ) {

            // fetch INVENTORY definitions, to use in pulldowns...
            
            const urlResources = ApiEndpoints.dipResourceOptionsActive();

            axios
                .get(
                    urlResources
                )
                .then(({ data }) => {
                    console.log( 'GET, resources', data);
                    setResourceData(data);
                })
                .catch((error) => {
                    console.log( 'GET, error', error);
                });

            // fetch INITAL formData associated to logged in AGENCY

            const urlInventoryGet = ApiEndpoints.dipNationalInventoryInputGet();

            axios
                .get(
                    urlInventoryGet,
                    {
                        headers: {
                            Authorization: "Bearer " + activeUser.jwt
                        }
                    }
                )
                .then(({ data }) => {
                    console.log( 'GET, inventory', data);

                    // append REMOVE key(s), if doesn't already exist
                    if ( !data.hasOwnProperty('inventory_to_remove') ) { data.inventory_to_remove = []; }

                    setFormData(data);

                    // flag API as accessible
                    setIsApi(true);
                })
                .catch((error) => {
                    console.log( 'GET, error', error);

                    // flag API as accessible
                    setIsApi(false);
                });

        } else {
            setResourceData( null );
            setFormData( null );

            // flag API as accessible
            setIsApi(false);
        }

    }, [ isLoggedIn ]);

    // prep FORM data, handlers, etc

    const formDataBefore = useRef(formData);

    const handleChange = (event) => {
        let updatedFormData  = _.cloneDeep(formData); // DEEP CLONING the object, to ensure the SETSTATE about to happen actually "sees" the state change properly
        _.set( updatedFormData, event.target.name, event.target.value ); // using LODASH to parse a path of object keys, to set the appropriate value (useful in sub ARRAYs)
        setFormData({...formData, ...updatedFormData});
    };

    const handleBlur = (event, forceSync=false, forceSubmit=false ) => {

        console.log('TO sync', formData, formStatus);

        if ( isLoggedIn ) {

            // vanilla JS check if INPUT is valid based on input TYPE and PATTERN attributes first...
            if ( event && !event.target.checkValidity() ) {
                console.log('SYNC prevented, checkValidity() FAIL');

                let formErrors = formStatus.validationErrors;

                // try to determine best message to display
                let formErrorMessage = t('Invalid format');
                if ( event.target.type === 'number' ) { formErrorMessage = t('Invalid value.'); }
                if ( !event.target.value ) { formErrorMessage = t('This field is required.'); }

                // check for NON-REACT (hence lowercase) attribute indicating error to be displayed for ALTERNATE field
                if ( event.target.getAttribute('forinputname') ) {
                    _.set(formErrors, event.target.getAttribute('forinputname'), formErrorMessage);
                }
                // else set error for field MATCHING input name
                else {
                    _.set(formErrors, event.target.name, formErrorMessage);
                }

                // update FORM STATUS to display syncing is DONE...
                setFormStatus(
                    {
                        ...formStatus, 
                        isError: true,
                        validationErrors: formErrors,
                        checkValidity: 'here'
                    }
                );

                // TOAST
                toast.dismiss();
                toast.error(
                    t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                );

            }

            // only SYNC to API when someone is logged in AND when the form data has CHANGED since before the BLUR event
            else if (
                formDataBefore.current!==formData
                || forceSync // passed in PARAM that forces sync, even we we don't recognize the data as changed since last time
            ) {

                if ( forceSync ) { console.log('handleBlur, formChange pass BECAUSE FORCED'); }
                
                // ...assuming this clears at least the standard validity check (ABOVE), clear any error, trust API to let if still an issue
                if ( event ) {

                    // check for NON-REACT (hence lowercase) attribute indicating error to be cleared for ALTERNATE field
                    if ( event.target.getAttribute('forinputname') ) {
                        _.set(formStatus.validationErrors, event.target.getAttribute('forinputname'), null);
                    }
                    // else clear error for field MATCHING input name
                    else {
                        _.unset(formStatus.validationErrors, event.target.name);
                    }

                }

                // update FORM STATUS to display syncing is STARTING...
                setFormStatus({
                    ...formStatus, 
                    isSyncing: true,
                    validationErrors: {}
                });

                const urlPost = ApiEndpoints.dipNationalInventoryInputValidate();

                axios
                    .post(
                        urlPost,
                        formData,
                        {
                            headers: {
                                Authorization: "Bearer " + activeUser.jwt
                            }
                        }
                    )
                    .then(({ data }) => {
                        console.log("SYNCED, with response", data);

                        let newFormStatus = {
                            isSyncing: false,
                            isLocked: data.locked,
                            isError: false,
                            validationErrors: data.validationErrors || {}
                        }

                        // update FORM STATUS with new form STATUS on success
                        setFormStatus({
                            ...formStatus,
                            ...newFormStatus
                        });

                        // try to TRIGGER a submit, if that's valid to do...
                        setTriggerSubmit(forceSubmit);
                        
                    })
                    .catch((error) => {
                        
                        if (
                            error.response !== undefined
                            && error.response.data !== undefined
                            && error.response.data.validationErrors !== undefined
                        ) {
                            console.log("SYNCED, with error", error);

                            let newFormStatus = {
                                isSyncing: false,
                                isError: true,
                                validationErrors: error.response.data.validationErrors
                            }

                            if ( error.response.data.hasOwnProperty('locked') ) { newFormStatus.isLocked = error.response.data.locked }
                            
                            newFormStatus = {
                                ...formStatus,
                                ...newFormStatus
                            }
                            
                            // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        }

                        else {
                            console.log("SYNCED, with undisplayed/unknown error", error);

                            let newFormStatus = {
                                ...formStatus,
                                isSyncing: false,
                                isError: true
                            }

                            // update with UNKNOWN error?
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        }
                    })
                    .finally(() => {
                        // update BEFORE form data to match CURRENT form data
                        formDataBefore.current = formData;
                    });
                
            } else {
                console.log('SYNC prevented, passed checkValidity() but formData not changed');
            }

        }

    };

    // create generic SERIES add and remove methods, that would be ATTACHED (as onclicks) to related data in form data

    const seriesAddRow = ( formDataKey, initialData, newObjectKey=false ) => {

        // copy of related ARRAY to append to
        const addToSeries = formData[formDataKey];

        // create UNIQUE (enough) key
        const newSeriesKey = formDataKey + '-' + Date.now();

        // appending to OBJECT passed in
        initialData.seriesKey = newSeriesKey;
        
        // appending to OBJECT with provided key
        if ( newObjectKey ) {
            // if key does NOT already exist
            if ( addToSeries.hasOwnProperty(newObjectKey) ) {
                // window.alert( t('That data is already ready to be updated.') );
            } else {
                addToSeries[newObjectKey] = initialData;
            }
        }

        // appending to ARRAY series
        else {
            addToSeries.push(initialData);
        }

        // updated STATE of form data
        setFormData({
            ...formData,
            [formDataKey]: addToSeries,
        });

    };

    const seriesRemoveRow = (formDataKey, seriesKeyToRemove ) => {

        const isConfirmed = window.confirm( t('Are you sure you want to delete this item?') );

        // support STRING (for one remove key) or ARRAY (to remove same key form multiple OBJECTs)
        if ( !Array.isArray(formDataKey) ) {
            formDataKey = [formDataKey];
        }

        if ( isConfirmed ) {

            let updatedFormData = _.cloneDeep(formData);

            formDataKey.forEach( (dataKey) => {

                // find ARRAY keeping track of DELETEs to send to server
                const dataKeyToRemove = dataKey+'_to_remove';

                // get CURRENT instances of both arrays ("keep" and "delete")
                let removalArray = updatedFormData[dataKeyToRemove];
                let visibleArray = updatedFormData[dataKey];

                // find the to-remove data in the "keep" array, save as separate object
                let dataToRemove = updatedFormData[dataKey].filter(x=>x.seriesKey===seriesKeyToRemove);
                if (dataToRemove.length) {
                    // should be ONE object from an array...
                    dataToRemove = dataToRemove[0]; 

                    // if this wasn't a NEW ROW added during this form (that is, it came from the original GET), add this row to the "delete" array, to tell SERVER to remove from database
                    if ( !dataToRemove.hasOwnProperty('is_new') || !dataToRemove.is_new ) removalArray.push( dataToRemove );
                }

                // update "keep" array, filtering out the to-remove data
                visibleArray = updatedFormData[dataKey].filter(x=>x.seriesKey!==seriesKeyToRemove)

                updatedFormData = {
                    ...updatedFormData,
                    [dataKeyToRemove]: removalArray,
                    [dataKey]: visibleArray
                };
           
            });

            setFormData(updatedFormData);
        }

    }

    // AFTER a change to SERIES, re-validate 

    useEffect(() => {
        handleBlur(null);
    }, [ 
        formData.inventory
    ]);

    // format SUBMIT buttons and associated HANDLERs

    const handleSubmit = async ( submitType ) => {
        if ( isLoggedIn ) {

            // submit the CURRENT form data for validation, and only IF PASSES will we allow submit byt PASSING IN the type (this should be the ONLY WAY to change triggerSubmit state to anything but FALSE)
            handleBlur(null, true, submitType);

        }
    }  

    const [triggerSubmit, setTriggerSubmit] = useState(false);
    
    useEffect(() => {
        if ( triggerSubmit ) {
            
            console.log('SUBMIT triggered!');

            if (
                !Object.keys(formStatus.validationErrors).length
                && !formStatus.isLocked
            ) {

                const isConfirmed = window.confirm( t('Are you sure you want to publish this data?') );

                if ( isConfirmed ) {

                    // update FORM STATUS to display syncing is STARTING...
                    setFormStatus({
                        ...formStatus, 
                        isSyncing: true
                    });

                    // figure out TYPE of form submit, use correct URL

                    let submitUrl = ApiEndpoints.dipNationalInventoryInputSave();

                    axios
                        .post(
                            submitUrl,
                            formData,
                            {
                                headers: {
                                    Authorization: "Bearer " + activeUser.jwt
                                }
                            }
                        )
                        .then(({ data }) => {
                            
                            console.log('SUBMITTED successfully', data);
    
                            let newFormStatus = {
                                isSyncing: false,
                                isLocked: data.locked,
                                isError: false,
                                validationErrors: data.validationErrors || {}
                            }
    
                            // update FORM STATUS with new form STATUS on success
                            setFormStatus({
                                ...formStatus,
                                ...newFormStatus
                            });

                            // ACKNOWLEDGE sync to server and REDIRECT away from form
                            window.alert( t('Data synced with server.')+' '+t('The action has been successfully saved.') );
                            history.push("/agency");

                        })
                        .catch((error) => {
                            console.log("SUBMITTED, with error", error);

                            let newFormStatus = {
                                isSyncing: false,
                                isError: true,
                                validationErrors: error.response.data.validationErrors
                            }

                            if ( error.response.data.hasOwnProperty('locked') ) { newFormStatus.isLocked = error.response.data.locked }
                            
                            newFormStatus = {
                                ...formStatus,
                                ...newFormStatus
                            }
                            
                            // update FORM STATUS to display and ERRORS from API (blindly replacing all that were there before?)
                            setFormStatus(newFormStatus);

                            // TOAST
                            toast.dismiss();
                            toast.error(
                                t('This data has one or more validation errors; please review it.')+' '+t('Your data will not be synced with the server until all issues are resolved.'),
                            );

                        });

                } else {
                    console.log('SUBMITTED cancelled by confirm()');
                }

            }

            // ALWAYS set this back to FALSE afterwards
            setTriggerSubmit(false);

        }
    }, [ triggerSubmit ]);

    // render SUBMIT buttons, based on form status and user logged in

    let submitButtons = '';
    
    if (
        formStatus.isLocked
    ) {
        
        submitButtons = <>
                <div className="input" data-validation={ t('This data is locked and currently cannot be edited.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else if (
        Object.keys(formStatus.validationErrors).length
    ) {
        
        submitButtons = 
            <>
                <div className="input" data-validation={ t('This data has one or more validation errors; please review it.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else if (
        Object.keys(formStatus.validationErrors).length
    ) {
        
        submitButtons = 
            <>
                <div className="input" data-validation={ t('This data has one or more validation errors; please review it.') }></div>
                <div className="input form-submit-buttons">
                    <button type="button" disabled="disabled" className="button-submit">
                        <FontAwesomeIcon icon={ solid('lock') } />
                        { t('Blocked') }
                    </button>
                </div>
            </>

    } else {

        let submitButtonDisabled = false;
        if ( !formData.inventory.length ) { submitButtonDisabled = true; }

        submitButtons = <div className="form-submit-buttons">
                <button type="button" disabled={submitButtonDisabled} className="button-submit approved" onClick={ () => handleSubmit('submit') }>
                    <FontAwesomeIcon icon={ solid('file-signature') } />
                    { t('Save') }
                </button>
            </div>

    }

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t(CIFFC SitRep Input') */ }
                <StaticContent staticContentAlias="inventory-input" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <form className={ "form-sitrep" + ( formStatus.isLocked ? ' is-locked' : '' ) }> 

                    <div className="input" data-validation={ t(formStatus.validationErrors.form_0) }></div>
                    
                    <fieldset className="form-section">

                        <div className="input-series">
                            {
                                formData.inventory.map( (item, index) => (
                                    <div className="input-series-set" key={item.seriesKey}>
                                        
                                        <fieldset className="input">

                                            {
                                                item.hasOwnProperty('is_new') && item.is_new
                                                    ? <>
                                                            <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('inventory') && formStatus.validationErrors.inventory.hasOwnProperty(index) ? t(formStatus.validationErrors.inventory[index].resources) : null }>
                                                                <ResourceOptionSet inputName={"inventory["+index+"].resources"} inputValue={ formData.inventory[index].resources } onBlur={handleBlur} resourceData={resourceData} formData={formData} setFormData={setFormData} useAmounts={false} useLabels={true} />
                                                            </div>
                                                        </>
                                                    : <div className="input wide" data-validation={ formStatus.validationErrors.hasOwnProperty('inventory') && formStatus.validationErrors.inventory.hasOwnProperty(index) ? t(formStatus.validationErrors.inventory[index].resources) : null }>
                                                            <label>{ t('Resource') }</label>
                                                            <input type="text"disabled="disabled" name={"inventory["+index+"].resources"} value={ formData.inventory[index].resources } />
                                                        </div>
                                            }

                                            <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('inventory') && formStatus.validationErrors.inventory.hasOwnProperty(index) ? t(formStatus.validationErrors.inventory[index].seasonal) : null }>
                                                <label>{ t('Seasonal') }</label>
                                                <input type="number" min="0" name={"inventory["+index+"].seasonal"} onChange={handleChange} onBlur={handleBlur} value={ formData.inventory[index].seasonal } />
                                            </div>

                                            <div className="input" data-validation={ formStatus.validationErrors.hasOwnProperty('inventory') && formStatus.validationErrors.inventory.hasOwnProperty(index) ? t(formStatus.validationErrors.inventory[index].exportable) : null }>
                                                <label>{ t('Exportable') }</label>
                                                <input type="number" min="0" name={"inventory["+index+"].exportable"} onChange={handleChange} onBlur={handleBlur} value={ formData.inventory[index].exportable } />
                                            </div>

                                            <input type="hidden" name={"inventory["+index+"].is_new"} value={ (formData.inventory[index].is_new || 0) } />
                                        </fieldset>

                                        <button type="button" className="input-series-remove" onClick={ () => seriesRemoveRow("inventory", item.seriesKey) }>
                                            <FontAwesomeIcon icon={ solid('delete-left') } />
                                            <span className="button-label">{ t('Remove') }</span>
                                        </button>

                                    </div>
                                ))
                            }
                        </div>

                        <button className="link input-series-add" type="button" onClick={ () => seriesAddRow("inventory", initialDataInventory) }>
                            <FontAwesomeIcon icon={ solid('list') } />
                            { t('Add another item') }
                        </button>

                    </fieldset>

                    { submitButtons }

                </form>

                <p className="back">
                    <Link to="/agency">
                        <FontAwesomeIcon icon={ solid('arrow-left') } />
                        { t('Overview') }
                    </Link>
                </p>

            </div>

            <Toaster
                toastOptions={{
                    
                    // Default options
                    className: 'toast-popup',

                    // Default options for specific types
                    error: {
                        duration: 4000,
                        position: 'top-center',

                        className: 'toast-popup toast-error',

                        iconTheme: {
                            primary: 'red',
                            secondary: 'white',
                        },
                    },
                
                    // Default options for specific types
                    success: {
                        duration: 750,
                        position: 'top-center',

                        className: 'toast-popup toast-notify',

                        iconTheme: {
                            primary: 'white',
                            secondary: '#18b4cd',
                        },
                    },
                }}
            />
        </section>


    );

}

export default InventoryInput;
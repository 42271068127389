import React, { useEffect, useState } from "react";
import useLocalStorage from "../components/hooks/UseLocalStorage";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from 'moment';

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

// translations
import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

// CONTENT
import StaticContent from "./elements/StaticContent";

// ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

function NationalSitRepArchive(){

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('Situation Reports') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    // const [sitrepData, setSitrepData] = useState(); // replaced with LOCAL STORAGE CACHE
    const [sitrepData, setSitrepData] = useLocalStorage("node-archive-sitrep-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    // const [siterepLatest, setSitrepLatest] = useState(); // replaced with LOCAL STORAGE CACHE
    const [siterepLatest, setSitrepLatest] = useLocalStorage("node-archive-latest-data", false, (process.env.REACT_APP_CACHE_IN_SECONDS_NODE_API || false));

    useEffect(() => {

        if ( !sitrepData ) {

            let urlArchive = ApiEndpoints.dipSitrepNationalArchive();

            axios
            .get( urlArchive )
            .then(({ data }) => {
                setSitrepData(data);

                // flag API as accessible
                setIsApi(true);
            })
            .catch((error) => {
                console.log(error);

                // flag API as inaccessible, show WARNING
                setIsApi(false);
            });

        } else {
            // if reading CACHE, then assume true
            setIsApi(true);
        }

        if ( !siterepLatest ) {

            let urlLatest = ApiEndpoints.dipSitrepNationalLatest();

            axios
                .get( urlLatest )
                .then(({ data }) => {
                    setSitrepLatest(data);
                })
                .catch((error) => {
                    console.log(error);
                });

        }

    }, []);

    // set ARCHIVE display

    const [sitrepArchiveDisplay, setSitrepArchiveDisplay] = useState();

    useEffect(() => {

        if (sitrepData) {

            // format CALENDARs for display

            let displayArchive = [];

            Object.keys(sitrepData).reverse().map(function(y) {
                displayArchive.push(
                    <SitrepYear key={y} date={y + '-01-01'} archive={sitrepData} />
                );
                return true;
            })

            setSitrepArchiveDisplay( displayArchive );

        }

    }, [ sitrepData, i18n.language ]);

    // set LATEST display
    
    const [sitrepPrepardnessLevel, setSitrepPrepardnessLevel] = useState(null);
    const [sitrepReportDate, setSitrepReportDate] = useState(null);
    const [sitrepReportComment, setSitrepReportComment] = useState(null);
    const [sitrepReportLink, setSitrepReportLink] = useState('/situation/');

    useEffect(() => {

        if (siterepLatest) {

            setSitrepPrepardnessLevel( siterepLatest.field_preparedness_level );

            let displayComments = i18n.language === 'fr' ? siterepLatest.field_fr_summary_comments : siterepLatest.field_en_summary_comments;
            setSitrepReportComment( displayComments );

            let displayDate = moment( siterepLatest.field_date ).format('LL');
            setSitrepReportDate( displayDate );

            let displayLinkSuffix = moment( siterepLatest.field_date ).format('YYYY-MM-DD');
            setSitrepReportLink( '/situation/' + displayLinkSuffix );

        }

    }, [ siterepLatest, i18n.language ]);

    // RETURN display

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t('National Fire Situation Reports') */ }
                <StaticContent staticContentAlias="national-sitrep-archive" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <h3>{ t('National Preparedness Level') }: <span className={ 'apl-lvl-' + sitrepPrepardnessLevel }>{ sitrepPrepardnessLevel }</span></h3>

                    <h4> 
                        <a href={ sitrepReportLink }>
                            <FontAwesomeIcon icon={ solid('calendar') } />
                            { sitrepReportDate === null ? t('Latest') : sitrepReportDate }
                        </a>
                    </h4>
                    { sitrepReportComment === null ? '' : <p><i>{ sitrepReportComment }</i></p> }

                <h3>{ t('Archive') }</h3>

                { sitrepArchiveDisplay }

            </div>
        </section>

    );

}

function SitrepYear( props ) {

    const { date, archive } = props;

    const year = moment(date).year();

    let displayMonths = [];

    for ( let m = 1; m <= 12; m++ ) {
        let monthDate = year + '-' + ('00'+m).slice(-2) + '-01';
        displayMonths.push(<SitrepMonth key={m} date={ monthDate } archive={ archive } />);
    }

    return <>
        <h4>{ year }</h4>
        <div className="calendar-year">
            { displayMonths }
        </div>
    </>

}

function SitrepMonth( props ) {

    const { date, archive } = props;

    const monthInt = parseInt(moment(date).month()) + 1;
    const yearInt = parseInt(moment(date).year());
    
    const monthName = moment(date).format('MMMM');
    
    const firstOfMonth = parseInt(moment(date).startOf('month').format('DD'));
    const lastOfMonth = parseInt(moment(date).endOf('month').format('DD'));

    const start = 1 - parseInt(moment(date).startOf('month').day());
    const end = lastOfMonth + (6 - parseInt(moment(date).endOf('month').day()));

    let daysMarkup = [];
    for ( let i = start; i <= end; i++ ) {
        if ( i >= firstOfMonth && i<=lastOfMonth ) {

            let level = 0;
            if ( 
                archive[yearInt] !== undefined
                && archive[yearInt][monthInt] !== undefined
                && archive[yearInt][monthInt][i] !== undefined
            ) {
                level = archive[yearInt][monthInt][i].data.field_preparedness_level;
            }

            if ( level ) {

                // format the LINK to the sitrep based on the provided KEYs; avoiding changing the provided DATE into a moment as the timezone shift might result in a different day that the displayed day
                let linkYear = String(yearInt).padStart(4, '0');
                let linkMonth = String(monthInt).padStart(2, '0');
                let linkDay = String(i).padStart(2, '0');
                let linkDateString = '/situation/' + linkYear + '-' + linkMonth + '-' + linkDay;

                daysMarkup.push( 
                    <li key={i} className={ 'calendar-day apl-lvl-' + level + '-light' }>
                        <Link className="calendar-content" to={ linkDateString }>
                            <span className="calendar-date">{ i }</span>
                            <span className="calendar-level">{ level }</span>
                        </Link>
                    </li>
                );
            } else {
                daysMarkup.push( 
                    <li key={i} className="calendar-day">
                        <span className="calendar-content">
                            <span className="calendar-date">{ i }</span>
                        </span>
                    </li>
                );
            }
        } else {
            daysMarkup.push( 
                <li key={i} className="calendar-day calendar-day-empty"></li>
            );
        }
        
    }

    return <div>
        <h5 className="calendar-month-name">{ monthName }</h5>
        <ul className="calendar-month">
            { daysMarkup }
        </ul>
    </div>
}

export default NationalSitRepArchive;
import React, { useEffect, useState } from "react";
import useLocalStorage from "../../components/hooks/UseLocalStorage";
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

import ApiEndpoints from "../../constants/ApiEndpoints";
import { useTranslation } from 'react-i18next';

import WarningBox from "../../components/WarningBox";

function StaticContent ( props ) {

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();

    const { staticContentAlias, staticContentTitleElement } = props;
    
    // vars and funcs, PUBLIC PAGES from backend

    const [contentPageNotFound, setContentPageNotFound] = useState(false); 
    const [contentPageTitle, setContentPageTitle] = useState();
    const [contentPageBody, setContentPageBody] = useState();

    // CACHED states of content, to avoid uneccessary API calls
    const staticContantCacheTitle = 'cache-page-' + staticContentAlias;
    const staticContentCacheTTL = process.env.REACT_APP_CACHE_IN_SECONDS_STRAPI_PAGE_CONTENT || false;
    const [contentPageApi, setContentPageApi] = useLocalStorage( staticContantCacheTitle, false, staticContentCacheTTL );

    useEffect(() => {

      // don't bother if we don't have an alias to search for...
      if ( !staticContentAlias ) {
        setContentPageNotFound( true ); // and mark that we COULD NOT FIND page content...
      }

      // don't bother if we have a CACHED RESULT to use instead...
      else if ( !contentPageApi ) {

        let url = ApiEndpoints.contentStatic( staticContentAlias ); 

        axios
          .get( url )
          .then(({ data }) => {
            setContentPageApi( data.data[0].attributes );
            setContentPageNotFound( false );
          })
          .catch((error) => {
            // console.log('error', error); 

            // if not, some NOT FOUND message/info...
            setContentPageTitle( t('Error') );
            setContentPageBody( t('Not found') + ': "' +staticContentAlias+ '"' );
            
            // and mark that we COULD NOT FIND page content...
            setContentPageNotFound( true );
          })

      } 

    }, [ staticContentAlias ]);
    
    useEffect(() => {

      // if we have content retrived EITHER from API or CACHE (above use effect)...
      if ( contentPageApi ) {

        var usePageTitle = contentPageApi.Name;
        var usePageContent = contentPageApi.Body;

        contentPageApi.localizations.data.forEach(element => {
            // if TRANSLATION provided for LANG currently in, use that NAME instead...
            if ( element.attributes.locale === i18n.language ) {
              usePageTitle = element.attributes.Name;
              usePageContent = element.attributes.Body;
            }
        });

        setContentPageTitle( usePageTitle );
        setContentPageBody( usePageContent );

      }

    }, [ contentPageApi, i18n.language ]);

    // if we have a NOT-FOUND warning box to display...

    if ( contentPageNotFound ) {
      return <WarningBox title={contentPageTitle} body={contentPageBody} />
    }

    // if we have NO CONTENT to display, return nothing (for better styling/layout)...

    if ( !contentPageTitle && !contentPageBody ) {
      return null;
    }

    // ...otherwise, DISPLAY content in formatted elements...

    return <section className="static-content">
              {
                (() => {
                  switch(staticContentTitleElement) {
                    case 'h1':
                      return <h1>{contentPageTitle ? contentPageTitle : ''}</h1>;
                    case 'h2':
                    default:
                      return <h2>{contentPageTitle ? contentPageTitle : ''}</h2>;
                    case 'h3':
                      return <h3>{contentPageTitle ? contentPageTitle : ''}</h3>;
                    case 'h4':
                      return <h4>{contentPageTitle ? contentPageTitle : ''}</h4>;
                    case 'h5':
                      return <h5>{contentPageTitle ? contentPageTitle : ''}</h5>;
                  }
                })()
              }
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{ contentPageBody ? contentPageBody : '' }</ReactMarkdown>
      </section>;
}

export default StaticContent;
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from 'moment';

import ApiEndpoints from "../constants/ApiEndpoints";
import useDocumentTitle from './elements/useDocumentTitle';

import { CSVLink } from "react-csv";

import { useTranslation } from 'react-i18next';
import MomentLocaleFr from "../constants/MomentLocaleFr";
import CiffcNumberFormat  from "../components/CiffcNumberFormat";

import Spinner from "../components/Spinner";
import WarningBox from "../components/WarningBox";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

// CONTENT
import StaticContent from "./elements/StaticContent";

import { TableWrapper } from "../components/tables/TableWrapper";  // used for consistency and layouts

function NationalFires(){

    // loading TRANSLATION funcitons
    const { t, i18n } = useTranslation();
    if ( i18n.language==='fr' ) { moment.locale('fr', MomentLocaleFr); } else { moment.locale('en'); }

    // set HTML PAGE TITLE and trigger any ANALYTICS...
    useDocumentTitle( t('National Fires List') );

    // is API access available?
    const [isApi, setIsApi] = useState(null);

    const [wildfireFilterPage, setWildfireFilterPage] = useState( 1 );
    const [wildfireFilterAgency, setWildfireFilterAgency] = useState('');
    const [wildfireFilterStage, setWildfireFilterStage] = useState('');
    const [wildfireFilterResponse, setWildfireFilterResponse] = useState('');
    
    const [wildfireData, setWildfireData] = useState();

    useEffect(() => {
        
        // always start with PAGE, as we should always HAVE that as a param with at LEAST a set default
        let urlParamsAsString = `?page=${wildfireFilterPage}`;

        // loop through any OTHER params and APPEND to url query STRING (starting with ampersand)
        if ( wildfireFilterAgency ) urlParamsAsString = urlParamsAsString + `&agency=${wildfireFilterAgency}`;
        if ( wildfireFilterStage ) urlParamsAsString = urlParamsAsString + `&stage=${wildfireFilterStage}`;
        if ( wildfireFilterResponse ) urlParamsAsString = urlParamsAsString + `&response=${wildfireFilterResponse}`;

        const url = ApiEndpoints.dipWildfires() + urlParamsAsString;
        console.log("url", url);

        axios
          .get( url )
          .then(({ data }) => {
            setWildfireData(data);

            // flag API as accessible
            setIsApi(true);
          })
          .catch((error) => {
            console.log(error);

            // flag API as inaccessible, show WARNING
            setIsApi(false);
          })

    }, [ wildfireFilterPage, wildfireFilterAgency, wildfireFilterStage, wildfireFilterResponse ]);

    const [wildfireTableRows, setWildfireTableRows] = useState();

    const [wildfireOptionsPagination, setWildfireOptionsPagination] = useState();
    const [wildfirePaginationSummary, setWildfirePaginationSummary] = useState();

    const [wildfireOptionsAgencies, setWildfireOptionsAgencies] = useState();
    const [wildfireOptionsStages, setWildfireOptionsStages] = useState();
    const [wildfireOptionsResponses, setWildfireOptionsResponses] = useState();

    useEffect(() => {

        // populate AGENCIES filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_agencies ) {
            setWildfireOptionsAgencies(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_agencies ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_agencies[dataKey].field_code }>{ t(wildfireData.meta.filter_agencies[dataKey].field_name) ? t(wildfireData.meta.filter_agencies[dataKey].field_name) : wildfireData.meta.filter_agencies[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate STAGES filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_stages ) {
            setWildfireOptionsStages(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_stages ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_stages[dataKey].field_code }>{ t(wildfireData.meta.filter_stages[dataKey].field_name) ? t(wildfireData.meta.filter_stages[dataKey].field_name) : wildfireData.meta.filter_stages[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate STAGES filter options

        if ( wildfireData && wildfireData.meta && wildfireData.meta.filter_responses ) {
            setWildfireOptionsResponses(
                <>
                    {
                        Object.keys( wildfireData.meta.filter_responses ).map(function(dataKey, index) {
                            return <option key={ index } value={ wildfireData.meta.filter_responses[dataKey].field_code }>{ t(wildfireData.meta.filter_responses[dataKey].field_name) ? t(wildfireData.meta.filter_responses[dataKey].field_name) : wildfireData.meta.filter_responses[dataKey].field_name }</option>
                        })
                    }
                </>
            );
        }

        // populate TABLE with paginated ROWS

        if ( wildfireData && wildfireData.rows ) {

            setWildfireTableRows(
                <>
                    <tbody>
                        { 
                            Object.keys( wildfireData.rows ).map(function(dataKey, index) {
                                return <tr key={ index }>
                                    <td className="nowrap">{ wildfireData.rows[dataKey].field_agency_fire_id }</td>
                                    <td className="center">{ wildfireData.rows[dataKey].field_agency_code.toUpperCase() }</td>
                                    <td className="center">{ wildfireData.rows[dataKey].field_agency_data_timezone }</td>
                                    <td className="nowrap">{ moment( wildfireData.rows[dataKey].field_situation_report_date ).format('ll') }</td>
                                    <td className="nowrap">{ moment( wildfireData.rows[dataKey].field_status_date ).format('ll') }</td>
                                    <td className="center">{ wildfireData.rows[dataKey].field_stage_of_control_status }</td>
                                    <td className="center">{ wildfireData.rows[dataKey].field_system_fire_cause }</td>
                                    <td className="center">{ wildfireData.rows[dataKey].field_response_type }</td>
                                    
                                    <td className="right">
                                        <CiffcNumberFormat number={wildfireData.rows[dataKey].field_fire_size} />
                                    </td>
                                    
                                    <td className="right">
                                        <CiffcNumberFormat type="latlong" number={wildfireData.rows[dataKey].field_latitude} />
                                    </td>
                                    <td className="right">
                                        <CiffcNumberFormat type="latlong" number={wildfireData.rows[dataKey].field_longitude} />
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </>
            );

            let paginationArray = Array.from({length: wildfireData.meta.total_pages}, (_, i) => i + 1);
        
            setWildfireOptionsPagination(
                <>
                    {
                        Object.keys( paginationArray ).map(function(dataKey, index) {
                            return <option key={ index } value={ paginationArray[dataKey] }>{ paginationArray[dataKey] }</option>
                        })
                    }
                </>
            );

            setWildfirePaginationSummary(
                <>
                    { t('of') } <CiffcNumberFormat type="int" number={wildfireData.meta.total_results} /> { t('results') }
                </>
            );

        }

    }, [wildfireData, i18n.language]);

    // define HANDLERs for filter pulldowns

    const handleOptionsChangeAgencies = event => {
        setWildfireFilterPage(1);
        setWildfireFilterAgency(event.target.value);
    };

    const handleOptionsChangeStages = event => {
        setWildfireFilterPage(1);
        setWildfireFilterStage(event.target.value);
    };

    const handleOptionsChangeResponses = event => {
        setWildfireFilterPage(1);
        setWildfireFilterResponse(event.target.value);
    };

    const handleOptionsChangePagination = event => {
        setWildfireFilterPage(event.target.value);
    };

    // define HANDLERs for download buttonm using react-csv LIB (https://github.com/react-csv/react-csv)

    const [wildfireCSV, setWildfireCSV] = useState([]);

    const csvLink = React.createRef();
    
    const handleExportToCsv = () => {

        setWildfireCSV([]); // start it EMPTY, we use this state as FLAG to trigger a download later

        // always start with PAGE, as we should always HAVE that as a param with at LEAST a set default
        let urlParamsAsString = `?export=true`;

        // loop through any OTHER params and APPEND to url query STRING (starting with ampersand)
        if ( wildfireFilterAgency ) urlParamsAsString = urlParamsAsString + `&agency=${wildfireFilterAgency}`;
        if ( wildfireFilterStage ) urlParamsAsString = urlParamsAsString + `&stage=${wildfireFilterStage}`;
        if ( wildfireFilterResponse ) urlParamsAsString = urlParamsAsString + `&response=${wildfireFilterResponse}`;

        const url = ApiEndpoints.dipWildfires() + urlParamsAsString;

        axios
            .get( url )
            .then(({ data }) => {
                setWildfireCSV(data.rows);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    // if CSV DATA TO DOWNLOAD finished fetching, only THEN trigger the download but programically pressing the button

    useEffect(() => {
        if ( wildfireCSV.length ) {
            csvLink.current.link.click();
        }
    }, [wildfireCSV]);

    return(
      
        <section className="contentpage">
            <div className="container">

                { /* t('National Fires List') */ }
                <StaticContent staticContentAlias="national-fires" />

                { isApi===null ? <Spinner className="api-check" /> : null }
                { isApi===false ? <WarningBox className="api-check" title={ t('Error') } body={ t('External server URL') } /> : null }

                <div className="table-filter">
                    <div>
                        <label>{ t('Agency') }</label>
                        <select onChange={ handleOptionsChangeAgencies }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsAgencies }
                        </select>
                    </div>

                    <div>
                        <label>{ t('Stage of Control') }</label>
                        <select onChange={ handleOptionsChangeStages }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsStages }
                        </select>
                    </div>

                    <div>
                        <label>{ t('Response Type') }</label>
                        <select onChange={ handleOptionsChangeResponses }>
                            <option value="">{ t('Any') }...</option>
                            { wildfireOptionsResponses }
                        </select>
                    </div>

                    <div className="to-right">
                        <button className="button" onClick={ handleExportToCsv }>
                            <FontAwesomeIcon icon={ solid('cloud-arrow-down') } />
                            { t('Export') } (CSV)
                        </button>
                    </div>

                    <CSVLink 
                        className="hidden" 
                        filename={ "ciffc.csv" } 
                        data={ wildfireCSV }
                        ref={csvLink}
                    />

                </div>
                
                <TableWrapper>
                    <thead>
                        <tr>
                            <th className="nowrap">{ t('Agency Fire ID') }</th>
                            <th className="center">{ t('Agency Code') }</th>
                            <th className="center">{ t('Agency Data Timezone') }</th>
                            <th className="nowrap">{ t('Situation Report Date') }</th>
                            <th className="nowrap">{ t('Status Date') }</th>
                            <th className="center">{ t('Stage of Control Status') }</th>
                            <th className="center">{ t('National Fire Cause') }</th>
                            <th className="center">{ t('Response Type') }</th>
                            <th className="right">{ t('Fire Size') }</th>
                            <th className="right">{ t('Latitude') }</th>	
                            <th className="right">{ t('Longitude') }</th>
                        </tr>
                    </thead>
                    { wildfireTableRows }
                </TableWrapper>
                
                <div className="table-pagination">
                    <label>{ t('Page') }</label>
                    <select onChange={ handleOptionsChangePagination }>
                        { wildfireOptionsPagination }
                    </select>
                    <span>{ wildfirePaginationSummary }</span>
                </div>

            </div>
        </section>

    );

}

export default NationalFires;
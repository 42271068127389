import React from "react";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

function WarningBox(props) {
    return <section className={ "warning-box " + props.className }>
            { props.title ? <h2>{ props.title }</h2> : null }
            { props.body ? <ReactMarkdown rehypePlugins={[rehypeRaw]}>{ props.body }</ReactMarkdown> : null }
        </section>
}

export default WarningBox;
import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import CiffcNumberFormat  from "../../components/CiffcNumberFormat";

import { TableWrapper } from "./TableWrapper"; // used for consistency and layouts

function TableHistorical( props ){
    
    // loading TRANSLATION funcitons
    const { t } = useTranslation();

    const [sitrepYtdFires, setSitrepYtdFires] = useState(<table></table>);
    const [sitrepYtdArea, setSitrepYtdArea] = useState(<table></table>);
    const [sitrepAverageFires, setSitrepAverageFires] = useState(<table></table>);
    const [sitrepAverageArea, setSitrepAverageArea] = useState(<table></table>);

    useEffect(() => {

        if ( props.historical && props.historical_averages && props.year ) {

            //! STATS, parse data for TOTALs

            let totalYtd = {
                fires: {
                    last_year: 0,
                    current_year: 0
                },
                area: {
                    last_year: 0,
                    current_year: 0
                }
            };

            Object.keys(props.historical).map(function(dataKey) {
                totalYtd.fires.last_year = totalYtd.fires.last_year + (parseInt(props.historical[dataKey].fires.last_year) || 0);
                totalYtd.fires.current_year = totalYtd.fires.current_year + (parseInt(props.historical[dataKey].fires.current_year) || 0);
            
                totalYtd.area.last_year = totalYtd.area.last_year + (parseFloat(props.historical[dataKey].area.last_year) || 0);
                totalYtd.area.current_year = totalYtd.area.current_year + (parseFloat(props.historical[dataKey].area.current_year) || 0);

                return true; // map func expects a RETURN; this is harmless but perhaps replace this func?
            });

            let totalAverages = {
                fires: {
                    years5: 0,
                    years10: 0,
                    years15: 0,
                    years20: 0,
                    years25: 0
                },
                area: {
                    years5: 0,
                    years10: 0,
                    years15: 0,
                    years20: 0,
                    years25: 0
                }
            };

            Object.keys(props.historical).map(function(dataKey) {
                totalAverages.fires.years5 = totalAverages.fires.years5 + (Math.round(props.historical_averages[dataKey].fires.years5) || 0);
                totalAverages.fires.years10 = totalAverages.fires.years10 + (Math.round(props.historical_averages[dataKey].fires.years10) || 0);
                totalAverages.fires.years15 = totalAverages.fires.years15 + (Math.round(props.historical_averages[dataKey].fires.years15) || 0);
                totalAverages.fires.years20 = totalAverages.fires.years20 + (Math.round(props.historical_averages[dataKey].fires.years20) || 0);
                totalAverages.fires.years25 = totalAverages.fires.years25 + (Math.round(props.historical_averages[dataKey].fires.years25) || 0);
            
                totalAverages.area.years5 = totalAverages.area.years5 + (parseFloat(props.historical_averages[dataKey].area.years5) || 0);
                totalAverages.area.years10 = totalAverages.area.years10 + (parseFloat(props.historical_averages[dataKey].area.years10) || 0);
                totalAverages.area.years15 = totalAverages.area.years15 + (parseFloat(props.historical_averages[dataKey].area.years15) || 0);
                totalAverages.area.years20 = totalAverages.area.years20 + (parseFloat(props.historical_averages[dataKey].area.years20) || 0);
                totalAverages.area.years25 = totalAverages.area.years25 + (parseFloat(props.historical_averages[dataKey].area.years25) || 0);

                return true; // map func expects a RETURN; this is harmless but perhaps replace this func?
            });

            // populate TABLES with historical data and TOTALs

            setSitrepYtdFires(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="w15 right">{ parseInt(props.year) - 1 }</th>
                            <th className="w15 right">{ parseInt(props.year) }</th>
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(props.historical).map(function(dataKey, index) {
                            return <tr key={ index }>
                                <td>{ dataKey }</td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseInt(props.historical[dataKey].fires.last_year) } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseInt(props.historical[dataKey].fires.current_year) } /></td>
                            </tr>
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{ t('Total') }</td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalYtd.fires.last_year } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalYtd.fires.current_year } /></td>
                        </tr>
                    </tfoot>
                </TableWrapper>
            );

            setSitrepYtdArea(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="w15 right">{ parseInt(props.year) - 1 }</th>
                            <th className="w15 right">{ parseInt(props.year) }</th>
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(props.historical).map(function(dataKey, index) {
                            return <tr key={ index }>
                                <td>{ dataKey }</td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical[dataKey].area.last_year) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical[dataKey].area.current_year) || 0 } /></td>
                            </tr>
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{ t('Total') }</td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalYtd.area.last_year } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalYtd.area.current_year } /></td>
                        </tr>
                    </tfoot>
                </TableWrapper>
            );

            setSitrepAverageFires(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="w15 right">{ t('5 yr') }</th>
                            <th className="w15 right">{ t('10 yr') }</th>
                            <th className="w15 right">{ t('15 yr') }</th>
                            <th className="w15 right">{ t('20 yr') }</th>
                            <th className="w15 right">{ t('25 yr') }</th>
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(props.historical_averages).map(function(dataKey, index) {
                            return <tr key={ index }>
                                <td>{ dataKey }</td>
                                <td className="right"><CiffcNumberFormat type="int" number={ Math.round(props.historical_averages[dataKey].fires.years5) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ Math.round(props.historical_averages[dataKey].fires.years10) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ Math.round(props.historical_averages[dataKey].fires.years15) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ Math.round(props.historical_averages[dataKey].fires.years20) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ Math.round(props.historical_averages[dataKey].fires.years25) || 0 } /></td>
                            </tr>
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{ t('Total') }</td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.fires.years5 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.fires.years10 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.fires.years15 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.fires.years20 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.fires.years25 } /></td>
                        </tr>
                    </tfoot>
                </TableWrapper>
            );

            setSitrepAverageArea(
                <TableWrapper>
                    <thead>
                        <tr>
                            <th>{ t('Agency') }</th>
                            <th className="w15 right">{ t('5 yr') }</th>
                            <th className="w15 right">{ t('10 yr') }</th>
                            <th className="w15 right">{ t('15 yr') }</th>
                            <th className="w15 right">{ t('20 yr') }</th>
                            <th className="w15 right">{ t('25 yr') }</th>
                        </tr>
                    </thead>
                    <tbody>
                    { 
                        Object.keys(props.historical_averages).map(function(dataKey, index) {
                            return <tr key={ index }>
                                <td>{ dataKey }</td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical_averages[dataKey].area.years5) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical_averages[dataKey].area.years10) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical_averages[dataKey].area.years15) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical_averages[dataKey].area.years20) || 0 } /></td>
                                <td className="right"><CiffcNumberFormat type="int" number={ parseFloat(props.historical_averages[dataKey].area.years25) || 0 } /></td>
                            </tr>
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>{ t('Total') }</td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.area.years5 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.area.years10 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.area.years15 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.area.years20 } /></td>
                            <td className="right"><CiffcNumberFormat type="int" number={ totalAverages.area.years25 } /></td>
                        </tr>
                    </tfoot>
                </TableWrapper>
            );

        }

    }, [ props.historical, props.historical_averages, t ]);

    // return JSON of values

    return {
        sitrepYtdFires: sitrepYtdFires,
        sitrepYtdArea: sitrepYtdArea,
        sitrepAverageFires: sitrepAverageFires,
        sitrepAverageArea: sitrepAverageArea
    }

}

export { TableHistorical };

function TableHistoricalYtdFires( props ){
    const calculatedTables = TableHistorical( props );
    return calculatedTables.sitrepYtdFires;
}

function TableHistoricalYtdArea( props ){
    const calculatedTables = TableHistorical( props );
    return calculatedTables.sitrepYtdArea;
}

function TableHistoricalAverageFires( props ){
    const calculatedTables = TableHistorical( props );
    return calculatedTables.sitrepAverageFires;
}

function TableHistoricalAverageArea( props ){
    const calculatedTables = TableHistorical( props );
    return calculatedTables.sitrepAverageArea;
}

export { TableHistoricalYtdFires, TableHistoricalYtdArea, TableHistoricalAverageFires, TableHistoricalAverageArea };